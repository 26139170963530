import { Button, Col, Image, message, Row, Table } from "antd";
import { Content } from 'antd/es/layout/layout';
import { PopupAddLicense, PopupEditLicense } from 'components/advanced.component';
import { InquiryWindow, LayoutNav, LayoutTabPlatform } from "components/layout.component";
import { ENABLE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from "services";
import { RESPONSE_CODE_LICENSE } from "../../../constants/response";

// 上傳牌照

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();

  const [id, setId] = useState<number | null>(null);
  const [data, setData] = useState<any>();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const { data: License, mutate, isValidating } = $get({ url: 'api/contents/license/list' });

  const statusColor = (status: number) => {
    switch (status) {
      case ENABLE.enabled:
        return 'color-pass'
      case ENABLE.disabled:
        return 'color-reject'
    }
  }

  const onEdit = (record: any) => {
    setData(record);
    setIsOpenEdit(true);
  }

  const handleDelete = (id: number) => {
    setId(id);
    setIsOpenDelete(true)
  }

  const submitDelete = () => {
    $post({
      url: 'api/contents/license/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_LICENSE
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <LayoutTabPlatform activeKey="7" />
        <Row align="middle" gutter={[12, 12]}>
          <Col span={24}>
            {$p('00602') && <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>}
          </Col>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                License ?
                  License.Data.map((item: License) => (
                    {
                      key: item.Id,
                      lang: item.Lang,
                      sort: item.Position,
                      title: item.Title,
                      photo: item.photo_1,
                      url: item.LinkURL,
                      status: item.Status,
                    }
                  )) : []
              }
              columns={[
                {
                  dataIndex: 'sort',
                  title: i18n.t('sort'),
                  align: 'center',
                  width: 80
                },
                {
                  dataIndex: 'title',
                  title: i18n.t('title'),
                  width: 300,
                  render: (val) => (
                    <div style={{
                      whiteSpace: 'nowrap', overflow: 'hidden',
                      textOverflow: 'ellipsis', maxWidth: 300
                    }}>
                      {val}
                    </div>
                  )
                },
                {
                  dataIndex: 'photo',
                  title: i18n.t('image'),
                  width: 300,
                  render: (val) => <Image src={val} style={{ maxWidth: 140, maxHeight: 50 }} />
                },
                {
                  dataIndex: 'url',
                  title: i18n.t('linkURL'),
                  width: 300,
                  render: (val) => val || '-'
                },
                {
                  dataIndex: 'status',
                  title: i18n.t('status'),
                  width: 80,
                  render: (val) => <div className={statusColor(val)}>{i18n.t(ENABLE[val])}</div>
                },
                {
                  title: i18n.t('operation'),
                  width: 80,
                  render: (_, record: any) => (
                    <>
                      {$p('00603') && <Button type="link" onClick={() => onEdit(record)}>{i18n.t('edit')}</Button>}
                      {$p('00604') && <Button type="link" onClick={() => handleDelete(record.key)}>{i18n.t('delete')}</Button>}
                    </>
                  )
                }
              ]}
              pagination={false}
            />
          </Col>
        </Row>
      </Content>
      {/* modal */}
      <PopupAddLicense isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} mutate={mutate} />
      <PopupEditLicense isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} data={data} mutate={mutate} />
      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={submitDelete} />
    </div >
  );
};

export default PageMain;