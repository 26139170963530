import { Button, Col, Form, Image, Input, Row, Select, Table, Tag, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import remarkIcon from 'assets/image/icon-11.svg';
import { PopupBlockGameIpModal, PopupGameIpCommentModal } from 'components/ip.component';
import { CopyButton, DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { timeL2S, timeS2L } from "utils/common";

// 遊戲IP檢核

const PageMain: React.FC = () => {
  const site = useSite();
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [isOpenCommentModal, setIsOpenCommentModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [comment, setComment] = useState<string>('');
  const [id, setId] = useState<number | null>(null);
  const [editData, setEditData] = useState<BlockIpGame>();
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<any>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1])
  })
  const { data: GameIpList, isValidating, mutate } = $get({
    url: 'api/blockip/member/game/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onEditNote = (text: string, memberId: number) => {
    setComment(text);
    setId(memberId);
    setIsOpenCommentModal(true);
  };

  const onBlock = (record: BlockIpGame) => {
    setEditData(record);
    setIsOpenEditModal(true);
  };

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        IP: formData.ip,
        MemberAccount: formData.memberAccount,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    form.resetFields();
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: 0,
        }}>
          <Row gutter={[12, 12]}>
            {/* 搜尋列 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="memberAccount" >
                    <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="ip">
                    <Input className="w-12" placeholder={`${i18n.t('IP')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="TimeMode" className="w-12">
                    <Select
                      disabled
                      className="w-full"
                      options={[
                        { value: 0, label: `${i18n.t('betTimes')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 搜尋結果 */}
            <Col span={24}>
              <Table
                loading={isValidating}
                size="small"
                scroll={{ x: 1200 }}
                dataSource={GameIpList?.Data?.map((item: any, index: number) => ({ key: index, ...item }))}
                columns={[
                  {
                    dataIndex: 'Account',
                    title: i18n.t('memberAccount'),
                    width: 150,
                    fixed: 'left',
                    render: (val) => (
                      <Row align="middle">
                        <span className="size-12">{val}</span>
                        <span><CopyButton text={val} /></span>
                      </Row>
                    )
                  },
                  {
                    dataIndex: 'UpAccount',
                    title: i18n.t('recommendedMembers'),
                    width: 150,
                    render: (val: any, { UpAccount }: any) => {
                      return UpAccount !== null ? UpAccount : <span>-</span>;
                    }
                  },
                  {
                    dataIndex: 'AgAccount',
                    title: i18n.t('agent'),
                    width: 150,
                  },
                  {
                    dataIndex: 'GameTypeSummary',
                    title: `${i18n.t('gameProvider')} - ${i18n.t('gameCategory')}`,
                    width: 150,
                    ellipsis: true,
                    render: (val) => {
                      if (!val) return '-'
                      const keys = Object.keys(val).map((item) => item)[0];
                      const values = Object.values(val).map((item: any) => ({
                        Name: item.Name, Category: item.Category
                      }))[0];
                      return (
                        <>
                          <Row key={keys} gutter={[10, 10]} style={{ borderBottom: '.5px solid #e3e3e3', paddingBottom: '2px', paddingTop: '2px' }}>
                            <Col span={10} className='size-12'>
                              {common.specialProviderName(values?.Name, site.data.SiteName, keys)}
                            </Col>
                            <Col span={14}>
                              <Row gutter={[0, 2]}>
                                {
                                  values?.Category?.map((category: string, i: number) => (
                                    <Col key={i} span={24} className='size-12' style={{ textAlign: 'end' }}>
                                      {i18n.t(category)}
                                    </Col>
                                  ))
                                }
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )
                    }
                  },
                  {
                    dataIndex: 'CreateDate',
                    title: `${i18n.t('createTime')}／IP`,
                    width: 200,
                    render: (val, { RegisterIP }) => (
                      <>
                        <div>{timeS2L(val)}</div>
                        <div>{RegisterIP}</div>
                      </>
                    )
                  },
                  {
                    dataIndex: 'TotalCount',
                    title: i18n.t('duplicateIPCount'),
                    align: 'center',
                    width: 100,
                    render: (val) => (
                      <Tag className={`${common.numColor02(val)}`} >
                        {val}
                      </Tag>
                    )
                  },
                  {
                    dataIndex: 'LastLogDate',
                    title: `${i18n.t('lastLoginTime')}／IP`,
                    width: 200,
                    render: (val, { LastLogIP }) => (
                      <>
                        <div>{timeS2L(val)}</div>
                        <div>{LastLogIP}</div>
                      </>
                    )
                  },
                  {
                    dataIndex: 'LastLogCount',
                    title: i18n.t('duplicateIPCount'),
                    align: 'center',
                    width: 100,
                    render: (val, record: any) => (
                      <Tag className={`${common.numColor02(val)}`} >
                        {val}
                      </Tag>
                    )
                  },
                  {
                    dataIndex: 'Remark',
                    title: i18n.t('remark'),
                    ellipsis: true,
                    width: 150,
                    render: (val, { MemberId }) => (
                      <>
                        <Tooltip title={val}>
                          <div style={{
                            whiteSpace: 'nowrap', maxWidth: 150,
                            overflow: 'hidden', textOverflow: 'ellipsis'
                          }}>{val}</div>
                        </Tooltip>
                        {$p('11303') &&
                          <Button
                            className="center"
                            size="small"
                            type="link"
                            onClick={() => onEditNote(val, MemberId)}
                          >
                            <Image className="center" src={remarkIcon} preview={false} />
                          </Button>}
                      </>
                    )
                  },
                  {
                    title: i18n.t('operation'),
                    align: 'center',
                    width: 130,
                    fixed: 'right',
                    render: (_, record: any) => (
                      $p('11302') &&
                      <Button type="link" onClick={() => onBlock(record as BlockIpGame)}>{i18n.t('block')}</Button>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={GameIpList ? GameIpList.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
      <PopupGameIpCommentModal isOpen={isOpenCommentModal} close={() => setIsOpenCommentModal(false)}
        commentHistory={comment} id={id} mutate={mutate} />
      <PopupBlockGameIpModal isOpen={isOpenEditModal} close={() => setIsOpenEditModal(false)} data={editData} mutate={mutate} />
    </div >
  );
};

export default PageMain;