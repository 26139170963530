import { Button, Col, Form, Input, message, Row, Table, Tooltip } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupAddIpModal, PopupEditIpModal } from 'components/ip.component';
import { InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from "services";
import { RESPONSE_CODE_IP } from "../../../constants/response";

// 會員IP檢核 > IP封鎖管理

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [id, setId] = useState<number | null>(null);
  const [ip, setIp] = useState<string | null>(null);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [params, setParams] = useState<{ IP?: string }>({});
  const { data: IpList, isValidating, mutate } = $get({
    url: 'api/blockip/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  });

  const onEdit = (id: number) => {
    setId(id);
    setIsOpenEdit(true);
  }

  const onDelete = (ip: string) => {
    setIp(ip);
    setIsOpenDelete(true);
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      IP: formData.ip
    })
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    form.resetFields();
  }

  const handleSubmit= () => {
    $post({
      url: 'api/blockip/delete',
      send: { IP: ip },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_IP
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Form form={form} onFinish={onFinish}>
          {/* 搜尋列 */}
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="ip">
                    <Input className="w-12" placeholder='IP' />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
                <Col span={24}>
                  {
                    $p('11202') &&
                    <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>
                  }
                </Col>
              </Row>
            </Col>
            {/* 資料 */}
            <Col span={24}>
              <Table
                loading={isValidating}
                size="small"
                dataSource={
                  IpList ?
                    IpList.Data.map((item: BlockIp) => (
                      {
                        key: item.Id,
                        IP: item.IP,
                        comment: item.Remark,
                        CreateDate: item.CreateDate
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('IP'),
                    dataIndex: 'IP',
                    width: '35%',
                  },
                  {
                    title: i18n.t('status'),
                    width: '15%',
                    render: (_, record: any) => i18n.t('blocked')
                  },
                  {
                    title: i18n.t('remark'),
                    dataIndex: 'comment',
                    width: '35%',
                    render: (_, { comment }: any) => (
                      <Tooltip title={comment}>
                        <div style={{
                          whiteSpace: 'nowrap', maxWidth: 500,
                          overflow: 'hidden', textOverflow: 'ellipsis',
                        }}>{comment}</div>
                      </Tooltip>
                    )
                  },
                  {
                    title: i18n.t('operation'),
                    width: '15%',
                    render: (_, record: any) => (
                      <>
                        {
                          <Button type="link" onClick={() => onEdit(record.key)}>
                            {$p('11203') ? i18n.t('edit') : i18n.t('detail')}
                          </Button>
                        }
                        {
                          $p('11204') &&
                          <Button type="link" onClick={() => onDelete(record.IP)}>
                            {i18n.t('delete')}
                          </Button>
                        }
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={IpList ? IpList.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>

      <PopupAddIpModal isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} mutate={mutate} />
      <PopupEditIpModal isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} id={id} mutate={mutate} />
      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={handleSubmit} />
    </div >
  );
};

export default PageMain;