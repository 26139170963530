import PageReport from "pages/admin/report";
import PageReportAgent from "pages/admin/report/agent";
import PageReportBusiness from "pages/admin/report/business";
import PageReportGame from "pages/admin/report/game";
import PageReportLadder from "pages/admin/report/ladder";
import PageReportPromotion from "pages/admin/report/promotion";
import PageReportSettlement from "pages/admin/report/settlement";
import PageReportSettlementSetting from "pages/admin/report/settlement-setting";
import PageReportWinlose from "pages/admin/report/winlose";
import PageHome from 'pages/home';
import PageReportMessage from "pages/admin/report/sms";

export const reportModule = [
  { path: '/report/:account?', Component: PageReport },
  { path: '/report/summary', Component: PageHome },
  { path: '/report/business', Component: PageReportBusiness },
  { path: '/report/game', Component: PageReportGame },
  { path: '/report/agent', Component: PageReportAgent },
  { path: '/report/promotion', Component: PageReportPromotion },
  { path: '/report/ladder', Component: PageReportLadder },
  { path: '/report/winlose', Component: PageReportWinlose },
  { path: '/report/settlement', Component: PageReportSettlement },
  { path: '/report/settlement/setting', Component: PageReportSettlementSetting },
  { path: '/report/sms', Component: PageReportMessage },
]