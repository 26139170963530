import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import type { ActionFunctionArgs } from "react-router-dom";
import { useRouteError } from "react-router-dom";

import PageFinanceAdjust from "pages/admin/finance/adjust";
import PageFinanceDeposit from "pages/admin/finance/deposit";
import PageFinancePayment from "pages/admin/finance/payment";
import PageFinancePaymentDepositTotal from "pages/admin/finance/payment-depositTotal";
import PageFinancePaymentWithdrawTotal from "pages/admin/finance/payment-withdrawTotal";
import PageFinanceTransfer from "pages/admin/finance/transfer";
import PageFinanceTransferDepositTotal from "pages/admin/finance/transfer-depositTotal";
import PageFinanceTransferWithdrawTotal from "pages/admin/finance/transfer-withdrawTotal";
import PageFinanceWithdraw from "pages/admin/finance/withdraw";

async function loader() {
  try {

    return {
      test: {}
    };

  } catch (e) {
    throw new Error("無法取得loader資料");
  };
};

async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  switch (formData.get("action")) {
    case "add":
      break;
    case "delete":
      break;

    default:
      break;
  }

  return { ok: true };
}

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const financeModule = [
  {
    path: '/', ErrorBoundary,
    children: [
      { path: '/finance/withdraw', Component: PageFinanceWithdraw },
      { path: '/finance/deposit', Component: PageFinanceDeposit },
      { path: '/finance/adjust', Component: PageFinanceAdjust },
      { path: '/finance/payment', Component: PageFinancePayment },
      { path: '/finance/payment-depositTotal/:id', Component: PageFinancePaymentDepositTotal },
      { path: '/finance/payment-withdrawTotal/:id', Component: PageFinancePaymentWithdrawTotal },
      { path: '/finance/transfer', Component: PageFinanceTransfer },
      { path: '/finance/transfer-depositTotal/:id', Component: PageFinanceTransferDepositTotal },
      { path: '/finance/transfer-withdrawTotal/:id', Component: PageFinanceTransferWithdrawTotal },
    ],
  },
]