import { Button, Form, Input, message } from "antd";
import { LayoutNav, ModalLayout } from "components/layout.component";
import { RESPONSE_CODE } from "constants/response";
import i18n from "i18n";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { $post } from "services";

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);

  const onSubmit = (formData: any) => {
    if (formData.NewPassword !== formData.ConfirmNewPassword) {
      message.error(i18n.t('INCORRECT_REPEAT_PASSWORD'));
      return
    }
    const send = {
      Password: formData.Password,
      NewPassword: formData.NewPassword,
      ConfirmNewPassword: formData.ConfirmNewPassword,
    };
    $post({
      url: 'api/account/self/password/update',
      send,
      success: () => {
        message.success(i18n.t('modifySuccess'));
        navigate('/');
      },
      resCode: RESPONSE_CODE
    }, isLoading)
    isLoading(true)
  };

  return (
    <>
      <LayoutNav hidden />
      <ModalLayout paddingBottom="100px">
        <Form size="middle" onFinish={onSubmit}>
          <Form.Item style={{ padding: '10px 0', textAlign: 'center', color: '#666' }}>
            <div>{i18n.t('modifyPassword')}</div>
          </Form.Item>
          <Form.Item
            name="Password"
            rules={[{ required: true, message: `${i18n.t('currentPasswordRequired')}` }]}
          >
            <Input.Password placeholder={`${i18n.t('currentPassword')}`} />
          </Form.Item>
          <Form.Item
            className="mt-1"
            name="NewPassword"
            rules={[{ required: true, message: `${i18n.t('newPasswordRequired')}` }]}
          >
            <Input.Password placeholder={`${i18n.t('newPassword')}`} />
          </Form.Item>
          <Form.Item
            className="mt-1"
            name="ConfirmNewPassword"
            rules={[
              { required: true, message: `${i18n.t('confirmNewPasswordRequired')}` },
            ]}
          >
            <Input.Password placeholder={`${i18n.t('confirmNewPassword')}`} />
          </Form.Item>
          <Form.Item className="center mt-2" name="modify-password">
            <Button
              htmlType="submit"
              loading={loading}
              className="w-10 login-btn"
              style={{ background: '#DABF8D' }}
              size="large"
            >
              {i18n.t('confirm')}
            </Button>
          </Form.Item>
        </Form>
      </ModalLayout>
    </>
  );
};

export default PageMain;
