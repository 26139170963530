import { Button, Col, Form, Input, message, Row, Table } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupAgentBankDetail } from 'components/agent.component';
import { InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from "services";
import { agentLevelWord, verify } from 'utils/common';

// 銀行卡查詢

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const { data: $s } = useSite();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>({});
  const [page, setPage] = useState<number[]>([1, 10]);
  const [withdrawmethodAgentListParams, setWithdrawmethodAgentListParams] = useState<any>({})
  const { data: withdrawmethodAgentList, mutate, isValidating } = $get({
    url: 'api/withdrawmethod/agent/list',
    params: {
      ...withdrawmethodAgentListParams,
      PageIndex: page[0],
      PageSize: page[1],
    }
  });

  const [form] = useForm();
  const [reset, setReset] = useState<boolean>(false);
  const search = (formData: any) => {
    setPage([1, page[1]]);
    setWithdrawmethodAgentListParams({
      BankAccount: formData.BankAccount,
      PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
      PhoneNumber: formData.PhoneNumber,
      Account: formData.Account,
      CryptoAddress: formData.CryptoAddress
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  const handleSubmit = (formData: any) => {
    $post({
      url: 'api/withdrawmethod/agent/delete',
      send: { Id: currentItem.Id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      }
    })
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        {/* 搜尋列 */}
        <Form form={form} onFinish={search}>
          <Row align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item name="Account">
                <Input className="w-12" placeholder={`${i18n.t("agentAccount")}`} />
              </Form.Item>
            </Col>
            {
              $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? ''
                : <>
                  <Col>
                    <Form.Item name="PhonePrefix" rules={verify({ point: 7, required: false })}>
                      <Input className="w-12" placeholder={`${i18n.t('countryCode')}`} addonBefore={'+'} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="PhoneNumber">
                      <Input className="w-12" placeholder={`${i18n.t('phoneNumber')}`} />
                    </Form.Item>
                  </Col>
                </>
            }
            <Col>
              <Form.Item name="BankAccount">
                <Input className="w-12" placeholder={`${i18n.t('bankAccount')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="CryptoAddress">
                <Input className="w-12" placeholder={`${i18n.t('userAddress')}`} />
              </Form.Item>
            </Col>
            <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
            <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
          </Row>
        </Form>
        {/* 搜尋結果 */}
        <Row className="mt-1">
          <Col span={24}>
            <Table
              loading={isValidating}
              dataSource={withdrawmethodAgentList?.Data.map((item: any) => ({ ...item, key: item.Id }))}
              columns={[
                {
                  dataIndex: 'AgentAccount',
                  title: i18n.t('agentAccount'),
                },
                {
                  dataIndex: 'Level',
                  title: i18n.t('agentLevel'),
                  render: (val) => agentLevelWord(val)
                },
                {
                  dataIndex: 'AGAccount',
                  title: i18n.t('upperAgentMasterAgent'),
                  render: (val, { SAAccount }: any) => `${val}／${SAAccount}`
                },
                {
                  dataIndex: 'BankName',
                  title: i18n.t('bankName'),
                },
                {
                  dataIndex: 'Branch',
                  hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
                  title: i18n.t('openingBranch'),
                  render: (val) => val || '-'
                },
                {
                  title: ($s && ($s.Currency === 'VND' || $s.Currency === 'kVND')) ? `${i18n.t('accountNumber')}／${i18n.t('userAddress')}` : i18n.t('phoneNumberAccountNumber'),
                  render: (_, { PhonePrefix, PhoneNumber, BankAccount, CryptoAddress }) => (
                    <>
                      {
                        $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? '' :
                          <><span>{PhoneNumber ? `${PhonePrefix} ${PhoneNumber}` : '-'}</span>／</>
                      }

                      <span>{BankAccount || '-'}</span>／
                      <span>{CryptoAddress || '-'}</span>
                    </>
                  )
                },
                {
                  dataIndex: 'Name',
                  title: i18n.t('accountName'),
                  render: (val) => val || '-'
                },
                {
                  dataIndex: 'State',
                  title: i18n.t('bankCounty'),
                  hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
                  render: (val) => val || '-'
                },
                {
                  dataIndex: 'City',
                  title: i18n.t('city'),
                  hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
                  render: (val) => val || '-'
                },
                {
                  title: i18n.t('operation'),
                  render: (val, record: any) => (
                    <>
                      <Button className="size-12" type='link' onClick={() => {
                        setIsOpenDetail(true);
                        setCurrentItem(record);
                      }}>{i18n.t('detail')}</Button>
                      {
                        $p('40803') &&
                        <Button className="size-12" type="link" onClick={() => {
                          setIsOpenDelete(true);
                          setCurrentItem(record);
                        }}>{i18n.t('delete')}</Button>
                      }
                    </>
                  )
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={withdrawmethodAgentList?.TotalRecord} page={page} setPage={setPage} />
          </Col>
        </Row>
        <PopupAgentBankDetail isOpen={isOpenDetail} close={() => setIsOpenDetail(false)} currentItem={currentItem} mutate={mutate} />
        <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={handleSubmit} />
      </Content>
    </div>
  );
};

export default PageMain;