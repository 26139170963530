export enum RSG_SLOT {
  '一般' = 0,
  '免費' = 1,
  '比倍' = 2,
  'Jackpot' = 3,
  '重轉' = 4,
  '選擇' = 5,
  '連鎖' = 6,
  '消除' = 7,
  '特色' = 99,
}
export enum RSG_FISHING {
  '一般' = 3000,
  '龍王砲' = 3001,
  '砲返現(龍王)' = 3002,
  '元寶牆' = 3003,
  '鑽頭炮' = 3004,
  '砲返現(鑽頭)' = 3005,
  '特色' = 99,
  'Jackpot' = 100,
}

export enum GAME_SETTING {
  newGame = 1,
  hotGames = 2,
  recommend = 3,
  none = 0
}