import { Button, Col, Form, Input, Radio, Row, Spin, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav, LayoutTabPlatform } from 'components/layout.component';
import { RESPONSE_CODE_GAME } from 'constants/response';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from 'services';
import { specialProviderName, verify } from 'utils/common';

// 站台設置 遊戲商排序

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [inputValue, setInputValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [editingKey, setEditingKey] = useState<number>(0);
  const [gameType, setGameType] = useState(1);
  const { data: gameCategory } = $get({ url: 'api/category/list', params: { Status: 1 } })
  const { data: gameProvider, isValidating, mutate } = $get({
    url: 'api/provider/list',
    params: {
      Status: 1,
      CategoryId: gameType,
      PageSize: 100,
      PageIndex: 1
    }
  })

  const onSave = (info: GameProvider) => {
    $post({
      url: 'api/provider/update',
      send: {
        Id: info.Id,
        Name: info.Name,
        Status: info.Status,
        Position: inputValue ? inputValue : info.Position
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setEditingKey(0);
        setIsEdit(false);
        setInputValue('');
        mutate();
      },
      resCode: RESPONSE_CODE_GAME
    })
  }

  const onEdit = (info: GameProvider) => {
    setEditingKey(info.Id);
    setIsEdit(true);
    form.setFieldValue(`sort${info.Id}`, info.Position);
    setInputValue('');
  }

  const onCancel = (info: GameProvider) => {
    if (info.Id === editingKey) {
      setIsEdit(false);
      setEditingKey(0);
    }
    form.setFieldValue(`sort${info.Id}`, '');
    setInputValue('');
  }

  const onChangeGameType = (id: number) => {
    setGameType(id);
    setIsEdit(false);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPlatform activeKey="4" />
        <Form form={form}>
          <Row align="middle" gutter={[10, 16]}>
            <Col span={24}>
              <Radio.Group defaultValue={gameType} buttonStyle="solid">
                {
                  gameCategory?.Data.map((item: GameCategory) => (
                    <Radio.Button key={item.Id} value={item.Id}
                      onChange={() => onChangeGameType(item.Id)}>
                      {i18n.t(item.Name)}
                    </Radio.Button>
                  ))
                }
              </Radio.Group>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  dataSource={gameProvider?.Data.map((item: GameProvider) => ({ key: item.Id, ...item }))}
                  columns={[
                    {
                      title: i18n.t('frontSort'),
                      width: '10%',
                      render: (_: any, record: GameProvider) => (
                        <>
                          {isEdit && record.Id === editingKey
                            ? <Form.Item name={`sort${record.Id}`} rules={[
                              ...verify({ point: 0, required: false, min: 0, max: 99 }),
                            ]}>
                              <Input defaultValue={record.Position}
                                onChange={e => setInputValue(e.target.value)} />
                            </Form.Item>
                            : record.Position}
                        </>
                      )
                    },
                    {
                      title: i18n.t('gameProvider'),
                      render: (_: any, record: GameProvider) => `${specialProviderName(record.Name, site.SiteName, record.Code)}${i18n.t(record.CategoryCode)}`
                    },
                    {
                      dataIndex: 'operation',
                      title: i18n.t('operation'),
                      width: '10%',
                      render: (_: any, record: GameProvider) => (
                        <>
                          {
                            (isEdit && record.Id === editingKey)
                              ?
                              <>
                                <Button type="link" onClick={() => onSave(record)}>
                                  {i18n.t('done')}
                                </Button>
                                <Button type="link" onClick={() => onCancel(record)}>
                                  {i18n.t('cancel')}
                                </Button>
                              </>
                              :
                              <Button type="link" onClick={() => onEdit(record)}>
                                {i18n.t('edit')}
                              </Button>
                          }
                        </>
                      )
                    }
                  ].filter((item: any) => !(item.dataIndex === 'operation' && !$p('00302')))}
                  pagination={false}
                />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;