import { createSlice } from "@reduxjs/toolkit";

interface SearchType {
  agent: null | any;
  saExpand: null | any;
  member: null | any;
  text: null | any;
}

const initialState: SearchType = {
  agent: null,
  saExpand: null,
  member: null,
  text: {
    bankName: '',
    bankDisplayName: '',
  }
}

const searchSlice = createSlice({
  name: 'Search',
  initialState,
  reducers: {
    setSearchHistory: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    clearSearchHistory: (state) => {
      return initialState
    }
  }
})

export const { setSearchHistory, clearSearchHistory } = searchSlice.actions;
export default searchSlice.reducer;