import PageAgent from "pages/admin/agent";
import PageAgentAdd from "pages/admin/agent/add";
import PageAddAgent from "pages/admin/agent/add-agent";
import PageAgentAdjust from "pages/admin/agent/adjust";
import PageAgentCard from "pages/admin/agent/card";
import PageAgentConfirm from "pages/admin/agent/confirm";
import PageAgentDaily from "pages/admin/agent/daily";
import PageAgentDeposit from "pages/admin/agent/deposit";
import PageAgentEdit from "pages/admin/agent/edit";
import PageAgentSettle from "pages/admin/agent/settle";
import PageAgentTag from "pages/admin/agent/tag";
import PageAgentWallet from "pages/admin/agent/wallet";
import PageAgentWithdraw from "pages/admin/agent/withdraw";

export const agentModule = [
  { path: '/agent', Component: PageAgent },
  { path: '/agent/add', Component: PageAgentAdd },
  { path: '/agent/edit/:id', Component: PageAgentEdit },
  { path: '/agent/settle/:id', Component: PageAgentSettle },
  { path: '/agent/daily/:id', Component: PageAgentDaily },
  { path: '/agent/add-agent/:id', Component: PageAddAgent },
  { path: '/agent/withdraw', Component: PageAgentWithdraw },
  { path: '/agent/deposit', Component: PageAgentDeposit },
  { path: '/agent/adjust', Component: PageAgentAdjust },
  { path: '/agent/card', Component: PageAgentCard },
  { path: '/agent/confirm', Component: PageAgentConfirm },
  { path: '/agent/tag', Component: PageAgentTag },
  { path: '/agent/wallet', Component: PageAgentWallet },
]