import { CloseOutlined, DownOutlined, EllipsisOutlined, NotificationFilled, SettingFilled } from '@ant-design/icons';
import type { TabsProps, UploadFile } from 'antd';
import { Badge, Breadcrumb, Button, Card, Col, DatePicker, Divider, Drawer, Flex, FloatButton, Form, Image, Input, InputNumber, Layout, List, Menu, Modal, Radio, Row, Select, Space, Spin, Tabs, Tooltip, Upload, message } from "antd";
import ImgCrop from 'antd-img-crop';
import { Excel } from 'antd-table-saveas-excel';
import { RangePickerProps } from 'antd/es/date-picker';
import { RcFile, UploadChangeParam } from "antd/es/upload";
import Pagination, { PaginationProps } from 'antd/lib/pagination';
import copyIcon from 'assets/image/icon-10.svg';
import hintIcon from 'assets/image/icon-12.png';
import logo02Icon from 'assets/image/logo-02.png';
import logo01Icon from 'assets/image/logo.ico';
import logoutIcon from 'assets/image/logout.svg';
import mailboxIcon from 'assets/image/mailbox.svg';
import newsIcon from 'assets/image/news.svg';
import service from 'assets/image/service.png';
import { COOKIE } from 'constants/cookie';
import { RESPONSE_CODE } from 'constants/response';
import { useSocket } from 'contexts/socket.context';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'enum/date';
import { MEMBER_BANK_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import { DefaultTFuncReturn } from 'i18next';
import Cookies from 'js-cookie';
import QueryString from 'qs';
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { $get } from 'services';
import { determineImageAttributes, enumToOptions, specialProviderName } from 'utils/common';
import $package from "../../package.json";
import { BONUS_CATEGORY } from "../enum/promotion";
import useLanguage, { LANG } from "hooks/language.hook";
import { setSearchHistory } from "../slice/searchSlice";
import { useDispatch } from "react-redux";

const topBarHeight = '55px';

export const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Layout style={{ background: "#fff" }}>
      {children}
      <FloatButton.BackTop visibilityHeight={1} />
    </Layout>
  )
};

// 登入畫面
export const ModalLayout: React.FC<{ children: ReactNode, paddingBottom?: string }> = ({ children, paddingBottom = 0 }) => {
  const { data: $s } = useSite();

  return (
    <>
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      <Flex
        className="login-background-image" justify="center" align="center"
        style={{ paddingBottom }}
      >
        <Card className="login-card">
          <Row className='login-box' justify="center" align="middle">
            <Col>
              <Image src={logo01Icon} preview={false} />
            </Col>
          </Row>
          {children}
        </Card>
      </Flex>
    </>
  )
};

export const LayoutNav: React.FC<{
  id?: any;
  account?: string;
  hidden?: boolean;
  agId?: string;
}> = ({ id, account, hidden , agId }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const $n = (url: any) => navigate(url);
  const socket = useSocket();
  const { info, logout, permissions: $p } = useAccount();
  const [currentTime, setCurrentTime] = useState<string>();
  const [isOpenAnnouncement, setIsOpenAnnouncement] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);

  // 語系
  const { list: languageList, getLang: lang, setLang } = useLanguage();
  const { t, i18n } = useTranslation();
  const { data: $s, isCashVersion: $sc } = useSite();
  const { data: SystemAnnouncement, isValidating, mutate } = $get({
    url: 'api/contents/system/publish/list',
    params: {
      Lang: Cookies.get(COOKIE.LANG),
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const { data: SettingInfo } = $get({ url: 'api/chatsetting/setting' });
  useEffect(() => {
    if (SettingInfo && SettingInfo.Data) {
      Cookies.set('defaultAvatar', SettingInfo.Data.DefaultAvatar);
      Cookies.set('defaultNickname', SettingInfo.Data.DefaultNickname);
    }
  }, [SettingInfo])

  // 進入聊天室的暱稱檢查
  const [callChat, setCallChat] = useState(false);
  const { data: chatAccount } = $get({
    url: 'api/chatroom/service/account/self',
    allow: callChat
  })

  const { isOurService } = useSite();

  useEffect(() => {
    if (chatAccount) {
      if (chatAccount.Data.NickName) {
        // 阻止同瀏覽器多開
        window.open('/#/chat/online-service', 'chat');
      } else {
        message.error(i18n.t('pleaseSetChatNickname'));
        navigate('/chat/account');
      }
      setCallChat(false)
    }
  }, [chatAccount])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs().tz($s && $s.TimeZone).format('YYYY-MM-DD HH:mm:ss'));
    }, 1000)
    return () => clearInterval(interval)
  }, [$s]);

  const logo = $s?.Logo2 
    ? <Image className="nav-logo" src={$s.Logo2} height={30} preview={false} onClick={() => $n('/')} />
    : <></>

  // 選單
  const menuItems: any = [
    ($p('10101') || $p('10701') || $p('10801') || $p('10901') || $p('11001') || $p('11101') || $p('11301') || $p('11501')) && {
      key: 'member',
      label:
        <div className="item-style" >
          <span>{i18n.t('member')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('10101') && { key: 'member-1', label: i18n.t('memberList'), onClick: () => $n('/member') },
        $p('10701') && { key: 'member-2', label: i18n.t('accountRecords'), onClick: () => $n('/member/account') },
        ($p('10801') && $sc) && { key: 'member-3', label: i18n.t('realNameVerification'), onClick: () => $n('/member/verification') },
        ($p('10901') && $sc) && { key: 'member-4', label: i18n.t('withdrawalInfoAudit'), onClick: () => $n('/member/withdraw') },
        ($p('11001') || $p('11501')) && { key: 'member-5', label: i18n.t('instantMessages'), onClick: () => $n('/member/message') },
        $p('11101') && { key: 'member-6', label: i18n.t('memberIPCheck'), onClick: () => $n('/member/ip-member') },
        $p('11301') && { key: 'member-7', label: i18n.t('gameIPCheck'), onClick: () => $n('/member/ip-game') },
        $p('11401') && { key: 'member-8', label: i18n.t('memberTag'), onClick: () => $n('/member/tag') },
      ],
    },
    ($p('20101') || $p('20201') || $p('20301') || $p('20401') || $p('20701') || $p('21001') || $p('21201')) && {
      key: 'finance',
      label:
        <div className="item-style">
          <span>{i18n.t('financial')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        ($p('20101') && $sc) && { key: 'finance-1', label: i18n.t('withdrawalQuery'), onClick: () => $n('/finance/withdraw') },
        ($p('20201') && $sc) && { key: 'finance-2', label: i18n.t('depositQuery'), onClick: () => $n('/finance/deposit') },
        $p('20301') && { key: 'finance-3', label: i18n.t('adjustmentQuery'), onClick: () => $n('/finance/adjust') },
        (($p('20401') || $p('20701')) && $sc) && { key: 'finance-4', label: i18n.t('offlineDepositWithdraw'), onClick: () => $n('/finance/transfer') },
        (($p('21001') || $p('21201')) && $sc) && { key: 'finance-5', label: i18n.t('paymentManagement'), onClick: () => $n('/finance/payment') },
      ],
    },
    // 財務
    ($p('30101') || $p('30201') || $p('30301') || $p('30601') || $p('30401') || $p('30501') || $p('30701') || $p('30801') || $p('30901') || $p('31001')) && {
      key: 'promotion',
      label:
        <div className="item-style">
          <span>{i18n.t('discount')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('30101') && { key: 'promotion-1', label: i18n.t('promotionManagement'), onClick: () => $n('/promotion') },
        $p('30201') && { key: 'promotion-2', label: i18n.t('memberLevelSetting'), onClick: () => $n('/promotion/level') },
        $p('30301') && { key: 'promotion-3', label: i18n.t('promotionQuery'), onClick: () => $n('/promotion/search') },
        ($p('30601') && $sc && $s.CommissionModule === '2') && { key: 'promotion-4', label: i18n.t('memberRebateSettings'), onClick: () => $n('/promotion/rebate') },
        $p('30401') && { key: 'promotion-5', label: i18n.t('rebatePromotionQuery'), onClick: () => $n('/promotion/rebate-search') },
        $p('30501') && { key: 'promotion-6', label: i18n.t('memberLevelPromotionQuery'), onClick: () => $n('/promotion/level-search') },
        $p('30701') && { key: 'promotion-7', label: i18n.t('jackpotSettings'), onClick: () => $n('/promotion/jackpot') },
        $p('30801') && { key: 'promotion-8', label: i18n.t('jackpotSearch'), onClick: () => $n('/promotion/jackpot-search') },
        $p('30901') && { key: 'promotion-9', label: i18n.t('couponManagement'), onClick: () => $n('/promotion/coupon') },
        $p('31001') && { key: 'promotion-10', label: i18n.t('couponQuery'), onClick: () => $n('/promotion/coupon-search') },
      ],
    },
    ( $sc && $s.CommissionModule === '2' && ($p('80101') || $p('80201') || $p('80301'))) && {
      key: 'people-agent',
      label:
        <div className="item-style">
          <span>{i18n.t('allAgent')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        ($p('80101') && $sc && $s.CommissionModule === '2') && { key: 'people-agent-1', label: i18n.t('peopleAgentSetting'), onClick: () => $n('/people-agent') },
        ($p('80201') && $sc && $s.CommissionModule === '2') && { key: 'people-agent-2', label: i18n.t('peopleAgentSearch'), onClick: () => $n('/people-agent/search') },
        ($p('80301') && $sc && $s.CommissionModule === '2') && { key: 'people-agent-3', label: i18n.t('peopleAgentRebateSearch'), onClick: () => $n('/people-agent/rebateSearch') },
      ],
    },
    ($p('40101') || $p('40501') || $p('40601') || $p('40701') || $p('40801') || $p('40901') || $p('41001') || $p('41101')) && {
      key: 'agent',
      label:
        <div className="item-style">
          <span>{i18n.t('agent')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('40101') && { key: 'agent-1', label: i18n.t('generalAgentList'), onClick: () => $n('/agent') },
        ($p('40501') && $sc) && { key: 'agent-2', label: i18n.t('agentWithdrawalQuery'), onClick: () => $n('/agent/withdraw') },
        ($p('40601') && $sc) && { key: 'agent-3', label: i18n.t('agentDepositQuery'), onClick: () => $n('/agent/deposit') },
        $p('40701') && { key: 'agent-4', label: i18n.t('agentAdjustmentQuery'), onClick: () => $n('/agent/adjust') },
        ($p('40801') && $sc) && { key: 'agent-5', label: i18n.t('agentBankCardQuery'), onClick: () => $n('/agent/card') },
        $p('40901') && { key: 'agent-6', label: i18n.t('agentReportAudit'), onClick: () => $n('/agent/confirm') },
        $p('41001') && { key: 'agent-7', label: i18n.t('agentWalletRecord'), onClick: () => $n('/agent/wallet') },
        $p('41101') && { key: 'agent-8', label: i18n.t('agentTag'), onClick: () => $n('/agent/tag') },
      ],
    },
    ($p('50101') || $p('50201') || $p('50301') || $p('50401') || $p('50501') || $p('50601')) && {
      key: 'front',
      label:
        <div className="item-style">
          <span>{i18n.t('frontendPage')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('50101') && { key: 'front-1', label: i18n.t('frontendText'), onClick: () => $n('/front') },
        $p('50201') && { key: 'front-2', label: i18n.t('carouselImage'), onClick: () => $n('/front/carousel') },
        ($p('50301') || $p('50501')) && { key: 'front-3', label: i18n.t('frontDeskSettings'), onClick: () => $n('/front/popup') },
        $p('50401') && { key: 'front-4', label: i18n.t('SEOSetting'), onClick: () => $n('/seo') },
        $p('50601') && { key: 'front-5', label: i18n.t('layoutSetting'), onClick: () => $n('/front/layout-setting') },
      ],
    },
    ($p('60101') || $p('60301') || $p('60401') || $p('60501') || $p('60601') || $p('60701') || $p('60801')) && {
      key: 'report',
      label:
        <div className="item-style">
          <span>{i18n.t('statisticalReports')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('60101') && { key: 'report-1', label: i18n.t('bettingReports'), onClick: () => $n('/report') },
        { key: 'report-2', label: i18n.t('dataOverview'), onClick: () => $n('/report/summary') },
        $p('60301') && { key: 'report-3', label: i18n.t('operationReports'), onClick: () => $n('/report/business') },
        $p('60401') && { key: 'report-4', label: i18n.t('gameStatisticalAnalysis'), onClick: () => $n('/report/game') },
        $p('60501') && { key: 'report-5', label: i18n.t('agentStatisticalAnalysis'), onClick: () => $n('/report/agent') },
        $p('60601') && { key: 'report-6', label: i18n.t('agentPromotionStatistical'), onClick: () => $n('/report/promotion') },
        $p('60701') && { key: 'report-7', label: i18n.t('memberRanking'), onClick: () => $n('/report/ladder') },
        $p('60801') && { key: 'report-8', label: i18n.t('memberWinlossReport'), onClick: () => $n('/report/winlose') },
        $p('60901') &&
        { key: 'report-9', label: i18n.t('settlementReport'), onClick: () => $n('/report/settlement') },
        $p('61001') && { key: 'report-10', label: i18n.t('smsRecord'), onClick: () => $n('/report/sms') },
      ],
    },
    // 預設客服
    ((isOurService && $p('70101')) || (isOurService && $p('70201'))) && {
      key: 'chat',
      label:
        <div className="item-style">
          <span>{i18n.t('chatRoom')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('70101') && { key: 'chat-1', label: i18n.t('chatRoomLog'), onClick: () => navigate('/chat') },
        $p('70201') && { key: 'chat-2', label: i18n.t('chatRoomSettings'), onClick: () => navigate('/chat/account') },
      ],
    },

    // 美洽客服
    (!isOurService && $p('70103')) && {
      key: 'chat',
      label:
        <div className="item-style">
          <span>{i18n.t('chatRoom')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('70103') && { key: 'chat-3', label: i18n.t('chatRoomLog'), onClick: () => navigate('/chat/private') },
      ],
    },
    ($p('00101') || $p('00701') || $p('00801') || $p('00901') || $p('01101') || $p('01401') || $p('01201')) && {
      key: 'advanced',
      label:
        <div className="item-style">
          <span>{i18n.t('advanced')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('00101') && { key: 'advanced-1', label: i18n.t('platformSettings'), onClick: () => $n('/advanced') },
       ($p('00701') || $p('00801')) && { key: 'advanced-2', label: i18n.t('permissionManagement'),
          onClick: () => $n($p('00701') ? '/advanced/permission_name' : '/advanced/permission_backOfficeAccount') },
        $p('00901') && { key: 'advanced-3', label: i18n.t('announcementSettings'), onClick: () => $n('/advanced/announcement') },
        $p('01101') && { key: 'advanced-4', label: i18n.t('maintenanceSettings'), onClick: () => $n('/advanced/maintenance') },
        $p('01201') && { key: 'advanced-5', label: i18n.t('gameManagement'), onClick: () => $n('/advanced/game/management') },
        $p('99999') && { key: 'advanced-7', label: i18n.t('operationRecords'), onClick: () => $n('/advanced/operation') },
        $p('01401') && { key: 'advanced-8', label: i18n.t('operationRecords'), onClick: () => $n('/advanced/operation_new') },
        $p('01501') && { key: 'advanced-9', label: i18n.t('loginRecords'), onClick: () => $n('/advanced/login-record') },
      ],
    },
  ];
  const adminItems = [
    {
      key: 'utc',
      label: (
        <div>
          <div>{`UTC${dayjs().tz($s && $s.TimeZone).format('Z')}`}</div>
          <div>{currentTime}</div>
        </div>
      ),
      className: "time-zone"
    },
    ...(isOurService ? [{
      key: 'service',
      label: (
        <Image src={service} width={25} height={20} preview={false} onClick={() => setCallChat(true)} />
      ),
    }] : []),
    {
      key: 'language',
      label:
        <div className="item-style">
          <span>{LANG[lang as keyof typeof LANG]}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: languageList?.map((item: any) => ({
        key: item,
        label: LANG[item as keyof typeof LANG],
        onClick: async () => await setLang(item)
      }))
    },
    {
      key: 'review',
      label: (
        <Badge offset={[1, 1]} dot={(socket?.reviewKyc || 0) + (socket?.reviewBankcard || 0) > 0}>
          <Image src={newsIcon} width={25} height={25} preview={false} />
        </Badge>
      ),
      children: [
        {
          key: 1,
          label: `${i18n.t('kycVerificationReview')} ${socket?.reviewKyc}`,
          onClick: () => {
            // 本地則重整
            if (window.location.hash.includes('/member/verification')) {
              window.location.reload();
            } else {
              $n('/member/verification')
            }
          }
        },
        {
          key: 2,
          label: `${i18n.t('bankcardVerificationReview')} ${socket?.reviewBankcard}`,
          onClick: () => {
            if (window.location.hash.includes('/member/withdraw')) {
              $n(`/member/withdraw/${MEMBER_BANK_STATE.waitVerify}`);
              window.location.reload();
            } else {
              $n(`/member/withdraw/${MEMBER_BANK_STATE.waitVerify}`);
            }
          }
        }
      ]
    },
    {
      key: 'mailbox',
      label: (
        <Badge offset={[1, 1]}>
          <Image src={mailboxIcon} width={25} height={25} preview={false} />
        </Badge>
      ),
      onClick: () => {
        mutate();
        setIsOpenAnnouncement(true);
      }
    },
    {
      key: 'admin',
      label:
        <Row align="middle">
          <Col>{info?.Account}</Col>
        </Row>,
      children: [
        {
          key: 'password',
          label: i18n.t('modifyPassword'),
          onClick: () => navigate('/password')
        },
        {
          key: 'logout',
          label:
            <Row align="middle" gutter={8}>
              <Col><Image src={logoutIcon} preview={false} /></Col>
              <Col>{i18n.t('logout')}</Col>
            </Row>,
          onClick: () => {
            logout();
            navigate('/login');
            dispatch(setSearchHistory({ member: null }));
          }
        },
        {
          key: 'ver',
          label:
            <Row className="version-box" justify="center">
              <Col>{`${i18n.t('version')}(${$package.version})`}</Col>
            </Row>
        },
      ],
    }
  ]

  return (
    <>
      {/* 主工具列 */}
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      <Row
        align="middle"
        justify="space-between"
        className="bg-color-02 color-01 menu"
        style={{
          height: topBarHeight
        }}
      >
        <Col span={16}>
          <Menu
            className="bg-color-02 color-01 menu-01"
            // 沒logo向左對齊
            style={{ marginLeft: $s?.Logo2 ? '0' : '-15px' }}
            mode="horizontal"
            theme="dark"
            items={[
              {
                key: '/',
                label: logo
              },
              ...menuItems
            ]}
          />
        </Col>
        <Col span={8}>
          <Menu
            className="bg-color-02 color-01 pr-1 menu-02"
            mode="horizontal"
            theme="dark"
            items={adminItems}
          />
        </Col>
      </Row>

      {!hidden && <BreadcrumbBar id={id} account={account} agId={agId}/>}

      <Announcement open={isOpenAnnouncement} loading={isValidating}
        close={() => setIsOpenAnnouncement(false)} SystemAnnouncement={SystemAnnouncement} page={page} setPage={setPage} />
    </>
  );
};

// 系統公告 (右側欄)
export const Announcement: React.FC<{
  open: boolean;
  close: () => void;
  SystemAnnouncement: {
    Data: any;
    TotalRecord: number;
  };
  loading: any;
  page: number[];
  setPage: any;
}> = ({ open, close, SystemAnnouncement, loading, page, setPage }) => {
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [data, setData] = useState<Contents[]>();
  const [details, setDetails] = useState<Contents>();
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  useEffect(() => {
    if (SystemAnnouncement) {
      setData(SystemAnnouncement.Data);
    }
  }, [SystemAnnouncement])

  const showChildrenDrawer = (key: number) => {
    setChildrenDrawer(true);
    data?.map(item => (
      item.Id === key && setDetails(item)
    ))
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const onClose = () => {
    const isFirstLogin = Cookies.get(COOKIE.FIRST_LOGIN);
    if (!isFirstLogin) {
      Cookies.set(COOKIE.FIRST_LOGIN, 'false', { expires: 1 });
      setIsFirstOpen(false);
    }
    close();
  }

  return (
    <Drawer
      width={'450px'}
      styles={{
        header: {
          height: topBarHeight,
          background: '#1E211D'
        }
      }}
      onClose={onClose}
      closable={false}
      title={
        <Row align="middle" justify="space-between">
          <Col>
            <Row align="middle" gutter={10}>
              <Col><Image src={mailboxIcon} preview={false} height={22} /></Col>
              <Col className="size-16 color-02">{i18n.t('systemAnnouncement')}</Col>
            </Row>
          </Col>
          <Col style={{ cursor: 'pointer' }} onClick={onClose}>
            <CloseOutlined className="size-16 color-02" />
          </Col>
        </Row >
      }
      open={
        Cookies.get(COOKIE.FIRST_LOGIN) ? open : isFirstOpen
      }
    >
      <Spin spinning={loading}>
        <List
          style={{ marginBottom: 50 }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item key={item.Id} onClick={() => showChildrenDrawer(item.Id)}>
              <List.Item.Meta
                title={
                  <div className="size-18 text-ellipsis">
                    {<SettingFilled />} {item.Title}
                  </div>
                }
                description={
                  <div className="size-14 text-ellipsis">
                    {item.Content.replace(/<\/?[^>]+(>|$)/g, "")}
                  </div>
                }
              />
            </List.Item>
          )}
        />
        {/* 頁碼 */}
        <div style={{ position: 'fixed', bottom: 30, right: 30 }}>
          <LayoutPagination total={SystemAnnouncement ? SystemAnnouncement.TotalRecord : 0}
            setPage={setPage} page={page} hiddenSizeChange={false} />
        </div>

        {/* 內容 */}
        {
          details &&
          <Drawer
            width={'450px'}
            styles={{
              header: {
                height: topBarHeight,
                background: '#1E211D'
              }
            }}
            onClose={onChildrenDrawerClose}
            closable={false}
            title={
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle" gutter={10}>
                    <Col><NotificationFilled className="size-16 color-01" /></Col>
                    <Col className="size-16 color-02">{i18n.t('announcementDetail')}</Col>
                  </Row>
                </Col>
                <Col style={{ cursor: 'pointer' }} onClick={onChildrenDrawerClose}>
                  <CloseOutlined className="size-16 color-02" />
                </Col>
              </Row >
            }
            open={childrenDrawer}
          >
            <Row style={{ padding: 20 }} >
              <Col span={24} className="font-w-md color-pass size-18">
                {i18n.t('mainTitle')}：
              </Col>
              <Col span={24} className="size-16" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                {details.Title}
              </Col>

              <Col span={24} className="size-18 font-w-md color-down pt-1">
                {i18n.t('content')}：
              </Col>
              <Col span={24} className="size-16" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                dangerouslySetInnerHTML={{ __html: details.Content }} />
            </Row>
          </Drawer>
        }
      </Spin >
    </Drawer >
  )
}

// 麵包屑
export const BreadcrumbBar: React.FC<any> = ({ id, account, agId  }) => {

  const location = useLocation();
  const navigate = useNavigate();

  const {
    id: _id,
    account: _account,
    type: _type,
    dateType: _dateType,
    status: _status
  } = useParams<any>();

  // NOTE: 這邊要module參數化
  const breadcrumbItems = new Map();

  breadcrumbItems.set('/', [
    { title: i18n.t('home') },
  ]);
  breadcrumbItems.set(`/${_dateType}`, [
    { title: i18n.t('home') },
  ]);
  breadcrumbItems.set('/member', [
    { title: i18n.t('member') },
    { title: i18n.t('memberList') }
  ]);
  breadcrumbItems.set(`${'/member/info'}/${id}/${account}/${agId}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set(`${'/member/provider'}/${id}/${account}/${agId}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set(`${'/member/contact'}/${id}/${account}/${agId}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set(`${'/member/bank'}/${id}/${account}/${agId}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set(`${'/member/rebate'}/${id}/${account}/${agId}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: account }
  ]);
  breadcrumbItems.set('/member/account', [
    { title: i18n.t('member') },
    { title: i18n.t('accountRecords') }
  ]);
  breadcrumbItems.set(`${'/member/account/'}${_account}`, [
    { title: i18n.t('member') },
    { title: <Link to={'/member'}>{i18n.t('memberList')}</Link> },
    { title: i18n.t('accountRecords') }
  ]);
  breadcrumbItems.set('/member/verification', [
    { title: i18n.t('member') },
    { title: i18n.t('realNameVerification') }
  ]);
  breadcrumbItems.set(`/member/withdraw`, [{ title: i18n.t('member') }, { title: i18n.t('withdrawalInfoAudit') }]);
  breadcrumbItems.set(`/member/withdraw/${_status}`, [{ title: i18n.t('member') }, { title: i18n.t('withdrawalInfoAudit') }]);
  breadcrumbItems.set('/member/message', [{ title: i18n.t('member') }, { title: i18n.t('instantMessages') }]);
  breadcrumbItems.set('/member/ip-member', [{ title: i18n.t('member') }, { title: i18n.t('memberIPCheck') }]);
  breadcrumbItems.set('/member/block-ip-member', [
    { title: i18n.t('member') },
    { title: <Link to={'/member/ip-member'}>{i18n.t('memberIPCheck')}</Link> },
    { title: i18n.t('IPBlockManagement') },
  ]);
  breadcrumbItems.set('/member/block-ip-game', [
    { title: i18n.t('member') },
    { title: <Link to={'/member/ip-game'}>{i18n.t('gameIPCheck')}</Link> },
    { title: i18n.t('IPBlockManagement') },
  ]);
  breadcrumbItems.set('/member/ip-game', [{ title: i18n.t('member') }, { title: <Link to={'/member/ip-game'}>{i18n.t('gameIPCheck')}</Link> }]);
  breadcrumbItems.set('/member/tag', [{ title: i18n.t('member') }, { title: <Link to={'/member/tag'}>{i18n.t('memberTag')}</Link> }]);
  breadcrumbItems.set('/finance/withdraw', [{ title: i18n.t('financial') }, { title: i18n.t('withdrawalQuery') }]);
  breadcrumbItems.set('/finance/deposit', [{ title: i18n.t('financial') }, { title: i18n.t('depositQuery') }]);
  breadcrumbItems.set('/finance/adjust', [{ title: i18n.t('financial') }, { title: i18n.t('adjustmentQuery') }]);
  breadcrumbItems.set('/finance/payment', [{ title: i18n.t('financial') }, { title: i18n.t('paymentManagement') }]);
  breadcrumbItems.set(`${'/finance/payment-depositTotal/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to={'/finance/payment'}>{i18n.t('paymentManagement')}</Link> },
    { title: i18n.t(`${account}`) }
  ]);
  breadcrumbItems.set(`${'/finance/payment-withdrawTotal/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to={'/finance/payment'}>{i18n.t('paymentManagement')}</Link> },
    { title: i18n.t(`${account}`) }
  ]);
  breadcrumbItems.set('/finance/transfer', [{ title: i18n.t('financial') }, { title: i18n.t('offlineDepositWithdraw') }]);
  breadcrumbItems.set(`${'/finance/transfer-depositTotal/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to={'/finance/transfer'}>{i18n.t('offlineDepositWithdraw')}</Link> },
    { title: i18n.t(`${account}`) }
  ]);
  breadcrumbItems.set(`${'/finance/transfer-withdrawTotal/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to={'/finance/transfer'}>{i18n.t('offlineDepositWithdraw')}</Link> },
    { title: i18n.t(`${account}`) }
  ]);
  breadcrumbItems.set('/finance/virtual-deposit', [{ title: i18n.t('financial') }, { title: i18n.t('WITHDRAW_GATE_CRYPTO') }]);
  breadcrumbItems.set('/finance/virtual-withdraw', [{ title: i18n.t('financial') }, { title: i18n.t('WITHDRAW_GATE_CRYPTO') }]);
  breadcrumbItems.set(`${'/finance/virtual-info/'}${_id}`, [
    { title: i18n.t('financial') },
    { title: <Link to="" onClick={() => navigate(-1)}>{i18n.t('WITHDRAW_GATE_CRYPTO')}</Link> },
    { title: i18n.t(`${_id}`) },
  ]);
  breadcrumbItems.set('/promotion', [{ title: i18n.t('discount') }, { title: i18n.t('promotionManagement') }]);
  breadcrumbItems.set('/promotion/edit-recommend', [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('referralReward') }]);
  breadcrumbItems.set(`/promotion/edit-mission/${_id}`, [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('dailyCheck') }]);
  breadcrumbItems.set('/promotion/add-mission', [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('dailyCheck') }, { title: i18n.t('add') }]);
  breadcrumbItems.set('/promotion/add-other', [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('add') }]);
  breadcrumbItems.set('/promotion/add-store', [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('add') }]);
  breadcrumbItems.set('/promotion/add-red-envelope', [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('add') }]);
  breadcrumbItems.set(`/promotion/edit-red-envelope/${_id}`, [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('redEnvelopePromotions') }]);
  breadcrumbItems.set(`${'/promotion/edit-other/'}${_id}`, [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('otherPromotions') }]);
  breadcrumbItems.set(`${'/promotion/edit-store/'}${_id}`, [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('storePromotions') }]);
  breadcrumbItems.set('/promotion/level', [{ title: i18n.t('discount') }, { title: i18n.t('memberLevelSetting') }]);
  breadcrumbItems.set('/promotion/search', [{ title: i18n.t('discount') }, { title: i18n.t('promotionQuery') }]);
  breadcrumbItems.set('/promotion/rebate', [{ title: i18n.t('discount') }, { title: i18n.t('memberRebateSettings') }]);
  breadcrumbItems.set('/promotion/rebate-search', [{ title: i18n.t('discount') }, { title: i18n.t('rebatePromotionQuery') }]);
  breadcrumbItems.set('/promotion/jackpot', [{ title: i18n.t('discount') }, { title: i18n.t('jackpotSettings') }]);
  breadcrumbItems.set('/promotion/add-jackpot', [{ title: i18n.t('discount') }, { title: <Link to={'/promotion/jackpot'}>{i18n.t('jackpotSettings')}</Link> }, { title: i18n.t('add') }]);
  breadcrumbItems.set(`${'/promotion/edit-jackpot/'}${_id}`, [{ title: i18n.t('discount') }, { title: <Link to={'/promotion/jackpot'}>{i18n.t('jackpotSettings')}</Link> }, { title: i18n.t('edit') }]);
  breadcrumbItems.set('/promotion/jackpot-search', [{ title: i18n.t('discount') }, { title: i18n.t('jackpotSearch') }]);
  breadcrumbItems.set('/promotion/level-search', [{ title: i18n.t('discount') }, { title: i18n.t('memberLevelPromotionQuery') }]);
  breadcrumbItems.set('/promotion/coupon', [{ title: i18n.t('discount') }, { title: i18n.t('couponManagement') }]);
  breadcrumbItems.set('/promotion/coupon-search', [{ title: i18n.t('discount') }, { title: i18n.t('couponQuery') }]);
  breadcrumbItems.set('/promotion/add-coupon', [{ title: i18n.t('discount') }, { title: <Link to={'/promotion/coupon'}>{i18n.t('couponManagement')}</Link> }, { title: i18n.t('add') }]);
  breadcrumbItems.set(`/promotion/edit-coupon/${_id}`, [{ title: i18n.t('discount') }, { title: <Link to={'/promotion/coupon'}>{i18n.t('couponManagement')}</Link> }, { title: i18n.t('edit') }]);
  breadcrumbItems.set('/promotion/edit-signup-bonus', [{ title: i18n.t('discount') }, { title: <Link to={'/promotion'}>{i18n.t('promotionManagement')}</Link> }, { title: i18n.t('signupBonus') }]);
  breadcrumbItems.set('/report', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('bettingReports') }]);
  breadcrumbItems.set(`/report/${_account}`, [{ title: i18n.t('statisticalReports') }, { title: i18n.t('bettingReports') }]);
  breadcrumbItems.set('/report/summary', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('dataOverview') }]);
  breadcrumbItems.set('/report/business', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('operationReports') }]);
  breadcrumbItems.set('/report/game', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('gameStatisticalAnalysis') }]);
  breadcrumbItems.set('/report/agent', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('agentStatisticalAnalysis') }]);
  breadcrumbItems.set('/report/promotion', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('agentPromotionStatistical') }]);
  breadcrumbItems.set('/report/ladder', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('memberRanking') }]);
  breadcrumbItems.set('/report/winlose', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('memberWinlossReport') }]);
  breadcrumbItems.set('/report/settlement', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('settlementReport') }]);
  breadcrumbItems.set('/report/settlement/setting', [{ title: i18n.t('statisticalReports') }, { title: <Link to={'/report/settlement'}>{i18n.t('settlementReport')}</Link> }, { title: i18n.t('setting') }]);
  breadcrumbItems.set('/agent', [{ title: i18n.t('agent') }, { title: i18n.t('generalAgentList') }]);
  breadcrumbItems.set('/agent/add', [{ title: i18n.t('agent') }, { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> }, { title: i18n.t('add') }]);
  breadcrumbItems.set('/report/sms', [{ title: i18n.t('statisticalReports') }, { title: i18n.t('smsRecord') }]);
  breadcrumbItems.set('/chat', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomLog') }]);
  breadcrumbItems.set('/chat/private', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomLog') }]);
  breadcrumbItems.set('/chat/account', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomSettings') }]);
  breadcrumbItems.set('/chat/reply', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomSettings') }]);
  breadcrumbItems.set('/chat/setting', [{ title: i18n.t('chatRoom') }, { title: i18n.t('chatRoomSettings') }]);
  breadcrumbItems.set(`/agent/edit/${_id}`, [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> },
    { title: i18n.t('edit') }
  ]);
  breadcrumbItems.set(`/agent/settle/${_id}`, [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> },
    { title: i18n.t('settlementReports') },
    { title: account }
  ]);
  breadcrumbItems.set(`/agent/daily/${_id}`, [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> },
    { title: i18n.t('dailyReport') },
    { title: account }
  ]);
  breadcrumbItems.set(`/agent/add-agent/${_id}`, [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent'}>{i18n.t('generalAgentList')}</Link> },
    { title: i18n.t('addAgent') }
  ]);
  breadcrumbItems.set('/agent/withdraw', [{ title: i18n.t('agent') }, { title: i18n.t('agentWithdrawalQuery') }]);
  breadcrumbItems.set('/agent/deposit', [{ title: i18n.t('agent') }, { title: i18n.t('agentDepositQuery') }]);
  breadcrumbItems.set('/agent/adjust', [{ title: i18n.t('agent') }, { title: i18n.t('agentAdjustmentQuery') }]);
  breadcrumbItems.set('/agent/card', [{ title: i18n.t('agent') }, { title: i18n.t('agentBankCardQuery') }]);
  breadcrumbItems.set('/agent/confirm', [{ title: i18n.t('agent') }, { title: i18n.t('agentReportAudit') }]);
  breadcrumbItems.set('/agent/wallet', [{ title: i18n.t('agent') }, { title: i18n.t('agentWalletRecord') }]);
  breadcrumbItems.set('/agent/confirm/report', [
    { title: i18n.t('agent') },
    { title: <Link to={'/agent/confirm'}>{i18n.t('agentReportAudit')}</Link> },
    { title: i18n.t('settlementReports') }
  ]);
  breadcrumbItems.set('/agent/tag', [{ title: i18n.t('agent') }, { title: i18n.t('agentTag') }]);
  breadcrumbItems.set('/front', [{ title: i18n.t('frontendPage') }, { title: i18n.t('frontendText') }]);
  breadcrumbItems.set('/front/sub', [{ title: i18n.t('frontendPage') }, { title: i18n.t('frontendText') }]);
  breadcrumbItems.set('/front/carousel', [{ title: i18n.t('frontendPage') }, { title: i18n.t('carouselImage') }]);
  breadcrumbItems.set('/front/popup', [{ title: i18n.t('frontendPage') }, { title: i18n.t('frontDeskSettings') }]);
  breadcrumbItems.set('/seo', [{ title: i18n.t('frontendPage') }, { title: i18n.t('SEOSetting') }]);
  breadcrumbItems.set('/seo/add', [
    { title: i18n.t('frontendPage') },
    { title: <Link to={'/seo'}>{i18n.t('SEOSetting')}</Link> },
    { title: i18n.t('add') }
  ]);
  breadcrumbItems.set(`/seo/edit/${_type}`, [
    { title: i18n.t('frontendPage') },
    { title: <Link to={'/seo'}>{i18n.t('SEOSetting')}</Link> },
    { title: i18n.t('edit') }
  ]);
  breadcrumbItems.set('/front/layout-setting', [{ title: i18n.t('frontendPage') }, { title: i18n.t('layoutSetting') }]);
  breadcrumbItems.set('/advanced', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('platformData') },
  ]);
  breadcrumbItems.set('/advanced/platform_domain', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('domainSettings') },
  ]);
  breadcrumbItems.set('/advanced/platform_gameData', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('gameData') },
  ]);
  breadcrumbItems.set('/advanced/platform_providerSort', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('gameProviderSorting') },
  ]);
  breadcrumbItems.set('/advanced/platform_gameList', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('gameList') },
  ]);
  breadcrumbItems.set('/advanced/platform_license', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('uploadLicense') },
  ]);
  breadcrumbItems.set('/advanced/platform_gameRebate', [
    { title: i18n.t('advanced') },
    { title: i18n.t('platformSettings') },
    { title: i18n.t('gameProviderCashbackSettings') },
  ]);
  breadcrumbItems.set('/advanced/permission_name', [
    { title: i18n.t('advanced') },
    { title: i18n.t('permissionManagement') },
  ]);
  breadcrumbItems.set(`/advanced/permission_name_edit/${_id}`, [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/permission_name'}>{i18n.t('permissionManagement')}</Link> },
    { title: i18n.t('edit') },
  ]);
  breadcrumbItems.set(`/advanced/permission_backOfficeAccount`, [
    { title: i18n.t('advanced') },
    { title: i18n.t('permissionManagement') },
  ]);
  breadcrumbItems.set(`/advanced/permission_backOfficeAccount/${_id}`, [
    { title: i18n.t('advanced') },
    { title: i18n.t('permissionManagement') },
  ]);
  breadcrumbItems.set('/advanced/announcement', [
    { title: i18n.t('advanced') },
    { title: i18n.t('announcementSettings') },
  ]);
  breadcrumbItems.set('/advanced/announcement/add', [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: i18n.t('add') }
  ]);
  breadcrumbItems.set(`/advanced/announcement/edit/${_id}`, [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: i18n.t('edit') }
  ]);
  breadcrumbItems.set('/advanced/announcement/system/add', [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: <Link to={'/advanced/announcement_system'}>{i18n.t('systemAnnouncement')}</Link> },
    { title: i18n.t('add') }
  ]);
  breadcrumbItems.set(`/advanced/announcement/system/edit/${_id}`, [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: <Link to={'/advanced/announcement_system'}>{i18n.t('systemAnnouncement')}</Link> },
    { title: i18n.t('edit') }
  ]);
  breadcrumbItems.set('/advanced/announcement_system', [
    { title: i18n.t('advanced') },
    { title: <Link to={'/advanced/announcement'}>{i18n.t('announcementSettings')}</Link> },
    { title: i18n.t('systemAnnouncement') },
  ]);
  breadcrumbItems.set('/advanced/maintenance', [
    { title: i18n.t('advanced') },
    { title: i18n.t('maintenanceSettings') },
  ]);
  breadcrumbItems.set('/advanced/game/management', [
    { title: i18n.t('advanced') },
    { title: i18n.t('gameManagement') },
  ]);
  breadcrumbItems.set('/advanced/operation', [
    { title: i18n.t('advanced') },
    { title: i18n.t('operationRecords') },
  ]);
  breadcrumbItems.set('/advanced/operation_new', [
    { title: i18n.t('advanced') },
    { title: i18n.t('operationRecords') },
  ]);
  breadcrumbItems.set('/advanced/login-record', [
    { title: i18n.t('advanced') },
    { title: i18n.t('loginRecords') },
  ]);
  breadcrumbItems.set('/people-agent', [
    { title: i18n.t('allAgent') },
    { title: i18n.t('peopleAgentSetting') }
  ]);
  breadcrumbItems.set('/people-agent/search', [
    { title: i18n.t('allAgent') },
    { title: i18n.t('peopleAgentSearch') }
  ]);
  breadcrumbItems.set('/people-agent/rebateSearch', [
    { title: i18n.t('allAgent') },
    { title: i18n.t('peopleAgentRebateSearch') }
  ]);

  return (
    <>
      <Breadcrumb
        style={{ padding: '17px 33px' }}
        separator=">"
        items={breadcrumbItems.get(location.pathname)}
      />
      <Divider style={{ margin: '0px 0px 20px 0px' }} />
    </>
  )
}

export const LayoutTabPayment: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {

  const navigate = useNavigate();

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/finance/payment-deposit');
        break;
      case '2':
        navigate('/finance/payment-withdraw');
        break;
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('deposit'),
    },
    {
      key: '2',
      label: i18n.t('withdrawal'),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabMember: React.FC<any> = ({ activeKey, id, account, agId }) => {
  const { permissions: $p } = useAccount();
  const { isCashVersion: $sc } = useSite();

  const navigate = useNavigate();
  const onTabClick = (key: any) => {
    switch (key) {
      case '1':
        navigate(`/member/info/${id}/${account}/${agId}`);
        break;
      case '2':
        navigate(`/member/provider/${id}/${account}/${agId}`);
        break;
      case '3':
        navigate(`/member/contact/${id}/${account}/${agId}`);
        break;
      case '4':
        navigate(`/member/bank/${id}/${account}/${agId}`);
        break;
      case '5':
        navigate(`/member/rebate/${id}/${account}/${agId}`);
        break;
    }
  }

  const items: any = [
    $p('10201') && { key: '1', label: i18n.t('basicData') },
    $p('10301') && { key: '2', label: i18n.t('gameProviderData') },
    $p('10401') && { key: '3', label: i18n.t('contactInformation') },
    ($p('10501') && $sc) && { key: '4', label: i18n.t('bankCardList') },
    $p('10601') && { key: '5', label: i18n.t('rebateList') },
  ];

  return (
    <Tabs className="color-03" size="small" activeKey={activeKey} items={items} onTabClick={onTabClick} />
  );
};

export const LayoutTabFront: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {

  const navigate = useNavigate();

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/front');
        break;
      case '2':
        navigate('/front/sub');
        break;
    }
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className='w-5 text-center'>{i18n.t('mainTitle')}</div>,
    },
    {
      key: '2',
      label: <div className='w-5 text-center'>{i18n.t('subTitle')}</div>,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

// TODO:參考
export const LayoutTabPermission: React.FC<any> = ({ activeKey }) => {

  const navigate = useNavigate();
  const { init, permissions: $p } = useAccount();
  const [key, setKey] = useState('1');
  useEffect(() => {
    if (init && activeKey) {
      setKey(activeKey);
    }
  }, [init, activeKey])

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/advanced/permission_name');
        break;
      case '2':
        navigate('/advanced/permission_backOfficeAccount');
        break;
    }
  }

  const items: any = [
    $p('00701') && { key: '1', label: <div className='w-8 text-center'>{i18n.t('permissionName')}</div> },
    $p('00801') && { key: '2', label: <div className='w-8 text-center'>{i18n.t('backOfficeAccount')}</div> },
  ];

  return (
    <>
      <Tabs activeKey={key} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabPlatform: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {
  const navigate = useNavigate();
  const { init, permissions: $p } = useAccount();
  const [key, setKey] = useState('1');
  useEffect(() => {
    if (init && activeKey) {
      setKey(activeKey);
    }
  }, [init, activeKey])

  const onTabClick = (key: string) => {

    switch (key) {
      case '1':
        navigate('/advanced');
        break;
      case '3':
        navigate('/advanced/platform_gameData');
        break;
      case '4':
        navigate('/advanced/platform_providerSort');
        break;
      case '5':
        navigate('/advanced/platform_gameList');
        break;
      case '6':
        navigate('/advanced/platform_gameRebate');
        break;
      case '7':
        navigate('/advanced/platform_license');
        break;
    }
  }

  // 聖經Tabs
  const items: any = [
    { key: '1', label: <div className='text-center'>{i18n.t('platformData')}</div> },
    $p('00201') && { key: '3', label: <div className='text-center'>{i18n.t('gameData')}</div> },
    $p('00301') && { key: '4', label: <div className='text-center'>{i18n.t('gameProviderSorting')}</div> },
    $p('00401') && { key: '5', label: <div className='text-center'>{i18n.t('gameList')}</div> },
    $p('00501') && { key: '6', label: <div className='text-center'>{i18n.t('gameProviderCashbackSettings')}</div> },
    $p('00601') && { key: '7', label: <div className='text-center'>{i18n.t('uploadLicense')}</div> },
  ];

  return (
    <>
      <Tabs activeKey={key} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const LayoutTabChatLog = ({ activeKey }: {
  activeKey: string;
}) => {
  const { permissions: $p } = useAccount();
  const navigate = useNavigate();
  const { isOurService } = useSite();
  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        navigate(`/chat`);
        break;
      case '2':
        navigate(`/chat/private`);
        break;
    }
  }

  const items: any = [
    isOurService && { key: '1', label: i18n.t('service') },
    $p('70103') && { key: '2', label: i18n.t('privateMessage') },
  ];

  return (
    <Tabs size="small" activeKey={activeKey} items={items} onTabClick={onTabClick}
    // indicatorSize={(origin) => 150} tabBarGutter={150} 
    />
  );
};

export const LayoutTabChatSetting = ({ activeKey }: {
  activeKey: string;
}) => {
  const { permissions: $p } = useAccount();
  const navigate = useNavigate();

  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        navigate(`/chat/account`);
        break;
      case '2':
        navigate(`/chat/reply`);
        break;
      case '3':
        navigate(`/chat/setting`);
        break;
    }
  }

  const items: any = [
    { key: '1', label: i18n.t('accountLookup') },
    $p('70301') && { key: '2', label: i18n.t('quickReply') },
    $p('70401') && { key: '3', label: i18n.t('featureSettings') },
  ];

  return (
    <>
      <Tabs className="color-03" size="small" activeKey={activeKey} items={items} onTabClick={onTabClick} />
    </>
  );
};

export const CopyButton: React.FC<{
  text: string;
}> = ({ text }) => {

  //copy text
  const handleCopyAccount = (text: string) => {
    navigator.clipboard.writeText(text);
    message.success(i18n.t('copySuccess'));
  };

  return (
    <Button onClick={() => handleCopyAccount(text)} className="center" size="small" type="link">
      <Image className="center" src={copyIcon} preview={false} />
    </Button>
  )
}

// 時間選擇器(範圍)
export const DateSelector: React.FC<{
  form?: any, name?: any,
  width?: any,           // 寬度
  date: any,             // state
  setDate: any,          // state
  initDate?: boolean,    // 重置
  format?: string,       // 格式
  ignoreDateType?: any,  // 不顯示週期
  displayCom?: any,      // 顯示元件
  disabled?: boolean,    // 全禁用
  rangeLimit?: any,      // 搜尋範圍
  defaultDateType?: any, // 預設
  setFormChanged?: any   // 表單更動
}> = ({
  form, name,
  width,
  date,
  setDate,
  initDate,
  format = 'YYYY-MM-DD HH:mm:ss',
  displayCom = ['Picker'],
  disabled,
  rangeLimit = DATE_RANGE_LIMIT.future,
  defaultDateType,
  setFormChanged = () => { }
}) => {

    // 伺服器的今天
    const serverZone = window?.serverZone || "Asia/Taipei";
    const L = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss'))
    const S = dayjs(dayjs().tz(serverZone).format('YYYY-MM-DD HH:mm:ss'))
    const diff = L.diff(S, 'hour');
    const shift = (day: any) => format.includes('HH:mm') ? day.add(diff, 'hour') : day;

    const today = dayjs().startOf('day');
    const yesterday = today.subtract(1, 'day');
    // 需求: 週一開始 週日算上禮拜
    const thisWeek = today.startOf('isoWeek');
    const lastWeek = thisWeek.subtract(1, 'week');
    const thisMonth = today.startOf('month');
    const lastMonth = thisMonth.subtract(1, 'month');

    const showTime = format.includes('HH')

    // 初始化
    useEffect(() => {
      dateSwitch(defaultDateType || DATE_TYPE.today);
    }, [initDate])

    // Radio快捷
    const dateSwitch = (type: DATE_TYPE) => {
      switch (type) {
        case DATE_TYPE.today:
          setDate([shift(today).format(format), shift(today.endOf('day')).format(format), type])
          break;
        case DATE_TYPE.yesterday:
          setDate([shift(yesterday).format(format), shift(yesterday.endOf('day')).format(format), type])
          break;
        case DATE_TYPE.thisWeek:
          setDate([shift(thisWeek).format(format), shift(today.endOf('isoWeek')).format(format), type])
          break;
        case DATE_TYPE.lastWeek:
          setDate([shift(lastWeek).format(format), shift(lastWeek.endOf('isoWeek')).format(format), type])
          break;
        case DATE_TYPE.thisMonth:
          setDate([shift(thisMonth).format(format), shift(today.endOf('month')).format(format), type])
          break;
        case DATE_TYPE.lastMonth:
          setDate([shift(lastMonth).format(format), shift(lastMonth.endOf('month')).format(format), type])
          break;
      }
      setFormChanged(true);
      if (form && name) {
        form.setFieldValue(name, date);
        form.validateFields([name]);
      }
    }

    const changeDate = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
      setDate([dateStrings[0], dateStrings[1], null]);
      setFormChanged(true);
      if (form && name) {
        form.setFieldValue(name, [dateStrings[0], dateStrings[1], null]);
        form.validateFields([name]);
      }
    };

    // 去空白
    const initialized = useRef(false)
    useEffect(() => {
      const w: any = window
      w.startTime = '';
      w.endTime = '';

      if (date) {
        w.startTime = date[0];
        w.endTime = date[1];
      }
      if (!initialized.current) {
        initialized.current = true;

        const input = document.getElementsByClassName('ant-picker-input')
        input[0]?.addEventListener('change', function (event: any) {
          const dateString = event.target.value.split(' ')
          w.startTime = `${dateString[0]} ${dateString[1]}`
          setDate([w.startTime, w.endTime, null])
        })
        input[1]?.addEventListener('change', function (event: any) {
          const dateString: string = event.target.value.split(' ')
          w.endTime = `${dateString[0]} ${dateString[1]}`
          setDate([w.startTime, w.endTime, null])
        })
      }
    }, [date]);

    const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
      switch (rangeLimit) {
        case DATE_RANGE_LIMIT.all:
          return true;
        case DATE_RANGE_LIMIT.future:
          return current < today.startOf('day');
        default:
          return current < today.add(-3, 'month').startOf('month');
      }
    };

    // RangePicker很難做到點開不清空跟動態範圍 二者只能擇一 除非拆成兩個Picker
    const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
      switch (rangeLimit) {
        case DATE_RANGE_LIMIT.past7Days:
          return current < dayjs(date[0]) || dayjs(date[0]).add(7, 'day') < current;
        case DATE_RANGE_LIMIT.past31Days:
          return current < dayjs(date[0]) || dayjs(date[0]).add(31, 'day') < current;
        default:
          return current < dayjs(date[0]) || today.endOf('month') < current;
      }
    };

    return (
      <>
        {displayCom.includes('Picker') && <Col>
          <DatePicker.RangePicker
            style={{ width }}
            placeholder={[i18n.t('startTime'), i18n.t('endTime')]}
            placement={'bottomLeft'}
            format={format}
            showTime={showTime ? {
              defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
            } : false}
            value={[date[0] ? dayjs(date[0]) : null, date[1] ? dayjs(date[1]) : null]}
            allowClear={false}
            onChange={changeDate}
            disabled={disabled}
            disabledDate={disabledDateStart}
          />
        </Col>}
      </>
    )
  }

// 時間選擇器(獨立兩組)
// NOTE: 時間選擇器有兩種 只差在RangePicker以及disabledDate
export const DatePickerCol: React.FC<{
  form?: any, name?: any,
  width?: number,         // 寬度
  date: any,              // state
  setDate: any,           // state
  initDate?: boolean,     // 重置
  format?: string,        // 格式
  ignoreDateType?: any,   // 不顯示週期
  displayCom?: any,       // 顯示元件
  disabled?: boolean,     // 全禁用
  rangeLimit?: any,       // 搜尋範圍
  defaultDateType?: any,  // 預設
  setFormChanged?: any    // 表單更動
  textAlign?: string,     // 對齊
  components?: any,       // 中間元件
}> = ({
  form, name,
  width,
  date,
  setDate,
  initDate,
  format = 'YYYY-MM-DD HH:mm:ss',
  ignoreDateType,
  displayCom = ['Picker', 'Radio'],
  disabled,
  rangeLimit,
  defaultDateType,
  setFormChanged = () => { },
  textAlign,
  components
}) => {

    // 伺服器的今天
    const serverZone = window?.serverZone || "Asia/Taipei";
    const L = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss'))
    const S = dayjs(dayjs().tz(serverZone).format('YYYY-MM-DD HH:mm:ss'))
    const diff = L.diff(S, 'hour');
    const shift = (day: any) => format.includes('HH:mm') ? day.add(diff, 'hour') : day;

    const today = dayjs().startOf('day');
    const yesterday = today.subtract(1, 'day');
    // 需求: 週一開始 週日算上禮拜
    const thisWeek = today.startOf('isoWeek');
    const lastWeek = thisWeek.subtract(1, 'week');
    const thisMonth = today.startOf('month');
    const lastMonth = thisMonth.subtract(1, 'month');

    const showTime = format.includes('HH');

    const [pickerStartOpen, setPickerStartOpen] = useState(false);
    const [pickerEndOpen, setPickerEndOpen] = useState(false);

    // 初始化
    useEffect(() => {
      dateSwitch(defaultDateType || DATE_TYPE.today);
    }, [initDate])

    // Picker快捷
    const presets: { id: DATE_TYPE, label: string, value: Dayjs[] }[] = [
      {
        id: DATE_TYPE.today,
        label: i18n.t('today'),
        value: [shift(today), shift(today.endOf('day'))]
      },
      {
        id: DATE_TYPE.yesterday,
        label: i18n.t('yesterday'),
        value: [shift(yesterday), shift(yesterday.endOf('day'))]
      },
      {
        id: DATE_TYPE.thisWeek,
        label: i18n.t('thisWeek'),
        value: [shift(thisWeek), shift(thisWeek.endOf('isoWeek'))]
      },
      {
        id: DATE_TYPE.lastWeek,
        label: i18n.t('lastWeek'),
        value: [shift(lastWeek), shift(lastWeek.endOf('isoWeek'))]
      },
      {
        id: DATE_TYPE.thisMonth,
        label: i18n.t('thisMonth'),
        value: [shift(thisMonth), shift(thisMonth.endOf('month'))]
      },
      {
        id: DATE_TYPE.lastMonth,
        label: i18n.t('lastMonth'),
        value: [shift(lastMonth), shift(lastMonth.endOf('month'))]
      },
    ];

    // Radio快捷
    const dateSwitch = (type: DATE_TYPE) => {
      switch (type) {
        case DATE_TYPE.today:
          setDate([shift(today).format(format), shift(today.endOf('day')).format(format), type])
          break;
        case DATE_TYPE.yesterday:
          setDate([shift(yesterday).format(format), shift(yesterday.endOf('day')).format(format), type])
          break;
        case DATE_TYPE.thisWeek:
          setDate([shift(thisWeek).format(format), shift(thisWeek.endOf('isoWeek')).format(format), type])
          break;
        case DATE_TYPE.lastWeek:
          setDate([shift(lastWeek).format(format), shift(lastWeek.endOf('isoWeek')).format(format), type])
          break;
        case DATE_TYPE.thisMonth:
          setDate([shift(thisMonth).format(format), shift(thisMonth.endOf('month')).format(format), type])
          break;
        case DATE_TYPE.lastMonth:
          setDate([shift(lastMonth).format(format), shift(lastMonth.endOf('month')).format(format), type])
          break;
      }
      setFormChanged(true);
      if (form && name) {
        form.setFieldValue(name, date);
        form.validateFields([name]);
      }
    }

    const changeDate = (dateString: any, index: any) => {
      date[index] = dateString || ''
      const final = dayjs(dateString).add((rangeLimit || 31) - 1, 'day').format(showTime ? 'YYYY-MM-DD 23:59:59' : format);
      // 自動區間
      if (index === 0 && dateString) {
        if (
          ((rangeLimit === DATE_RANGE_LIMIT.past7Days || rangeLimit === DATE_RANGE_LIMIT.past31Days) && dayjs(date[1]).isAfter(final, 'day')) ||
          dayjs(date[0]).isAfter(dayjs(date[1]), 'day')
        ) {
          date[1] = final;
          setPickerEndOpen(true);
        }
      }
      setDate([date[0], date[1], null])
      setFormChanged(true);
      if (form && name) {
        form.setFieldValue(name, [date[0], date[1], null]);
        form.validateFields([name]);
      }
    }

    // 去空白
    const initialized = useRef(false)
    useEffect(() => {
      const w: any = window
      w.startTime = '';
      w.endTime = '';

      if (date) {
        w.startTime = date[0];
        w.endTime = date[1];
      }
      if (!initialized.current) {
        initialized.current = true;

        const input = document.getElementsByClassName('ant-picker-input')
        input[0]?.addEventListener('change', function (event: any) {
          if (format.includes('HH:mm:ss')) {
            const dateString = event.target.value.split(' ');
            w.startTime = `${dateString[0]} ${dateString[1]}`;
          } else {
            const dateString = event.target.value;
            w.startTime = `${dateString}`;
          }
          setDate([w.startTime, w.endTime, null]);
        })
        input[1]?.addEventListener('change', function (event: any) {
          if (format.includes('HH:mm:ss')) {
            const dateString = event.target.value.split(' ');
            w.endTime = `${dateString[0]} ${dateString[1]}`;
          } else {
            const dateString = event.target.value;
            w.endTime = `${dateString}`;
          }
          setDate([w.startTime, w.endTime, null]);
        })
      }
    }, [date]);

    const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
      switch (rangeLimit) {
        case DATE_RANGE_LIMIT.all:
          return false;
        case DATE_RANGE_LIMIT.future:
          return current < today.startOf('day');
        default:
          return current < today.add(-3, 'month').startOf('month');
      }
    };

    const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
      switch (rangeLimit) {
        case DATE_RANGE_LIMIT.past7Days:
          // 沒有HH:mm:ss的格式會多一天不知道為啥
          return current < dayjs(date[0]) || dayjs(date[0]).add(showTime ? 7 : 6, 'day') < current;
        case DATE_RANGE_LIMIT.past31Days:
          return current < dayjs(date[0]) || dayjs(date[0]).add(showTime ? 31 : 30, 'day') < current;
        default:
          return current < dayjs(date[0]);
      }
    };

    return (
      <>
        {displayCom.includes('Picker') && <>
          <Col span={''}>
            <DatePicker
              open={pickerStartOpen}
              onOpenChange={setPickerStartOpen}
              showNow={false}
              style={{ width }}
              placeholder={i18n.t('startTime') as string}
              placement={'bottomLeft'}
              format={format}
              showTime={showTime ? {
                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
              } : false}
              value={dayjs(date[0])}
              allowClear={false}
              onChange={(e, d) => changeDate(d, 0)}
              disabled={disabled}
              disabledDate={disabledDateStart}
              presets={
                displayCom.includes('Presets') && presets
                  .filter(item => !ignoreDateType || !ignoreDateType.includes(item.id))
                  .map(item => ({ label: item.label, value: item.value[0] })) as any
              }
            />
          </Col>
          <Col span={''}>
            <DatePicker
              open={pickerEndOpen}
              onOpenChange={setPickerEndOpen}
              showNow={false}
              style={{ width }}
              placeholder={i18n.t('endTime') as string}
              placement={'bottomLeft'}
              format={format}
              showTime={showTime ? {
                defaultValue: dayjs('23:59:59', 'HH:mm:ss'),
              } : false}
              value={dayjs(date[1])}
              allowClear={false}
              onChange={(e, d) => changeDate(d, 1)}
              disabled={disabled}
              disabledDate={disabledDateEnd}
              presets={
                displayCom.includes('Presets') && presets
                  .filter(item => !ignoreDateType || !ignoreDateType.includes(item.id))
                  .map(item => ({ label: item.label, value: item.value[1] })) as any
              }
            />
          </Col>
        </>}
        {
          components && components
        }
        {displayCom.includes('Radio') &&
          <Col style={{ textAlign: textAlign as any }}>
            <Radio.Group
              style={{ width: '100%' }}
              optionType="button"
              buttonStyle="solid"
              value={date[2]}
              options={enumToOptions(DATE_TYPE).filter(item => !ignoreDateType || !ignoreDateType.includes(item.value))}
              onChange={(e) => dateSwitch(e.target.value)}
              disabled={disabled}
            />
          </Col>}
      </>
    )
  }

export const LayoutUpdateControl = ({ callback }: {
  callback?: () => void; // import callback function
}) => {

  enum UPDATE_TYPE {
    "15s" = 1,
    "30s" = 2,
    "60s" = 3,
    "300s" = 4,
    "close" = 0
  }

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const handleUpdate = (type: number) => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    switch (type) {
      case UPDATE_TYPE['15s']:
        startInterval(15);
        break;
      case UPDATE_TYPE['30s']:
        startInterval(30);
        break;
      case UPDATE_TYPE['60s']:
        startInterval(60);
        break;
      case UPDATE_TYPE['300s']:
        startInterval(300);
        break;
      case UPDATE_TYPE['close']:
        break;
    }
  };

  const startInterval = (seconds: number) => {
    const interval = seconds * 1000;
    if (callback) {
      callback();
      const id = setInterval(() => {
        callback();
      }, interval);
      setIntervalId(id);
    }
  };

  return (
    <Form.Item name="updateType">
      <Row align="middle" gutter={10}>
        <Col>{i18n.t('updateTime')}</Col>
        <Col className="w-6">
          <Select
            placeholder={i18n.t('pleaseSelect')}
            onChange={handleUpdate}
            options={enumToOptions(UPDATE_TYPE)}
          />
        </Col>
      </Row>
    </Form.Item>
  )
}

export const LayoutPagination = ({
  defaultPageSize = 10,
  defaultPageIndex = 1,
  page,
  setPage,
  total,
  hiddenSizeChange = true
}: {
  defaultPageSize?: number;
  defaultPageIndex?: number;
  page?: number[];
  setPage: React.Dispatch<React.SetStateAction<number[]>>;
  total: number;
  hiddenSizeChange?: boolean;
}) => {
  const [current, setCurrent] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);

  // 換頁時滑動到頂部
  useEffect(() => {
    if (page && page[0]) {
      window.scrollTo(0, 0);
    }
  }, [page]);

  const onShowSizeChange: PaginationProps['onShowSizeChange']
    = (current, pageSize) => {
      setPage([current, pageSize]);
      setCurrent(current);
      setCurrentPageSize(pageSize);
    };

  return (
    <Row justify="end" className="mt-1">
      <Pagination
        showSizeChanger={hiddenSizeChange}
        total={total}
        onChange={onShowSizeChange}
        current={page ? page[0] : current}
        pageSize={page ? page[1] : currentPageSize}
        defaultPageSize={defaultPageSize}
        showTotal={(total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`}
      />
    </Row>
  )
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Memo;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input maxLength={100} showCount />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const Export = ({ url, fileName, sheetName, columns, param, otherData, reverse, externalData, showLink = false, className = 'excel-btn', title = "exportExcel", disabled = false }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const exportExcel = async () => {
    const query = QueryString.stringify(param, {
      addQueryPrefix: true,
    });
    setIsLoading(true);
    try {
      await fetch(`api${url}${query}`, {
        headers: {
          "Authorization": Cookies.get(COOKIE.TOKEN) as any
        },
        method: 'GET',
      }).then(res => res.json())
        .then(resData => {
          // api資料
          if (!externalData) {
            const data = resData.Data;
            if (data.length === 0) return message.success(i18n.t('listIsNoData'));
            if (data.length !== 0) {
              const excel = new Excel();
              excel
                .addSheet(sheetName)
                .addColumns(columns)
                .addDataSource(
                  reverse
                    ? data.map((item: any) => ({ ...item, otherData })).reverse()
                    : data.map((item: any) => ({ ...item, otherData }))
                  , { str2Percent: true }
                )
                .saveAs(`${fileName || dayjs().format('YYYYMMDDHHmm')}.xlsx`, "blob");
              message.success(i18n.t('downloadSuccess'));
            }
            // 外部資料
          } else {
            const excel = new Excel();
            excel
              .addSheet(sheetName)
              .addColumns(columns)
              .addDataSource(
                externalData.map((item: any) => ({ ...item, otherData }))
                , { str2Percent: true }
              )
              .saveAs(`${dayjs().format('YYYYMMDDHHmm')}.xlsx`, "blob");
            message.success(i18n.t('downloadSuccess'));
          }
        })
      setIsLoading(false);
    } catch (error) {
      message.success(i18n.t('listIsNoData'));
      setIsLoading(false);
    }
  }

  return (
    <Button loading={isLoading} className={className} type={showLink ? 'link' : undefined}
      disabled={disabled} onClick={exportExcel}>{i18n.t(title)}</Button>
  )
}

// 詢問視窗
export const InquiryWindow = ({ isOpen, close, msg, action, isLoading }: any) => {
  const handleSubmit = () => {
    action();
    close();
  }

  return (
    <Modal
      open={isOpen}
      onOk={handleSubmit}
      onCancel={close}
      centered
      width={450}
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" key="cancel" onClick={close}>
              {i18n.t('cancel')}
            </Button>
          </Col>
          <Col>
            <Button className="mt-1" key="confirm" type="primary" loading={isLoading} onClick={handleSubmit}>
              {i18n.t('confirm')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{msg || i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}

// 會員等級在列表的顯示
export const MemberLevelTableDisplay = ({ levelName, noneIsAll }: any) => {
  const list = levelName?.sort((a: number, b: number) => a - b) || []
  return (
    <>
      {list.length === 0 && (noneIsAll ? i18n.t('ALL') : '-')}
      {list.length > 3
        ? <>
          {
            list.map((item: string, i: number) => (
              <span key={i}>{i <= 2 && `${item}／`}</span>
            ))
          }
          <span>
            <Tooltip placement='right' title={
              <>
                {
                  list.map((item: string, i: number) => (
                    <div key={i}>{i >= 3 && `${item}`}</div>
                  ))
                }
              </>
            }>
              <EllipsisOutlined />
            </Tooltip>
          </span>
        </>
        : <span>{list.join("／")}</span>
      }
    </>
  )
}

// 遊戲商在列表的顯示
export const ProviderTableDisplay = ({ Providers, Format = 'PC', gameCategorys, promotionCategory }: any) => {
  const site = useSite();
  // const list = providerName?.sort((a: number, b: number) => a - b) || []
  const renderedData = Providers?.map((obj: any, index: any) => {
    const ProviderName = specialProviderName(obj.ProviderName, site.data.SiteName, obj.ProviderCode);
    const CategoryCode = Format === 'PC' ? i18n.t(obj.CategoryCode) : '';
    return `${ProviderName}${CategoryCode}`;
  });

  const renderGameCategories = () => {
    switch (gameCategorys?.length) {
      case 1:
        return <span>{`${i18n.t(gameCategorys[0])}`}</span>;
      case 2:
        return <span>{`${i18n.t(gameCategorys[0])}／${i18n.t(gameCategorys[1])}`}</span>;
      case 3:
        return <span>{`${i18n.t(gameCategorys[0])}／${i18n.t(gameCategorys[1])}／${i18n.t(gameCategorys[2])}`}</span>;
    }
  }

  return (
    <>
      {promotionCategory === BONUS_CATEGORY.jackpot && i18n.t('-')}
      {(promotionCategory !== BONUS_CATEGORY.jackpot && gameCategorys?.length === 0 && Providers?.length === 0) && i18n.t('ALL')}
      {promotionCategory !== BONUS_CATEGORY.jackpot && gameCategorys?.length !== 0 ? (
        Providers?.length > 3 ? (
          <>
            {
              renderedData?.map((item: any, i: any) => {
                return <span key={i}>{i <= 2 && `${item}／`}</span>;
                // const provider = item.split('-')[0];
                // const type = item.split('-')[1];
                // return (
                //   <span key={i}>{i <= 2 && `${provider}-${i18n.t(type)}／`}</span>
                // )
              })
            }
            <span>
              <Tooltip placement="right" title={
                <>
                  {
                    renderedData.map((item: any, i: any) => {
                      return <div key={i}>{i >= 3 && `${item}`}</div>;
                      // const provider = item.split('-')[0];
                      // const type = item.split('-')[1];
                      // return (
                      //   <div key={i}>{i >= 3 && `${provider}-${i18n.t(type)}`}</div>
                      // )
                    })
                  }
                </>
              }>
                <EllipsisOutlined />
              </Tooltip>
            </span>
          </>
        ) : Providers?.length > 0 ? (
          <span>{renderedData?.join("/")}</span>
        ) : (
          gameCategorys?.length > 3 ? (
            <>
              {gameCategorys?.map((item: any, i: any) => (
                <span key={i}>{i <= 2 && `${i18n.t(item)}／`}</span>
              ))}
              <span>
                <Tooltip placement="right" title={
                  <>
                    {gameCategorys.map((item: any, i: any) => (
                      <div key={i}>{i >= 3 && `${i18n.t(item)}`}</div>
                    ))}
                  </>
                }>
                  <EllipsisOutlined />
                </Tooltip>
              </span>
            </>
          ) : (
            <span>
              {renderGameCategories()}
            </span>
          )
        )
      ) : null}
    </>
  )
}

// 上傳圖片
export const UploadImage = ({
  form, name,
  imageData, setImageData,
  url, disabled,
  preUrl,
  crop, cropShape = 'rect',
  maxCount = 1, w, h,
  onRemove, button,
  accept = ".jpg, .png",
  fillColor,
  remove = false,
}: any) => {

  const [loading, setLoading] = useState(false);

  const handleFileListChange = async (data: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = data;
    setImageData(fileList);
  };

  useEffect(() => {
    if (imageData && (imageData[0] || imageData[1]) && imageData[0].status === 'done') {

      const newFormData = new FormData();
      const file = imageData[0].originFileObj;

      // NOTE: 目前都只能傳一張
      determineImageAttributes(file, w, h, {
        pass: () => {
          newFormData.append('Media', file as RcFile);

          try {
            fetch(`api${url}`, {
              headers: {
                "Authorization": Cookies.get(COOKIE.TOKEN) as any
              },
              method: 'POST',
              body: newFormData,
            })
              .then(res => {
                const response: any = res.json();
                if (res.ok) return response;
                else throw response;
              })
              .then(data => {
                // 成功
                if (data.State === 'Success') {
                  setImageData([{
                    uid: `${data.Data[0]}?${Date.now()}`,
                    name: `${data.Data[0]}?${Date.now()}`,
                    url: `${data.Data[0]}?${Date.now()}`,
                  }]);

                  if (form && name) {
                    form.setFieldValue(name, url);
                    form.validateFields([name]);
                  }
                } else {
                  failCase(data.Message);
                };
              })
          } catch (e: any) {
            failCase(e.Message);
          }
        },
        fail: () => {
          failCase('imageSizeWrong');
        }
      });
    }
  }, [imageData])

  // 上傳失敗
  const failCase = (m: any) => {
    const msg = RESPONSE_CODE[m as keyof typeof RESPONSE_CODE];
    message.error(msg || i18n.t('processingFailed'));
    // 一進來初始圖片先存useState 就不用外部設定
    preUrl ? setImageData([{
      uid: `${preUrl}?${Date.now()}`,
      name: `${preUrl}?${Date.now()}`,
      url: `${preUrl}?${Date.now()}`,
    }]) : setImageData([]);
  }

  // 有上傳圖片才會顯示圖片,建站的時候還沒上傳圖片則不顯示圖片
  const isShowPicture = imageData?.length > 0 && imageData[0]?.url?.includes('https');

  const upload =
    <Upload
      multiple
      accept={accept}
      listType="picture"
      maxCount={maxCount}
      fileList={imageData}
      onChange={handleFileListChange}
      customRequest={(e: any) => e.onSuccess()}
      showUploadList={isShowPicture? {
        showRemoveIcon: remove
      }: false}
      onRemove={onRemove}
    >
      {button ? button : <Button type="primary" disabled={disabled}>{i18n.t('upload')}</Button>}
    </Upload>

  return (
    <Spin spinning={loading}>
      {
        crop
          ?
          <ImgCrop
            cropShape={cropShape}
            fillColor={fillColor}
            aspect={w ? w / h : 1}
            showGrid
            showReset
            resetText={`${i18n.t('reset')}`}
            modalTitle={`${i18n.t('editImage')}`}
          >
            {upload}
          </ImgCrop>
          : (upload)
      }
    </Spin>
  )
}
/* 1.9 統一輸入框格式 */
export const LayoutTextArea = ({
  formName,
  formLabel,
  formRules,
  autoSize = { minRows: 2, maxRows: 1 },
  maxLength = 100,
  disabled = false
}: {
  formName?: string;
  formLabel?: DefaultTFuncReturn;
  formRules?: any[];
  required?: boolean;
  autoSize?: { minRows: number, maxRows: number };
  maxLength?: number;
  disabled?: boolean;
}) => {
  return (
    <Form.Item name={formName} label={formLabel} rules={formRules}>
      <Input.TextArea
        size="middle"
        placeholder={`${i18n.t('inputData')}`}
        autoSize={autoSize}
        count={{
          show: true,
          max: maxLength,
        }}
        disabled={disabled}
      />
    </Form.Item>
  )
}