import { Button, Col, Form, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupAddPermission, ViewPermissionList } from 'components/advanced.component';
import { LayoutNav, LayoutTabPermission } from 'components/layout.component';
import { PERMISSION_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { enumToOptions } from 'utils/common';

// 權限管理

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [param, setParam] = useState<any>({
    PageIndex: 1,
    PageSize: 10,
  });
  const { data, mutate, isValidating } = $get({
    url: 'api/permission/list',
    params: param
  });
  const [form] = useForm();

  const search = (formData: any) => {
    setParam({
      ...param,
      PageIndex: 1,
      Status: formData.Status,
    })
    mutate();
  }
  const onClear = () => {
    form.resetFields();
    setParam({
      ...param,
      PageSize: 10,
      PageIndex: 1,
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPermission activeKey="1" />
        <Row className="mt-1" gutter={[0, 12]}>
          <Col span={24}>
            <Form form={form} onFinish={search} initialValues={{ Status: '' }}>
              <Row align="middle" gutter={10}>
                <Col className="w-12">
                  <Form.Item name="Status">
                    <Select
                      options={[
                        { value: '', label: i18n.t('allStatus') },
                        ...enumToOptions(PERMISSION_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" onClick={form.submit}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={24}>
            {$p('00703') && <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>}
          </Col>
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewPermissionList data={data} setParams={setParam} params={param} mutate={mutate} />
            </Spin>
          </Col>
        </Row>
        <PopupAddPermission isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} mutate={mutate} />
      </Content>
    </div>
  );
};

export default PageMain;