// ALB相關
export const ALB: any = {
  Status: {
    100: "Betting",
    101: "Bet failed",
    110: "Unpaid",
    111: "Paid",
    120: "Refund"
  },
  GameType: {
    101: "Baccarat",
    111: "VIP Baccarat",
    103: "Quick Baccarat",
    104: "See Card Baccarat",
    110: "Insurance Baccarat",
    201: "Sic Bo (HiLo)",
    202: "Fish Prawn Crab",
    301: "Dragon Tiger",
    401: "Roulette",
    501: "Classic Pok Deng / Two Sides Pok Deng",
    601: "Rock Paper Scissors",
    801: "Bull Bull",
    901: "Win Three Cards / Three Pictures",
    702: "Ultimate Texas Hold'em",
    602: "Andar Bahar",
    603: "Teen Patti 20-20",
    703: "Casino War",
    704: "Infinite Blackjack",
  },
  Commission: {
    100: "Baccarat",
    101: "No Commission",
  }
}

export const ALB_BetType :any = (gameTypeName: any) => {
  switch (gameTypeName) {
    case "Baccarat":
    case "VIP Baccarat":
    case "Quick Baccarat":
    case "See Card Baccarat":
    case "Insurance Baccarat":
      return {
        1001: "Banker",
        1002: "Player",
        1003: "Tie",
        1006: "Banker Pair",
        1007: "Player Pair",
        1100: "Lucky 6",
        1211: "Banker Natural",
        1212: "Player Natural",
        1223: "Any Pair",
        1224: "Perfect Pair",
        1231: "Banker Dragon Bonus",
        1232: "Player Dragon Bonus",
        1301: "Banker Insurance(First)",
        1302: "Player Insurance(Second)",
        1303: "Player Insurance(First)",
        1304: "Player Insurance(Second)",
        1401: "Tiger",
        1402: "Small Tiger",
        1403: "Big Tiger",
        1404: "Tiger Pair",
        1405: "Tiger Tie",
        1501: "Banker Fabulous 4",
        1502: "Player Fabulous 4",
        1503: "Banker Precious Pair",
        1504: "Player Precious Pair",
        1601: "Banker Black",
        1602: "Banker Red",
        1603: "Player Black",
        1604: "Player Red",
        1605: "Any 6"
      }
    case "Dragon Tiger":
      return {
        2001: "Dragon",
        2002: "Tiger",
        2003: "Tie",
      }
    case "Sic Bo (HiLo)":
      return {
        3001: "Small",
        3002: "Odd",
        3003: "Even",
        3004: "Big",
        3005: "All or specific triples for one",
        3006: "All or specific triples for two",
        3007: "All or specific triples for three",
        3008: "All or specific triples for four",
        3009: "All or specific triples for five",
        3010: "All or specific triples for six",
        3011: "All or any triple",
        3012: "Double: 1",
        3013: "Double: 2",
        3014: "Double: 3",
        3015: "Double: 4",
        3016: "Double: 5",
        3017: "Double: 6",
        3018: "Sum: 4",
        3019: "Sum: 5",
        3020: "Sum: 6",
        3021: "Sum: 7",
        3022: "Sum: 8",
        3023: "Sum: 9",
        3024: "Sum: 10",
        3025: "Sum: 11",
        3026: "Sum: 12",
        3027: "Sum: 13",
        3028: "Sum: 14",
        3029: "Sum: 15",
        3030: "Sum: 16",
        3031: "Sum: 17",
        3033: "Two Dice Combination: 1,2",
        3034: "Two Dice Combination: 1,3",
        3035: "Two Dice Combination: 1,4",
        3036: "Two Dice Combination: 1,5",
        3037: "Two Dice Combination: 1,6",
        3038: "Two Dice Combination: 2,3",
        3039: "Two Dice Combination: 2,4",
        3040: "Two Dice Combination: 2,5",
        3041: "Two Dice Combination: 2,6",
        3042: "Two Dice Combination: 3,4",
        3043: "Two Dice Combination: 3,5",
        3044: "Two Dice Combination: 3,6",
        3045: "Two Dice Combination: 4,5",
        3046: "Two Dice Combination: 4,6",
        3047: "Two Dice Combination: 5,6",
        3048: "Single: 1",
        3049: "Single: 2",
        3050: "Single: 3",
        3051: "Single: 4",
        3052: "Single: 5",
        3053: "Single: 6",
        3200: "Hi",
        3201: "Lo",
        3202: "11 Hi-Lo",
        3203: "Dice 1",
        3204: "Dice 2",
        3205: "Dice 3",
        3206: "Dice 4",
        3207: "Dice 5",
        3208: "Dice 6",
        3209: "1-2",
        3210: "1-3",
        3211: "1-4",
        3212: "1-5",
        3213: "1-6",
        3214: "2-3",
        3215: "2-4",
        3216: "2-5",
        3217: "2-6",
        3218: "3-4",
        3219: "3-5",
        3220: "3-6",
        3221: "4-5",
        3222: "4-6",
        3223: "5-6",
        3224: "1-Lo",
        3225: "2-Lo",
        3226: "3-Lo",
        3227: "4-Lo",
        3228: "5-Lo",
        3229: "6-Lo",
        3230: "3-Hi",
        3231: "4-Hi",
        3232: "5-Hi",
        3233: "6-Hi",
        3234: "1,2,3",
        3235: "2,3,4",
        3236: "3,4,5",
        3237: "4,5,6"
      }
    case "Roulette":
      return {
        4001: "Small",
        4002: "Even",
        4003: "Red",
        4004: "Black",
        4005: "Odd",
        4006: "Big",
        4007: "First dozen",
        4008: "Second dozen",
        4009: "Third dozen",
        4010: "First column",
        4011: "Second column",
        4012: "Third column",
        4013: "Direct bet: (0)",
        4014: "Direct bet: (1)",
        4015: "Direct bet: (2)",
        4016: "Direct bet: (3)",
        4017: "Direct bet: (4)",
        4018: "Direct bet: (5)",
        4019: "Direct bet: (6)",
        4020: "Direct bet: (7)",
        4021: "Direct bet: (8)",
        4022: "Direct bet: (9)",
        4023: "Direct bet: (10)",
        4024: "Direct bet: (11)",
        4025: "Direct bet: (12)",
        4026: "Direct bet: (13)",
        4027: "Direct bet: (14)",
        4028: "Direct bet: (15)",
        4029: "Direct bet: (16)",
        4030: "Direct bet: (17)",
        4031: "Direct bet: (18)",
        4032: "Direct bet: (19)",
        4033: "Direct bet: (20)",
        4034: "Direct bet: (21)",
        4035: "Direct bet: (22)",
        4036: "Direct bet: (23)",
        4037: "Direct bet: (24)",
        4038: "Direct bet: (25)",
        4039: "Direct bet: (26)",
        4040: "Direct bet: (27)",
        4041: "Direct bet: (28)",
        4042: "Direct bet: (29)",
        4043: "Direct bet: (30)",
        4044: "Direct bet: (31)",
        4045: "Direct bet: (32)",
        4046: "Direct bet: (33)",
        4047: "Direct bet: (34)",
        4048: "Direct bet: (35)",
        4049: "Direct bet: (36)",
        4050: "Three numbers: (0/1/2)",
        4051: "Three numbers: (0/2/3)",
        4052: "Corner bet: (0/1/2/3)",
        4053: "Corner bet: (0/1/2/3)",
        4054: "Corner bet: (0/1/2/3)",
        4055: "Corner bet: (0/1/2/3)",
        4056: "Separate: (1/2)",
        4057: "Separate: (2/3)",
        4058: "Separate: (4/5)",
        4059: "Separate: (5/6)",
        4060: "Separate: (7/8)",
        4061: "Separate: (8/9)",
        4062: "Separate: (10/11)",
        4063: "Separate: (11/12)",
        4064: "Separate: (13/14)",
        4065: "Separate: (14/15)",
        4066: "Separate: (16/17)",
        4067: "Separate: (17/18)",
        4068: "Separate: (19/20)",
        4069: "Separate: (20/21)",
        4070: "Separate: (22/23)",
        4071: "Separate: (23/24)",
        4072: "Separate: (25/26)",
        4073: "Separate: (26/27)",
        4074: "Separate: (28/29)",
        4075: "Separate: (29/30)",
        4076: "Separate: (31/32)",
        4077: "Separate: (32/33)",
        4078: "Separate: (34/35)",
        4079: "Separate: (35/36)",
        4080: "Separate: (1/4)",
        4081: "Separate: (4/7)",
        4082: "Separate: (7/10)",
        4083: "Separate: (10/13)",
        4084: "Separate: (13/16)",
        4085: "Separate: (16/19)",
        4086: "Separate: (19/22)",
        4087: "Separate: (22/25)",
        4088: "Separate: (25/28)",
        4089: "Separate: (28/31)",
        4090: "Separate: (31/34)",
        4091: "Separate: (2/5)",
        4092: "Separate: (5/8)",
        4093: "Separate: (8/11)",
        4094: "Separate: (11/14)",
        4095: "Separate: (14/17)",
        4096: "Separate: (17/20)",
        4097: "Separate: (20/23)",
        4098: "Separate: (23/26)",
        4099: "Separate: (26/29)",
        4100: "Separate: (29/32)",
        4101: "Separate: (32/35)",
        4102: "Separate: (3/6)",
        4103: "Separate: (6/9)",
        4104: "Separate: (9/12)",
        4105: "Separate: (12/15)",
        4106: "Separate: (15/18)",
        4107: "Separate: (18/21)",
        4108: "Separate: (21/24)",
        4109: "Separate: (24/27)",
        4110: "Separate: (27/30)",
        4111: "Separate: (30/33)",
        4112: "Separate: (33/36)",
        4113: "Corner bet: (1/5)",
        4114: "Corner bet: (2/6)",
        4115: "Corner bet: (4/8)",
        4116: "Corner bet: (5/9)",
        4117: "Corner bet: (7/11)",
        4118: "Corner bet: (8/12)",
        4119: "Corner bet: (10/14)",
        4120: "Corner bet: (11/15)",
        4121: "Corner bet: (13/17)",
        4122: "Corner bet: (14/18)",
        4123: "Corner bet: (16/20)",
        4124: "Corner bet: (17/21)",
        4125: "Corner bet: (19/23)",
        4126: "Corner bet: (20/24)",
        4127: "Corner bet: (22/26)",
        4128: "Corner bet: (23/27)",
        4129: "Corner bet: (25/29)",
        4130: "Corner bet: (26/30)",
        4131: "Corner bet: (28/32)",
        4132: "Corner bet: (29/33)",
        4133: "Corner bet: (31/35)",
        4134: "Corner bet: (32/36)",
        4135: "Street bet: (1~3)",
        4136: "Street bet: (4~6)",
        4137: "Street bet: (7~9)",
        4138: "Street bet: (10~12)",
        4139: "Street bet: (13~15)",
        4140: "Street bet: (16~18)",
        4141: "Street bet: (19~21)",
        4142: "Street bet: (22~24)",
        4143: "Street bet: (25~27)",
        4144: "Street bet: (28~30)",
        4145: "Street bet: (31~33)",
        4146: "Street bet: (34~36)",
        4147: "Line bet: (1~6)",
        4148: "Line bet: (4~9)",
        4149: "Line bet: (7~12)",
        4150: "Line bet: (10~15)",
        4151: "Line bet: (13~18)",
        4152: "Line bet: (16~21)",
        4153: "Line bet: (19~24)",
        4154: "Line bet: (22~27)",
        4155: "Line bet: (25~30)",
        4156: "Line bet: (28~33)",
        4157: "Line bet: (31~36)"
      }
    case "Classic Pok Deng / Two Sides Pok Deng":
      return {
        5001: "Player 1 (Classic Pok Deng)",
        5002: "Player 2 (Classic Pok Deng)",
        5003: "Player 3 (Classic Pok Deng)",
        5004: "Player 4 (Classic Pok Deng)",
        5005: "Player 5 (Classic Pok Deng)",
        5011: "Player 1 Pair",
        5012: "Player 2 Pair",
        5013: "Player 3 Pair",
        5014: "Player 4 Pair",
        5015: "Player 5 Pair",
        5101: "Player 1 (Two Sides Pok Deng)",
        5102: "Player 2 (Two Sides Pok Deng)",
        5103: "Player 3 (Two Sides Pok Deng)",
        5104: "Player 4 (Two Sides Pok Deng)",
        5105: "Player 5 (Two Sides Pok Deng)",
        5106: "Banker 1",
        5107: "Banker 2",
        5108: "Banker 3",
        5109: "Banker 4",
        5110: "Banker 5",
        5111: "Banker Pair"
      }
    case "Rock Paper Scissors":
      return {
        6001: "Gold Rock",
        6002: "Gold Paper",
        6003: "Gold Scissors",
        6004: "Silver Rock",
        6005: "Silver Paper",
        6006: "Silver Scissors",
        6007: "Bronze Rock",
        6008: "Bronze Paper",
        6009: "Bronze Scissors"
      }
    case "Bull Bull":
      return {
        8001: "Banker 1 Equal",
        8011: "Banker 1 Double",
        8101: "Player 1 Equal",
        8111: "Player 1 Double",
        8002: "Banker 2 Equal",
        8012: "Banker 2 Double",
        8102: "Player 2 Equal",
        8112: "Player 2 Double",
        8003: "Banker 3 Equal",
        8013: "Banker 3 Double",
        8103: "Player 3 Equal",
        8113: "Player 3 Double",
        8021: "Banker 1 Super Bull",
        8121: "Player 1 Super Bull",
        8022: "Banker 2 Super Bull",
        8122: "Player 2 Super Bull",
        8023: "Banker 3 Super Bull",
        8123: "Player 3 Super Bull"
      }
    case "Win Three Cards / Three Pictures":
      return {
        9001: "Dragon",
        9002: "Phoenix",
        9003: "Pair 8 Plus",
        9004: "Straight",
        9005: "Flush",
        9006: "Straight Flush",
        9007: "Three of a Kind",
        9101: "(Three Pictures) Dragon",
        9102: "(Three Pictures) Phoenix",
        9103: "Tie",
        9114: "Dragon Three Pictures",
        9124: "Phoenix Three Pictures"
      }
    case "Ultimate Texas Hold'em":
      return {
        7201: "Player 1 (Ante)",
        7202: "Player 1 (Blind)",
        7203: "Player 1 (Trips)",
        7204: "Player 1 Play (Raise 4X)",
        7205: "Player 1 Play (Raise 3X)",
        7206: "Player 1 Play (Raise 2X)",
        7207: "Player 1 Play (Raise 1X)",
        7211: "Player 2 (Ante)",
        7212: "Player 2 (Blind)",
        7213: "Player 2 (Trips)",
        7214: "Player 2 Play (Raise 4X)",
        7215: "Player 2 Play (Raise 3X)",
        7216: "Player 2 Play (Raise 2X)",
        7217: "Player 2 Play (Raise 1X)",
        7221: "Player 3 (Ante)",
        7222: "Player 3 (Blind)",
        7223: "Player 3 (Trips)",
        7224: "Player 3 Play (Raise 4X)",
        7225: "Player 3 Play (Raise 3X)",
        7226: "Player 3 Play (Raise 2X)",
        7227: "Player 3 Play (Raise 1X)"
      }
    case "Andar Bahar":
      return {
        6201: "Andar",
        6202: "Bahar",
        6203: "1-5 Cards",
        6204: "6-10 Cards",
        6205: "11-15 Cards",
        6206: "16-25 Cards",
        6207: "26-30 Cards",
        6208: "31-35 Cards",
        6209: "36-40 Cards",
        6210: "41+ Cards",
        6211: "1st Andar",
        6212: "1st Bahar"
      }
    case "Teen Patti 20-20":
      return {
        6301: "Player A",
        6302: "Player B",
        6303: "Tie",
        6304: "Player A Pair+",
        6305: "Player B Pair+",
        6306: "6 Card Bonus"
      }
    case "Fish Prawn Crab":
      return {
        3301: "Fish",
        3302: "Prawn",
        3303: "Calabash",
        3304: "Coins",
        3305: "Crab",
        3306: "Cock",
        3307: "Specific Single Red",
        3308: "Specific Single Green",
        3309: "Specific Single Blue",
        3310: "Specific Double Red",
        3311: "Specific Double Green",
        3312: "Specific Double Blue",
        3313: "Specific Triple Red",
        3314: "Specific Triple Green",
        3315: "Specific Triple Blue",
        3316: "Any Triple Color",
        3317: "Calabash & Prawn",
        3318: "Calabash & Fish",
        3319: "Calabash & Cock",
        3320: "Calabash & Crab",
        3321: "Calabash & Coins",
        3322: "Prawn & Fish",
        3323: "Prawn & Cock",
        3324: "Prawn & Crab",
        3325: "Prawn & Coins",
        3326: "Fish & Cock",
        3327: "Fish & Crab",
        3328: "Fish & Coins",
        3329: "Cock & Crab",
        3330: "Cock & Coins",
        3331: "Crab & Coins"
      }
    case "Infinite Blackjack":
      return {
        7401: "Ante",
        7402: "Ante_Double",
        7403: "Split",
        7404: "Pair",
        7405: "Lucky 3",
        7406: "Hot 3",
        7407: "Bust it",
        7408: "Insurance",
        7409: "Lucky Ladies"
      }
    case "Casino War":
      return {
        7301: "Player 1 Ante",
        7302: "Player 1 First Tie",
        7303: "Player 1 War",
        7304: "Player 1 Second Tie",
        7311: "Player 2 Ante",
        7312: "Player 2 First Tie",
        7313: "Player 2 War",
        7314: "Player 2 Second Tie",
        7321: "Player 3 Ante",
        7322: "Player 3 First Tie",
        7323: "Player 3 War",
        7324: "Player 3 Second Tie"
      }
  }
}

export const ALB_gameResult: any = (gameTypeName: any, value: any) => {
  function convertString(input: any) {
    // 将字符串中的花色和数字映射成对应的文字
    const suitMap: any = {
      '1': 'Spade',
      '2': 'Heart',
      '3': 'Club',
      '4': 'Diamond',
      '-1': ''
    };
    const valueMap: any = {
      '01': '1',
      '02': '2',
      '03': '3',
      '04': '4',
      '05': '5',
      '06': '6',
      '07': '7',
      '08': '8',
      '09': '9',
      '10': '10',
      '11': 'J',
      '12': 'Q',
      '13': 'K'
    };
    //切割字符串并处理每个部分
    const parts = input.split('},{');
    const convertedParts: any = [];
    parts.forEach((part: string) => {
      const cards = part.match(/\d+-?\d+/g); // 匹配数字部分，包括-1
      if (cards) {
        const convertedCards = cards.map((card: any) => {
          const suit = suitMap[card.substring(0, 1)];
          const value = valueMap[card.substring(1)];
          if (value === '') return ''; // 当卡片值为-1时返回空字符串，对应于valueMap中的'-1': ''
          return `${suit} ${value}`; // 只有当value有意义时才拼接字符串
        }).filter((card) => card !== ''); // 过滤掉 undefined 或 空字符串
        if (convertedCards.length > 0) {
          convertedParts.push(`{${convertedCards.join(',')}}`); // 只有当convertedCards非空时才加入最后的结果
        }
      }
    });
    return convertedParts.join(','); // 在整个字符串中的组合之间使用逗号分隔
  }

  function fishPrawnCrabConvertString(input: any) {

    const mapping: any = {
      '1': 'Fish',
      '2': 'Prawn',
      '3': 'Calabash',
      '4': 'Coins',
      '5': 'Crab',
      '6': 'Cock'
    };
    const items = input.slice(1, -1).split(',');
    const convertedItems = items.map((item: any)=> `${mapping[item]}`).join(',');
    return `{${convertedItems}}`;
  }

  function rockPaperScissorsConvertString(input: any) {

    const mapping: any = {
      '1': 'Paper',
      '2': 'Scissors',
      '3': 'Rock',
    };
    const items = input.slice(1, -1).split(',');
    const convertedItems = items.map((item: any)=> `${mapping[item]}`).join(',');
    return `{${convertedItems}}`;
  }

  switch (gameTypeName) {
    case "Baccarat":
    case "VIP Baccarat":
    case "Quick Baccarat":
    case "See Card Baccarat":
    case "Insurance Baccarat":
    case "Dragon Tiger":
    case "Classic Pok Deng / Two Sides Pok Deng":
    case "Bull Bull":
    case "Win Three Cards / Three Pictures":
    case "Ultimate Texas Hold'em":
    case "Andar Bahar":
    case "Teen Patti 20-20":
    case "Infinite Blackjack":
    case "Casino War":
      return convertString(value)
    case "Fish Prawn Crab":
      return fishPrawnCrabConvertString(value)
    case "Rock Paper Scissors":
      return rockPaperScissorsConvertString(value)
    //Sic Bo (HiLo)與Roulette不用轉換
    default:
      return value;
  }
}

export const ALB_gameResult2: any = (gameTypeName: any, value: any) => {

  function baccaratSeries(input: any) {
    const items = input.slice(1, -1).split(',');
    const playerScore = `Player ${items[0]}`;
    const bankerScore = `Banker ${items[1]}`;
    return `{${playerScore},${bankerScore}}`;
  }

  function dragonTigerSeries(input: any) {
    const items = input.slice(1, -1).split(',');
    const playerScore = `Dragon ${items[0]}`;
    const bankerScore = `Tiger ${items[1]}`;
    return `{${playerScore},${bankerScore}}`;
  }

  function pokDengSeries(input: any) {
    const pokDengSeriesMapping: any = {
      "0": "0",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "6": "6",
      "7": "7",
      "7.1": "Flush with No Point",
      "7.2": "Ten-suit Combo",
      "7.3": "Suits Combo",
      "7.4": "Special Pairs",
      "7.5": "AK Flush",
      "8": "8",
      "9": "9"
    };
    function mapScore(score: any): any{
      return pokDengSeriesMapping[score] || score;
    }
    const items = input.slice(1, -1).split(',').map(mapScore);

    const playerScore = `Banker ${items[0]}`;
    const bankerScore = `Player ${items[1]},Player ${items[2]},Player ${items[3]},Player ${items[4]},Player ${items[5]}`;
    return `{${playerScore},${bankerScore}}`;
  }

  function bullBullSeries(input: any) {
    const bullBullSeriesMapping: any = {
      "0": "No Bull",
      "1": "Bull 1",
      "2": "Bull 2",
      "3": "Bull 3",
      "4": "Bull 4",
      "5": "Bull 5",
      "6": "Bull 6",
      "7": "Bull 7",
      "8": "Bull 8",
      "9": "Bull 9",
      "10": "Bull Bull",
      "11": "5 Dukes"
    };
    function mapScore(score: any): any{
      return bullBullSeriesMapping[score] || score;
    }

    const items = input.slice(1, -1).split(',').map(mapScore);
    const playerScore = `Banker ${items[0]}`;
    const bankerScore = `Player ${items[1]},Player ${items[2]},Player ${items[3]}`;
    return `{${playerScore},${bankerScore}}`;
  }

  function winThreeCardsSeries(input: any) {
    const winThreeCardsSeriesMapping: any = {
      "111": "235",
      "110": "Three of a kind",
      "101": "Straight Flush",
      "100": "Flush",
      "011": "Straight",
      "010": "Pair",
      "001": "High Card",
      "30": "Three Pictures",
      "29": "Double Pictures 9",
      "19": "Single Picture 9",
      "09": "9",
      "28": "Double Pictures 8",
      "18": "Single Picture 8",
      "08": "8",
      "27": "Double Pictures 7",
      "17": "Single Picture 7",
      "07": "7",
      "26": "Double Pictures 6",
      "16": "Single Picture 6",
      "06": "6",
      "25": "Double Pictures 5",
      "15": "Single Picture 5",
      "05": "5",
      "24": "Double Pictures 4",
      "14": "Single Picture 4",
      "04": "4",
      "23": "Double Pictures 3",
      "13": "Single Picture 3",
      "03": "3",
      "22": "Double Pictures 2",
      "12": "Single Picture 2",
      "02": "2",
      "21": "Double Pictures 1",
      "11": "Single Picture 1",
      "01": "1",
      "20": "Double Pictures 0",
      "10": "Single Picture 0",
      "00": "0"
    };
    function mapScore(score: any): any{
      return winThreeCardsSeriesMapping[score] || score;
    }
    const items = input.slice(1, -1).split(',').map(mapScore);
    const playerScore = `Dragon ${items[0]}`;
    const bankerScore = `Phoenix ${items[1]}`;
    return `{${playerScore},${bankerScore}}`;
  }

  function texasSeries(input: any) {
    const texasSeriesMapping: any = {
      "0": "High Card",
      "1": "Pair",
      "2": "Two-Pair",
      "3": "Three of a Kind",
      "4": "Straight",
      "5": "Flush",
      "6": "Full House",
      "7": "Four of a Kind",
      "8": "Straight Flush",
      "9": "Royal Flush"
    };
    function mapScore(score: any): any{
      return texasSeriesMapping[score] || score;
    }
    const items = input.slice(1, -1).split(',').map(mapScore);
    const playerScore = `Banker ${items[0]}`;
    const bankerScore = `Player ${items[1]},Player ${items[2]},Player ${items[3]}`;
    return `{${playerScore},${bankerScore}}`;
  }

  function teenPattiSeries(input: any) {
    const teenPattiSeriesMapping: any = {
      "0": "High Card",
      "1": "Pair",
      "2": "Flush",
      "3": "Straight",
      "4": "Straight Flush",
      "5": "Three of a Kind",
      "6": "Three of a Kind (Ace)"
    };
    function mapScore(score: any): any{
      return teenPattiSeriesMapping[score] || score;
    }
    const items = input.slice(1, -1).split(',').map(mapScore);
    const playerScore = `PlayerA ${items[0]}`;
    const bankerScore = `PlayerB ${items[1]}`;
    return `{${playerScore},${bankerScore}}`;
  }

  function CasinoWarSeries(input: any) {
    const CasinoWarSeriesMapping: any = {
      "0": "First Round Win",
      "1": "First Round Lose",
      "2": "First Round Tie, Second Round Win",
      "3": "First Round Tie, Second Round Tie",
      "4": "First Round Tie, Second Round Lose",
    };
    function mapScore(score: any): any{
      return CasinoWarSeriesMapping[score] || score;
    }
    const items = input.slice(1, -1).split(',').map(mapScore);
    return `{${items[0]},${items[1]},${items[2]}}`;
  }

  switch (gameTypeName) {
    case "Baccarat":
    case "VIP Baccarat":
    case "Quick Baccarat":
    case "See Card Baccarat":
    case "Insurance Baccarat":
      return baccaratSeries(value)
    case "Dragon Tiger":
      return dragonTigerSeries(value)
    case "Classic Pok Deng / Two Sides Pok Deng":
      return pokDengSeries(value)
    case "Bull Bull":
      return bullBullSeries(value)
    case "Win Three Cards / Three Pictures":
      return winThreeCardsSeries(value)
    case "Ultimate Texas Hold'em":
      return texasSeries(value)
    case "Teen Patti 20-20":
      return teenPattiSeries(value)
    case "Casino War":
      return CasinoWarSeries(value)
    //Sic Bo (HiLo),Roulette,Andar Bahar,Fish Prawn Crab,Rock Paper Scissors沒有值所以不用轉換
    default:
      return value;
  }
}

// SA相關
export const SA: any = {
  GameType: {
    bac: "Baccarat",
    dtx: "Dragon Tiger",
    sicbo: "Sic bo",
    rot: "Roulette",
    pokdeng: "Pok Deng",
    andarbahar: "Andar Bahar",
    teenpatti2020: "Teen Patti",
    blackjack: "Blackjack",
    xocdia: "Xóc Đĩa"
  },
  HostId: {
    531: "M Xóc Đĩa",
    532: "M Sic bo",
    533: "M Roulette",
    534: "M Dragon Tiger",
    535: "M Pok Deng",
    536: "M Blackjack",
    537: "M Andar Bahar",
    538: "M Teen Patti",
    830: "E Roulette",
    840: "Blackjack",
    842: "E Sic bo",
    843: "E Dragon Tiger",
    846: "E Pok Deng",
    847: "E Andar Bahar",
    861: "C Roulette",
    866: "E Teen Patti",
    871: "Baccarat C01",
    872: "Baccarat C02",
    873: "Baccarat C03",
    874: "Baccarat C04",
    875: "Baccarat C05",
    876: "Baccarat C06",
    877: "Baccarat C07",
    878: "Speed Baccarat C08",
    901: "Baccarat D01",
    902: "Baccarat D02",
    903: "Baccarat D03",
    904: "Baccarat D04",
    905: "Baccarat D05",
    906: "Baccarat D06",
    907: "Baccarat D07",
    908: "Speed Baccarat D08"
  }
}

export const SA_BetType :any = (gameTypeName: any) => {
  switch (gameTypeName) {
    case "Baccarat":
      return {
        0: "Tie",
        1: "Player",
        2: "Banker",
        3: "Player Pair",
        4: "Banker Pair",
        25: "No Commission Tie",
        26: "No Commission Player",
        27: "No Commission Banker",
        28: "No Commission Player Pair",
        29: "No Commission Banker Pair",
        36: "Player Natural",
        37: "Banker Natural",
        40: "No Commission Player Natural",
        41: "No Commission Banker Natural",
        53: "No Commission Lucky Six",
        54: "Lucky Six"
      }
    case "Dragon Tiger":
      return {
        0: "Tie",
        1: "Dragon",
        2: "Tiger"
      }
    case "Sic bo":
      return {
        0: "Small",
        1: "Big",
        2: "Odd",
        3: "Even",
        4: "Number 1",
        5: "Number 2",
        6: "Number 3",
        7: "Number 4",
        8: "Number 5",
        9: "Number 6",
        10: "Triple 1",
        11: "Triple 2",
        12: "Triple 3",
        13: "Triple 4",
        14: "Triple 5",
        15: "Triple 6",
        16: "All same",
        17: "Point 4",
        18: "Point 5",
        19: "Point 6",
        20: "Point 7",
        21: "Point 8",
        22: "Point 9",
        23: "Point 10",
        24: "Point 11",
        25: "Point 12",
        26: "Point 13",
        27: "Point 14",
        28: "Point 15",
        29: "Point 16",
        30: "Point 17",
        31: "Specific Double 1,2",
        32: "Specific Double 1,3",
        33: "Specific Double 1,4",
        34: "Specific Double 1,5",
        35: "Specific Double 1,6",
        36: "Specific Double 2,3",
        37: "Specific Double 2,4",
        38: "Specific Double 2,5",
        39: "Specific Double 2,6",
        40: "Specific Double 3,4",
        41: "Specific Double 3,5",
        42: "Specific Double 3,6",
        43: "Specific Double 4,5",
        44: "Specific Double 4,6",
        45: "Specific Double 5,6",
        46: "Pair 1",
        47: "Pair 2",
        48: "Pair 3",
        49: "Pair 4",
        50: "Pair 5",
        51: "Pair 6",
        52: "All Odd",
        53: "2 Odd 1 Even",
        54: "2 Even 1 Odd",
        55: "All Even",
        56: "1234",
        57: "2345",
        58: "2356",
        59: "3456",
        60: "112",
        61: "113",
        62: "114",
        63: "115",
        64: "116",
        65: "221",
        66: "223",
        67: "224",
        68: "225",
        69: "226",
        70: "331",
        71: "332",
        72: "334",
        73: "335",
        74: "336",
        75: "441",
        76: "442",
        77: "443",
        78: "445",
        79: "446",
        80: "551",
        81: "552",
        82: "553",
        83: "554",
        84: "556",
        85: "661",
        86: "662",
        87: "663",
        88: "664",
        89: "665",
        90: "126",
        91: "135",
        92: "234",
        93: "256",
        94: "346",
        95: "123",
        96: "136",
        97: "145",
        98: "235",
        99: "356",
        100: "124",
        101: "146",
        102: "236",
        103: "245",
        104: "456",
        105: "125",
        106: "134",
        107: "156",
        108: "246",
        109: "345"
      }
    case "Roulette":
      return {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
        25: "25",
        26: "26",
        27: "27",
        28: "28",
        29: "29",
        30: "30",
        31: "31",
        32: "32",
        33: "33",
        34: "34",
        35: "35",
        36: "36",
        37: "0,1",
        38: "0,2",
        39: "0,3",
        40: "1,2",
        41: "1,4",
        42: "2,3",
        43: "2,5",
        44: "3,6",
        45: "4,5",
        46: "4,7",
        47: "5,6",
        48: "5,8",
        49: "6,9",
        50: "7,8",
        51: "7,10",
        52: "8,9",
        53: "8,11",
        54: "9,12",
        55: "10,11",
        56: "10,13",
        57: "11,12",
        58: "11,14",
        59: "12,15",
        60: "13,14",
        61: "13,16",
        62: "14,15",
        63: "14,17",
        64: "15,18",
        65: "16,17",
        66: "16,19",
        67: "17,18",
        68: "17,20",
        69: "18,21",
        70: "19,20",
        71: "19,22",
        72: "20,21",
        73: "20,23",
        74: "21,24",
        75: "22,23",
        76: "22,25",
        77: "23,24",
        78: "23,26",
        79: "24,27",
        80: "25,26",
        81: "25,28",
        82: "26,27",
        83: "26,29",
        84: "27,30",
        85: "28,29",
        86: "28,31",
        87: "29,30",
        88: "29,32",
        89: "30,33",
        90: "31,32",
        91: "31,34",
        92: "32,33",
        93: "32,35",
        94: "33,36",
        95: "34,35",
        96: "35,36",
        97: "0,1,2",
        98: "0,2,3",
        99: "1,2,3",
        100: "4,5,6",
        101: "7,8,9",
        102: "10,11,12",
        103: "13,14,15",
        104: "16,17,18",
        105: "19,20,21",
        106: "22,23,24",
        107: "25,26,27",
        108: "28,29,30",
        109: "31,32,33",
        110: "34,35,36",
        111: "1,2,4,5",
        112: "2,3,5,6",
        113: "4,5,7,8",
        114: "5,6,8,9",
        115: "7,8,10,11",
        116: "8,9,11,12",
        117: "10,11,13,14",
        118: "11,12,14,15",
        119: "13,14,16,17",
        120: "14,15,17,18",
        121: "16,17,19,20",
        122: "17,18,20,21",
        123: "19,20,22,23",
        124: "20,21,23,24",
        125: "22,23,25,26",
        126: "23,24,26,27",
        127: "25,26,28,29",
        128: "26,27,29,30",
        129: "28,29,31,32",
        130: "29,30,32,33",
        131: "31,32,34,35",
        132: "32,33,35,36",
        133: "1,2,3,4,5,6",
        134: "4,5,6,7,8,9",
        135: "7,8,9,10,11,12",
        136: "10,11,12,13,14,15",
        137: "13,14,15,16,17,18",
        138: "16,17,18,19,20,21",
        139: "19,20,21,22,23,24",
        140: "22,23,24,25,26,27",
        141: "25,26,27,28,29,30",
        142: "28,29,30,31,32,33",
        143: "31,32,33,34,35,36",
        144: "1st 12",
        145: "2nd 12",
        146: "3rd 12",
        147: "1st Row",
        148: "2nd Row",
        149: "3rd Row",
        150: "1~18",
        151: "19~36",
        152: "Odd",
        153: "Even",
        154: "Red",
        155: "Black",
        156: "0,1,2,3"
      }
    case "Pok Deng":
      return {
        0: "Player 1",
        1: "Player 2",
        2: "Player 3",
        3: "Player 4",
        4: "Player 5",
        5: "Player 1 Pair",
        6: "Player 2 Pair",
        7: "Player 3 Pair",
        8: "Player 4 Pair",
        9: "Player 5 Pair",
      }
    case "Andar Bahar":
      return {
        0: "Andar",
        1: "Bahar",
        2: "1-5",
        3: "6-10",
        4: "11-15",
        5: "16-20",
        6: "21-25",
        7: "26-30",
        8: "31-35",
        9: "36-49 (Obsolete soon)",
        10: "36-40",
        11: "41-45",
        12: "46-49",
        13: "First 3",
        14: "1st Andar",
        15: "1st Bahar",
        16: "NC. Andar",
        17: "NC. Bahar",
        18: "NC. 1-5",
        19: "NC. 6-10",
        20: "NC. 11-15",
        21: "NC. 16-20",
        22: "NC. 21-25",
        23: "NC. 26-30",
        24: "NC. 31-35",
        25: "NC. 36-40",
        26: "NC. 41-45",
        27: "NC. 46-49"
      }
    case "Teen Patti":
      return {
        0: "Tie",
        1: "Player A",
        2: "Player B",
        3: "Player A Pair +",
        4: "Player B Pair +",
        5: "6 Card Bonus"
      }
    case "Blackjack":
      return {
        0: "Player 1 - Single",
        1: "Player 2 - Single",
        2: "Player 3 - Single",
        3: "Player 5 - Single",
        4: "Player 6 - Single",
        5: "Player 7 - Single",
        6: "Player 8 - Single",
        7: "Player 1 - Split",
        8: "Player 2 - Split",
        9: "Player 3 - Split",
        10: "Player 5 - Split",
        11: "Player 6 - Split",
        12: "Player 7 - Split",
        13: "Player 8 - Split",
        14: "Player 1 - Double Down",
        15: "Player 2 - Double Down",
        16: "Player 3 - Double Down",
        17: "Player 5 - Double Down",
        18: "Player 6 - Double Down",
        19: "Player 7 - Double Down",
        20: "Player 8 - Double Down",
        21: "Player 1 - Pair",
        22: "Player 2 - Pair",
        23: "Player 3 - Pair",
        24: "Player 5 - Pair",
        25: "Player 6 - Pair",
        26: "Player 7 - Pair",
        27: "Player 8 - Pair",
        28: "Insurance"
      }
    case "Xóc Đĩa":
      return {
        0: "4 Red",
        1: "1 White 3 Red",
        2: "2 White 2 Red",
        3: "3 White 1 Red",
        4: "4 White",
        5: "4 White or 4 Red",
        6: "Big",
        7: "Small",
        8: "Odd",
        9: "Even"
      }
  }
}

// OG相關
export const OG: any = {
  Game_Code: {
    "og-lobby": "og-lobby",
    C1: "C1_baccarat",
    C2: "C2_baccarat",
    C3: "C3_baccarat",
    C5: "C5_baccarat",
    C6: "C6_baccarat",
    C7: "C7_baccarat",
    C8: "C8_DT",
    C9: "C9_DT",
    C10: "C10_DT",
    C11: "C11_DT",
    C12: "C12_sicbo",
    E1: "E1_baccarat",
    E2: "E2_baccarat",
    E3: "E3_baccarat",
    E5: "E5_baccarat",
    MW1: "MW1-money-wheel",
    MW2: "MW2-money-wheel",
    G1: "G1",
    G2: "G2",
    G3: "G3",
    G5: "G5",
    G6: "G6",
    G7: "G7",
    G8: "G8",
    G9: "G9",
    G10: "G10",
    P1: "P1_baccarat",
    P2: "P2_baccarat",
    P3: "P3_bull-bull",
    P5: "P5_3cards",
    P6: "P6_DT",
    P7: "P7_DT",
    P8: "P8_roulette",
    P9: "P9_baccarat",
    P10: "P10_baccarat",
    P11: "P11_baccarat",
    P12: "P12_baccarat",
    P13: "P13_bull-bull",
    P15: "P15_3cards",
    P16: "P16_DT",
    P17: "P17_DT",
    P18: "P18_DT",
    P19: "P19_DT",
    P20: "P20_roulette"
  }
}

export const OG_Bet_Place :any = (gameTypeName: any) => {
  switch (gameTypeName) {
    case "C1_baccarat":
    case "C2_baccarat":
    case "C3_baccarat":
    case "C5_baccarat":
    case "C6_baccarat":
    case "C7_baccarat":
    case "E1_baccarat":
    case "E2_baccarat":
    case "E3_baccarat":
    case "E5_baccarat":
    case "P1_baccarat":
    case "P2_baccarat":
    case "P9_baccarat":
    case "P10_baccarat":
    case "P11_baccarat":
    case "P12_baccarat":
    case "G1":
    case "G5":
    case "G6":
    case "G7":
      return {
        p: "Player",
        bs: "Banker Commission",
        b: "Banker",
        s: "Super Six",
        tie: "Tie",
        bp: "Banker Pair",
        pp: "Player Pair",
        bg: "Big",
        sm: "Small",
        etp: "Any Pair",
        pfp: "Perfect Pair",
        bb: "Banker Dragon",
        pb: "Player Dragon"
      }
    case "C8_DT":
    case "C9_DT":
    case "C10_DT":
    case "C11_DT":
    case "P6_DT":
    case "P7_DT":
    case "P16_DT":
    case "P17_DT":
    case "P18_DT":
    case "P19_DT":
    case "G8":
    case "G9":
      return {
        d: "Dragon",
        t: "Tiger",
        tie: "Tie",
        do: "Dragon Odd",
        de: "Dragon Even",
        dr: "Dragon Red",
        db: "Dragon Black",
        to: "Tiger Odd",
        te: "Tiger Even",
        tr: "Tiger Red",
        tb: "Tiger Black"
      }
    case "MW1-money-wheel":
    case "MW2-money-wheel":
      return {
        x1: "x1",
        x2: "x2",
        x5: "x5",
        x10: "x10",
        x20: "x20",
        og: "OG"
      }
    case "P3_bull-bull":
    case "P13_bull-bull":
    case "G2":
      return {
        be1: "Banker1 Equal",
        bd1: "Banker1 Double",
        bm1: "Banker1 Many",
        pe1: "Player1 Equal",
        pd1: "Player1 Double",
        pm1: "Player1 Many",
        be2: "Banker2 Equal",
        bd2: "Banker2 Double",
        bm2: "Banker2 Many",
        pe2: "Player2 Equal",
        pd2: "Player2 Double",
        pm2: "Player2 Many",
        be3: "Banker3 Equal",
        bd3: "Banker3 Double",
        bm3: "Banker3 Many",
        pe3: "Player3 Equal",
        pd3: "Player3 Double",
        pm3: "Player3 Many"
      }
    case "P8_roulette":
    case "P20_roulette":
    case "G10":
      return {
        s0: "Straight 0",
        s1: "Straight 1",
        s2: "Straight 2",
        s3: "Straight 3",
        s4: "Straight 4",
        s5: "Straight 5",
        s6: "Straight 6",
        s7: "Straight 7",
        s8: "Straight 8",
        s9: "Straight 9",
        s10: "Straight 10",
        s11: "Straight 11",
        s12: "Straight 12",
        s13: "Straight 13",
        s14: "Straight 14",
        s15: "Straight 15",
        s16: "Straight 16",
        s17: "Straight 17",
        s18: "Straight 18",
        s19: "Straight 19",
        s20: "Straight 20",
        s21: "Straight 21",
        s22: "Straight 22",
        s23: "Straight 23",
        s24: "Straight 24",
        s25: "Straight 25",
        s26: "Straight 26",
        s27: "Straight 27",
        s28: "Straight 28",
        s29: "Straight 29",
        s30: "Straight 30",
        s31: "Straight 31",
        s32: "Straight 32",
        s33: "Straight 33",
        s34: "Straight 34",
        s35: "Straight 35",
        s36: "Straight 36",
        sp0: "Split (0,1)",
        sp1: "Split (0,2)",
        sp2: "Split (0,3)",
        sp3: "Split (1,2)",
        sp4: "Split (2,3)",
        sp5: "Split (1,4)",
        sp6: "Split (2,5)",
        sp7: "Split (3,6)",
        sp8: "Split (4,5)",
        sp9: "Split (5,6)",
        sp10: "Split (4,7)",
        sp11: "Split (5,8)",
        sp12: "Split (6,9)",
        sp13: "Split (7,8)",
        sp14: "Split (8,9)",
        sp15: "Split (7,10)",
        sp16: "Split (8,11)",
        sp17: "Split (9,12)",
        sp18: "Split (10,11)",
        sp19: "Split (11,12)",
        sp20: "Split (10,13)",
        sp21: "Split (11,14)",
        sp22: "Split (12,15)",
        sp23: "Split (13,14)",
        sp24: "Split (14,15)",
        sp25: "Split (13,16)",
        sp26: "Split (14,17)",
        sp27: "Split (15,18)",
        sp28: "Split (16,17)",
        sp29: "Split (17,18)",
        sp30: "Split (16,19)",
        sp31: "Split (17,20)",
        sp32: "Split (18,21)",
        sp33: "Split (19,20)",
        sp34: "Split (20,21)",
        sp35: "Split (19,22)",
        sp36: "Split (20,23)",
        sp37: "Split (21,24)",
        sp38: "Split (22,23)",
        sp39: "Split (23,24)",
        sp40: "Split (22,25)",
        sp41: "Split (23,26)",
        sp42: "Split (24,27)",
        sp43: "Split (25,26)",
        sp44: "Split (26,27)",
        sp45: "Split (25,28)",
        sp46: "Split (26,29)",
        sp47: "Split (27,30)",
        sp48: "Split (28,29)",
        sp49: "Split (29,30)",
        sp50: "Split (28,31)",
        sp51: "Split (29,32)",
        sp52: "Split (30,33)",
        sp53: "Split (31,32)",
        sp54: "Split (32,33)",
        sp55: "Split (31,34)",
        sp56: "Split (32,35)",
        sp57: "Split (33,36)",
        sp58: "Split (34,35)",
        sp59: "Split (35,36)",
        st1: "Street (1,2,3)",
        st2: "Street (4,5,6)",
        st3: "Street (7,8,9)",
        st4: "Street (10,11,12)",
        st5: "Street (13,14,15)",
        st6: "Street (16,17,18)",
        st7: "Street (19,20,21)",
        st8: "Street (22,23,24)",
        st9: "Street (25,26,27)",
        st10: "Street (28,29,30)",
        st11: "Street (31,32,33)",
        st12: "Street (34,35,36)",
        t1: "Trio (0,1,2)",
        t2: "Trio (0,2,3)",
        c1: "Corner (1,2,4,5)",
        c2: "Corner (2,3,5,6)",
        c3: "Corner (4,5,7,8)",
        c4: "Corner (5,6,8,9)",
        c5: "Corner (7,8,10,11)",
        c6: "Corner (8,9,11,12)",
        c7: "Corner (10,11,13,14)",
        c8: "Corner (11,12,14,15)",
        c9: "Corner (13,14,16,17)",
        c10: "Corner (14,15,17,18)",
        c11: "Corner (16,17,19,20)",
        c12: "Corner (17,18,20,21)",
        c13: "Corner (19,20,22,23)",
        c14: "Corner (20,21,23,24)",
        c15: "Corner (22,23,25,26)",
        c16: "Corner (23,24,26,27)",
        c17: "Corner (25,26,28,29)",
        c18: "Corner (26,27,29,30)",
        c19: "Corner (28,29,31,32)",
        c20: "Corner (29,30,32,33)",
        c21: "Corner (31,32,34,35)",
        c22: "Corner (32,33,35,36)",
        f: "First Four",
        l1: "Line (1,2,3,4,5,6)",
        l2: "Line (4,5,6,7,8,9)",
        l3: "Line (7,8,9,10,11,12)",
        l4: "Line (10,11,12,13,14,15)",
        l5: "Line (13,14,15,16,17,18)",
        l6: "Line (16,17,18,19,20,21)",
        l7: "Line (19,20,21,22,23,24)",
        l8: "Line (22,23,24,25,26,27)",
        l9: "Line (25,26,27,28,29,30)",
        l10: "Line (28,29,30,31,32,33)",
        l11: "Line (31,32,33,34,35,36)",
        cl1: "Column (1,4,7,10,13,16,19,22,25,28,31,34)",
        cl2: "Column (2,5,8,11,14,17,20,23,26,29,32,35)",
        cl3: "Column (3,6,9,12,15,18,21,24,27,30,33,36)",
        d1: "Dozen (1-12)",
        d2: "Dozen (13-24)",
        d3: "Dozen (25-36)",
        rd: "Red",
        bl: "Black",
        od: "Odd",
        ev: "Even",
        bg: "Big",
        sm: "Small"
      }
    case "C12_sicbo":
      return {
        bg: "Big",
        sm: "Small",
        ev: "Even",
        od: "Odd",
        d1: "Double(1)",
        d2: "Double(2)",
        d3: "Double(3)",
        d4: "Double(4)",
        d5: "Double(5)",
        d6: "Double(6)",
        t1: "Specific Triple(1)",
        t2: "Specific Triple(2)",
        t3: "Specific Triple(3)",
        t4: "Specific Triple(4)",
        t5: "Specific Triple(5)",
        t6: "Specific Triple(6)",
        at: "Any Triple",
        s4: "Sum4",
        s5: "Sum5",
        s6: "Sum6",
        s7: "Sum7",
        s8: "Sum8",
        s9: "Sum9",
        s10: "Sum10",
        s11: "Sum11",
        s12: "Sum12",
        s13: "Sum13",
        s14: "Sum14",
        s15: "Sum15",
        s16: "Sum16",
        s17: "Sum17",
        c56: "Two Dice (5,6)",
        c46: "Two Dice (4,6)",
        c45: "Two Dice (4,5)",
        c36: "Two Dice (3,6)",
        c35: "Two Dice (3,5)",
        c34: "Two Dice (3,4)",
        c26: "Two Dice (2,6)",
        c25: "Two Dice (2,5)",
        c24: "Two Dice (2,4)",
        c23: "Two Dice (2,3)",
        c16: "Two Dice (1,6)",
        c15: "Two Dice (1,5)",
        c14: "Two Dice (1,4)",
        c13: "Two Dice (1,3)",
        c12: "Two Dice (1,2)",
        sd1: "Single Dice(1)",
        sd2: "Single Dice(2)",
        sd3: "Single Dice(3)",
        sd4: "Single Dice(4)",
        sd5: "Single Dice(5)",
        sd6: "Single Dice(6)"
      }
    case "P5_3cards":
    case "P15_3cards":
    case "G3":
      return {
        d: "Dragon",
        p: "Phoenix",
        l: "Lucky"
      }
  }
}

export const WG: any = {
  Status: {
    0: "Unpaid",
    1: "Not winning",
    2: "Has won",
    3: "Tie",
    9: "Completed"
  },
  PlayKey: {
    "1FCSSC": "WG 一分彩",
    "FFCSSC": "WG 三分彩",
    "5FCSSC": "WG 五分彩",
    "STPK_p": "WG幸運飛艇",
    "1JSPK_p": "WG 一分賽車",
    "3JSPK_p": "WG 三分賽車",
    "JSPK_p": "WG 五分賽車",
    "1WGVEN": "WG越南一分彩",
    "3WGVEN": "WG越南三分彩",
    "5WGVEN": "WG越南五分彩",
    "TWBG_p": "台湾宾果",
    "TWEB": "台湾28",
    "TWSSC": "台湾快开",
    "HKLHC": "香港六合彩",
    "DLTLHC": "台灣大樂透",
    "WSJLHC": "今彩539",
    "WLCLHC": "台湾威力彩",
    "TWD3SSC": "台湾3星彩",
    "TWD4SSC": "台湾4星彩",
    "CAF5WSJLHC": "加州天天樂",
    "FLAF5WSJLHC": "佛羅里達天天樂",
    "OKLF5WSJLHC": "俄克拉荷馬天天樂",
    "NYF5WSJLHC": "紐約天天樂",
    "MCGF5WSJLHC": "密西根天天樂",
    "PH42F6LHC": "菲律賓樂透42",
    "PH45F6LHC": "菲律賓樂透45",
    "PH49F6LHC": "菲律賓樂透49",
    "PH55F6LHC": "菲律賓樂透55",
    "LAOD4SSC": "老挝彩",
    "TOTOD4SSC": "多多萬字",
    "MND4SSC": "萬能4D",
    "DMCD4SSC": "大馬彩",
    "SGCD4SSC": "新加坡4D",
    "WNSSC": "WG視訊時時彩",
    "WNPK_p": "WG視訊賽車A",
    "WN2PK_p": "WG視訊賽車B",
    "WNLHC": "WG視訊六合彩A",
    "WN2LHC": "WG視訊六合彩B",
    "WNWSJLHC": "WG視訊539 A",
    "WN2WSJLHC": "WG視訊539 B",
    "WN3WSJLHC": "WG視訊539 C",
    "CQLY": "WG象棋樂園",
    "WNKL8": "WG視訊賓果",
    "HNVEN": "河内大乐透",
    "BDVEN": "平定越南大乐透",
    "DANVEN": "岘港越南大乐透",
    "DLVEN": "多乐越南大乐透",
    "DANOVEN": "得农越南大乐透",
    "GLVEN": "嘉莱越南大乐透",
    "KHVEN": "庆和越南大乐透",
    "KTVEN": "昆嵩越南大乐透",
    "NTVEN": "宁顺越南大乐透",
    "PYVEN": "富安越南大乐透",
    "QBVEN": "广平越南大乐透",
    "QNVEN": "广南越南大乐透",
    "QNGVEN": "广义越南大乐透",
    "QTVEN": "广治越南大乐透",
    "HUEVEN": "顺化越南大乐透",
    "AGVEN": "安江越南大乐透",
    "BLVEN": "薄辽越南大乐透",
    "BTVEN": "槟椥越南大乐透",
    "BDUVEN": "平阳越南大乐透",
    "BPVEN": "平福越南大乐透",
    "BTHVEN": "平顺越南大乐透",
    "CMVEN": "金甌越南大乐透",
    "CTVEN": "芹苴越南大乐透",
    "DLLDVEN": "大叻越南大乐透",
    "DONVEN": "同奈越南大乐透",
    "DTVEN": "同塔越南大乐透",
    "HGVEN": "后江越南大乐透",
    "KGVEN": "坚江越南大乐透",
    "LAVEN": "隆安越南大乐透",
    "STVEN": "溯庄越南大乐透",
    "TNVEN": "西宁越南大乐透",
    "TGVEN": "前江越南大乐透",
    "TPHVEN": "胡志明越南大乐透",
    "TVVEN": "茶荣越南大乐透",
    "VLVEN": "永龙越南大乐透",
    "VTVEN": "头顿越南大乐透"
  },
}