import PageChatLog from "pages/admin/chat";
import PageChatAccount from "pages/admin/chat/account";
import PageChatOnlineService from 'pages/admin/chat/online-service';
import PageChatPrivate from "pages/admin/chat/private";
import PageChatReply from "pages/admin/chat/reply";
import PageChatSetting from "pages/admin/chat/setting";

export const chatModule = [
  { path: '/chat', Component: PageChatLog },
  { path: '/chat/private', Component: PageChatPrivate },
  { path: '/chat/setting', Component: PageChatSetting },
  { path: '/chat/account', Component: PageChatAccount },
  { path: '/chat/reply', Component: PageChatReply },
  { path: '/chat/online-service', Component: PageChatOnlineService },
]