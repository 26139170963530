import { Button, Col, DatePicker, Form, Image, Input, message, Modal, Row, Select, Space, Spin, Table, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import hintIcon from 'assets/image/icon-12.png';
import { COOKIE } from 'constants/cookie';
import { RESPONSE_CODE, RESPONSE_CODE_BONUS, RESPONSE_CODE_BONUSTICKET_CREATE, RESPONSE_CODE_REBATE } from "constants/response";
import dayjs from "dayjs";
import { BONUS_CATEGORY, PROMOTION_STATE } from "enum/promotion";
import { BONUS_TICKET_TYPE, DISCOUNT_TYPE, PROMOTION_CYCLE } from "enum/state";
import useAccount from 'hooks/account.hook';
import i18n from "i18n";
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { $get, $post } from "services";
import * as common from 'utils/common';
import { toFormatNumber as $f, convertedToPercentage01 as $g, timeS2L, verify } from "utils/common";
import { InquiryWindow, MemberLevelTableDisplay, ProviderTableDisplay } from "./layout.component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
	DecoupledEditor,
	Alignment,
	BalloonToolbar,
	Bold,
	FontBackgroundColor,
	FontColor,
	Heading,
	HorizontalLine,
	ImageBlock,
	ImageInsertViaUrl,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	RemoveFormat,
	Strikethrough,
	Table as CkTable,
	TableCellProperties,
	TableProperties,
	TableToolbar,
	TodoList,
	Underline,
	Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

// 優惠管理列表
export const ViewPromotionList = ({ data, mutate }: any) => {
  const { permissions: $p } = useAccount();
  const navigate = useNavigate();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [id, setId] = useState(0);

  const handleDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  const submitDelete = () => {
    $post({
      url: 'api/bonus/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_BONUS
    })
  }

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data.filter((item: PromotionData) => {
          if (!$p('30701')) {
            return item.Category !== BONUS_CATEGORY.jackpot;
          }
          if (!$p('30901')) {
            return item.Category !== BONUS_CATEGORY.couponOffer;
          }
          return true;
        }).map((item: PromotionData) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Position',
            title: i18n.t('frontSort'),
            align: 'center',
            fixed: 'left',
            width: 80
          },
          {
            dataIndex: 'Name',
            title: i18n.t('promotionName'),
            fixed: 'left',
            width: 350
          },
          {
            dataIndex: 'Category',
            title: i18n.t('category'),
            width: 120,
            render: (val) => i18n.t(BONUS_CATEGORY[val])
          },
          {
            dataIndex: 'Period',
            title: i18n.t('cycle'),
            width: 120,
            render: (val, { Category }: any) => {
              if (Category === BONUS_CATEGORY.jackpot || Category === BONUS_CATEGORY.signupBonus || Category === BONUS_CATEGORY.allAgent) {
                return '-';
              }
              return (val || val === 0) ? i18n.t(PROMOTION_CYCLE[val]) : '-';
            }
          },
          {
            dataIndex: 'IsOneTime',
            title: i18n.t('oneTimePromotion'),
            width: 120,
            render: (val, { Category }) => {
              if (Category === BONUS_CATEGORY.jackpot || Category === BONUS_CATEGORY.allAgent) {
                return '-';
              }
              return val ? i18n.t('yes') : i18n.t('none')
            }
          },
          {
            dataIndex: 'Providers',
            title: `${i18n.t('applicableGameProvider')}/${i18n.t('mainCategory')}`,
            width: 350,
            render: (val, { CategoryCodes, Category }) => <ProviderTableDisplay Providers={val} gameCategorys={CategoryCodes} promotionCategory={Category} />
          },
          {
            dataIndex: 'MemberLevelName',
            title: i18n.t('applicableMemberLevel'),
            width: 200,
            render: (val, {Category}) => {
              if (Category === BONUS_CATEGORY.signupBonus || Category === BONUS_CATEGORY.allAgent ) {
                return '-';
              }
              return <MemberLevelTableDisplay levelName={val} noneIsAll={true} />}
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 100,
            render: (val) => <div className={val ? 'color-pass' : 'color-reject'}>{i18n.t(PROMOTION_STATE[val])}</div>
          },
          {
            dataIndex: 'StartDate',
            title: `${i18n.t("promotionStartTime")} - ${i18n.t("endTime")}`,
            width: 170,
            render: (val, { Category, StartDate, EndDate }) => (
              <div>
                <div>{timeS2L(StartDate)} -{' '}</div>
                <div>
                  {
                    Category === BONUS_CATEGORY.couponOffer || Category === BONUS_CATEGORY.signupBonus || Category === BONUS_CATEGORY.allAgent
                      ? (EndDate === '0000-00-00 00:00:00'
                        ? i18n.t('permanent')
                        : dayjs(EndDate).isAfter(dayjs())
                          ? timeS2L(EndDate)
                          : <span className="color-down">{timeS2L(EndDate)}</span>)
                      : timeS2L(EndDate)
                  }
                </div>
              </div>
            )
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 170,
            render: (val) => timeS2L(val)
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, record: any) => (
              <>
                {record.Category !== BONUS_CATEGORY.jackpot &&
                  record.Category !== BONUS_CATEGORY.couponOffer &&
                  record.Category !== BONUS_CATEGORY.signupBonus &&
                  record.Category !== BONUS_CATEGORY.allAgent &&
                  $p('30103') &&
                  <Button className="size-12" type="link" onClick={() => {
                    switch (record.Category) {
                      case BONUS_CATEGORY.otherPromotions:
                        return navigate(`/promotion/edit-other/${record.key}`);
                      case BONUS_CATEGORY.referralReward:
                        return navigate('/promotion/edit-recommend');
                      case BONUS_CATEGORY.dailyCheck:
                        return navigate(`/promotion/edit-mission/${record.key}`);
                      case BONUS_CATEGORY.storePromotions:
                        return navigate(`/promotion/edit-store/${record.key}`);
                      case BONUS_CATEGORY.redEnvelopePromotions:
                        return navigate(`/promotion/edit-red-envelope/${record.key}`);
                    }
                  }}>
                    {i18n.t('edit')}
                  </Button>
                }
                {record.Category === BONUS_CATEGORY.jackpot &&
                  <Button className="size-12" type="link" onClick={() => {
                    switch (record.Category) {
                      case BONUS_CATEGORY.jackpot:
                        return navigate(`/promotion/edit-jackpot/${record.key}`);
                    }
                  }}>
                    {$p('30703') ? i18n.t('edit') : i18n.t('detail')}
                  </Button>
                }
                {record.Category === BONUS_CATEGORY.jackpot && $p('30704') &&
                  <Button className="size-12" type="link" onClick={() => handleDelete(record.key)}>
                    {i18n.t('delete')}
                  </Button>
                }
                {record.Category === BONUS_CATEGORY.couponOffer && $p('30903') &&
                  (record.EndDate === '0000-00-00 00:00:00' || dayjs(record.EndDate).isAfter(dayjs())) &&
                  <Button className="size-12" type="link"
                    onClick={() => navigate(`/promotion/edit-coupon/${record.key}`)}>
                    {i18n.t('edit')}
                  </Button>
                }
                {record.Category === BONUS_CATEGORY.couponOffer && $p('30904') &&
                  <Button className="size-12" type="link" onClick={() => handleDelete(record.key)}>
                    {i18n.t('delete')}
                  </Button>
                }
                {
                  (record.Category !== BONUS_CATEGORY.referralReward &&
                    record.Category !== BONUS_CATEGORY.jackpot &&
                    record.Category !== BONUS_CATEGORY.couponOffer &&
                    record.Category !== BONUS_CATEGORY.signupBonus &&
                    record.Category !== BONUS_CATEGORY.allAgent &&
                    $p('30104')) &&
                  <Button className="size-12" type="link" onClick={() => handleDelete(record.key)}>
                    {i18n.t('delete')}
                  </Button>
                }
                {record.Category === BONUS_CATEGORY.signupBonus &&  $p('30103') &&
                  <Button className="size-12" type="link" onClick={() => {
                    switch (record.Category) {
                      case BONUS_CATEGORY.signupBonus:
                        return navigate('/promotion/edit-signup-bonus');
                    }
                  }}>
                    {i18n.t('edit') }
                  </Button>
                }
                {/*TODO:等權限表*/}
                {record.Category === BONUS_CATEGORY.allAgent &&  $p('30103') &&
                  <Button className="size-12" type="link" onClick={() => {
                    switch (record.Category) {
                      case BONUS_CATEGORY.allAgent:
                        return navigate('/people-agent');
                    }
                  }}>
                    {i18n.t('edit') }
                  </Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />
      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={submitDelete} />
    </>
  )
}

// 優惠查詢總計
export const ViewDiscountOverview = ({ data }: {
  data: {
    TotalRecord: number;
    Type1_TotalAmount: number;
    Type2_TotalAmount: number;
    Type3_TotalAmount: number;
  };
}) => {

  return (
    <>
      <Table
        size="middle"
        dataSource={
          data ? [{
            key: 1,
            count: data.TotalRecord,
            totalPromotions: data.Type3_TotalAmount,
            totalAddedPromotions: data.Type1_TotalAmount,
            totalDeductedPromotions: data.Type2_TotalAmount
          }] : []
        }
        columns={[
          {
            title: i18n.t('count'),
            dataIndex: 'count',
            align: 'right',
            width: '25%',
            render: (_, { count }) => count ? count : 0
          },
          {
            title: i18n.t('totalPromotions'),
            dataIndex: 'totalPromotions',
            align: 'right',
            width: '25%',
            render: (_, { totalPromotions }) => common.toFormatNumber(totalPromotions)
          },
          {
            title: i18n.t('totalAddedPromotions'),
            dataIndex: 'totalAddedPromotions',
            align: 'right',
            width: '25%',
            render: (_, { totalAddedPromotions }) => common.toFormatNumber(totalAddedPromotions)
          },
          {
            title: i18n.t('totalDeductedPromotions'),
            dataIndex: 'totalDeductedPromotions',
            align: 'right',
            width: '25%',
            render: (_, { totalDeductedPromotions }) => common.toFormatNumber(totalDeductedPromotions)
          },
        ]}
        pagination={false}
      />
    </>
  )
}
// 優惠活動查詢列表
export const ViewDiscount = ({ data, mutate, setSelectedRowKeys, selectedRowKeys, hideColumns }: {
  data: any;
  mutate: () => void;
  setSelectedRowKeys: any;
  selectedRowKeys: React.Key[];
  hideColumns: {
    amount: boolean;
    applicableGameProvider: boolean;
    applicableMemberLevel: boolean;
    auditTime: boolean;
    createTime: boolean;
    cycle: boolean;
    memberAccount: boolean;
    memberLevel: boolean;
    oneTimePromotion: boolean;
    order: boolean;
    promotionName: boolean;
    promotionType: boolean;
    recommendedMembers: boolean;
    remark: boolean;
    rolloverRate: boolean;
    status: boolean;
    transactionType: boolean;
    operation: boolean;
  }
}) => {
  const { permissions: $p } = useAccount();
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [order, setOrder] = useState<number>(0);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleStatusColor = (type: number) => {
    switch (type) {
      case DISCOUNT_TYPE.dispatched:
      case DISCOUNT_TYPE.received:
        return 'color-pass'
      case DISCOUNT_TYPE.waitApproval:
        return 'color-padding'
      case DISCOUNT_TYPE.rejected:
      case DISCOUNT_TYPE.duplicateReceipt:
        return 'color-reject'
    }
  }

  const handleDetail = (id: number) => {
    setOrder(id);
    setIsModalOpenDetail(true);
  }

  const handleAdjust = (order: number) => {
    setIsModalOpenConfirm(true);
    setOrder(order);
  }

  return (
    <>
      <Table
        size="middle"
        className="mt-1"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: (record: any) => ({
            disabled: record.Status === DISCOUNT_TYPE.dispatched ||
              record.Status === DISCOUNT_TYPE.rejected ||
              record.Status === DISCOUNT_TYPE.received,
          }),
        }}
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item })) || []}
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            className: hideColumns.order ? '' : 'hide',
            fixed: 'left',
            width: 250,
          },
          {
            dataIndex: 'Account',
            title: i18n.t('memberAccount'),
            className: hideColumns.memberAccount ? '' : 'hide',
            width: 160
          },
          {
            dataIndex: 'LevelName',
            title: i18n.t('memberLevel'),
            className: hideColumns.memberLevel ? '' : 'hide',
            width: 100
          },
          {
            dataIndex: 'UpMemberAccount',
            title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
            className: hideColumns.recommendedMembers ? '' : 'hide',
            width: 250,
            render: (val, record: any) => `${val || '-'}／${record.AGAccount}／${record.SAAccount}`
          },
          {
            dataIndex: 'Category',
            title: i18n.t('promotionType'),
            className: hideColumns.promotionType ? '' : 'hide',
            width: 130,
            render: (val) => i18n.t(BONUS_CATEGORY[val])
          },
          {
            dataIndex: 'TxnType',
            title: i18n.t('transactionType'),
            className: hideColumns.transactionType ? '' : 'hide',
            width: 130,
            render: (val) => i18n.t(BONUS_TICKET_TYPE[val])
          },
          {
            dataIndex: 'Name',
            title: i18n.t(i18n.t('promotionName')),
            className: hideColumns.promotionName ? '' : 'hide',
            width: 300,
            render: (val, { Category, Type }) =>
              <>
                {Category === BONUS_CATEGORY.dailyCheck && Type === 2 ? `${val}（${i18n.t('totalSignIn')}）` : val}
              </>
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('amount'),
            className: hideColumns.amount ? '' : 'hide',
            width: 200,
            align: 'right',
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'ValidBetTimes',
            title: i18n.t('rolloverRate'),
            className: hideColumns.rolloverRate ? '' : 'hide',
            align: 'right',
            width: 80,
          },
          {
            dataIndex: 'Period',
            title: i18n.t('cycle'),
            className: hideColumns.cycle ? '' : 'hide',
            align: 'center',
            width: 150,
            render: (val, { Category }) => {
              if (Category === BONUS_CATEGORY.jackpot) {
                return '-';
              }
              return (val || val === 0) ? i18n.t(PROMOTION_CYCLE[val]) : '-';
            }
          },
          {
            dataIndex: 'IsOneTime',
            title: i18n.t('oneTimePromotion'),
            className: hideColumns.oneTimePromotion ? '' : 'hide',
            width: 100,
            render: (val, { Category }) => {
              if (Category === BONUS_CATEGORY.jackpot) {
                return '-';
              }
              return val ? i18n.t('yes') : i18n.t('none')
            }
          },
          {
            dataIndex: 'Providers',
            title: `${i18n.t('applicableGameProvider')}/${i18n.t('mainCategory')}`,
            className: hideColumns.applicableGameProvider ? '' : 'hide',
            width: 200,
            render: (val, { CategoryCodes, Category }) => <ProviderTableDisplay Providers={val} gameCategorys={CategoryCodes} promotionCategory={Category} />
          },
          {
            dataIndex: 'MemberLevelName',
            title: i18n.t('applicableMemberLevel'),
            className: hideColumns.applicableMemberLevel ? '' : 'hide',
            width: 200,
            render: (val) => <MemberLevelTableDisplay levelName={val} noneIsAll={true} />
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            className: hideColumns.createTime ? '' : 'hide',
            width: 160,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'AuditedTime',
            title: i18n.t('auditTime'),
            className: hideColumns.auditTime ? '' : 'hide',
            width: 160,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'Remark',
            title: i18n.t('remark'),
            className: hideColumns.remark ? '' : 'hide',
            width: 200,
            render: (val) => (
              <Tooltip title={val}>
                <div style={{ whiteSpace: 'nowrap', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val || '-'}</div>
              </Tooltip>
            )
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            className: hideColumns.status ? '' : 'hide',
            fixed: 'right',
            width: 150,
            render: (val, { OperatorId, OperatorAccount }) => (
              <>
                <div className={handleStatusColor(val)}>{i18n.t(DISCOUNT_TYPE[val])}</div>
                <div>
                  <span>{i18n.t('from')} {OperatorId === 0 ? 'System' : OperatorAccount} </span>
                  {val === DISCOUNT_TYPE.waitApproval && i18n.t('add')}
                  {val === DISCOUNT_TYPE.rejected && i18n.t('reject')}
                  {val === DISCOUNT_TYPE.duplicateReceipt && i18n.t('reject')}
                  {val === DISCOUNT_TYPE.received && i18n.t('distribution')}
                  {val === DISCOUNT_TYPE.dispatched && i18n.t('distribution')}
                </div>
              </>
            )
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            className: hideColumns.operation ? '' : 'hide',
            width: 100,
            render: (_, record: any) => (
              <>
                {
                  (
                    record.Status === DISCOUNT_TYPE.waitApproval && $p('30303')
                  ) &&
                  <Button className="size-12" type="link"
                    onClick={() => handleAdjust(record.key)}>{i18n.t('audit')}</Button>
                }
                {
                  (
                    record.Status === DISCOUNT_TYPE.dispatched ||
                    record.Status === DISCOUNT_TYPE.received ||
                    record.Status === DISCOUNT_TYPE.rejected ||
                    record.Status === DISCOUNT_TYPE.duplicateReceipt
                  ) &&
                  <Button className="size-12" type="link"
                    onClick={() => handleDetail(record.key)}>{i18n.t('detail')}</Button>
                }
                {/* <Button className="size-12" type="link" onClick={showModalUnlock}>{i18n.t('unlock')}</Button> */}
              </>
            ),
          },
        ]}
        pagination={false}
      />
      <PopupAdjustDiscount isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} mutate={mutate} id={order} />
      <PopupDiscountDetail isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} mutate={mutate} id={order} />
    </>
  )
}
// 新增審核詳細
export const PopupAddDiscount = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const { data: BonusOptions, isValidating: BonusLoading } = $get({
    url: 'api/bonus/name/list',
    params: { Status: 1 }
  })
  const [loading, setLoading] = useState(false);
  const onFinish = (formData: any) => {
    setLoading(true);
    const members = formData.memberAccount.split(',');
    $post({
      url: 'api/bonusticket/create',
      send: {
        BonusId: Number(formData.promotionName),
        MemberAccount: [...members],
        TxnType: formData.transactionType,
        Amount: Number(formData.amount),
        ValidBetTimes: Number(formData.rolloverRate),
        Remark: formData.backOfficeRemarks
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        mutate();
        setLoading(false);
        onClose();
      },
      fail: (response: any) => {
        setLoading(false);
        const errorData = response.Data;
        const errorMessage = response.Message;
        message.error(
          <>
            {
              errorData
                ? <Row>
                  <Col span={24}>{errorMessage === "NOT_FIND_MEMBER" ? i18n.t('notFoundAccount') : i18n.t('memberBalanceLimitReached')}</Col>
                  {
                    errorData &&
                    errorData.map((error: string, i: number) => {
                      const account = error.split(" ")[0];
                      return (
                        <Col span={24} key={i}>{account}</Col>
                      )
                    })
                  }
                </Row>
                : RESPONSE_CODE_BONUS[response.Message as keyof typeof RESPONSE_CODE_BONUS]
            }
          </>
        );
      },
      showMessage: false
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={500}
      forceRender
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[16, 16]} className="pb-1">
            {/* 優惠名稱 */}
            <Col span={24}>
              <Form.Item className="w-full" name="promotionName" label={i18n.t('promotionName')} rules={[{ required: true }]}>
                <Select
                  loading={BonusLoading}
                  showSearch
                  filterOption={common.selectFuzzyFilter}
                  placeholder={i18n.t('pleaseSelect')}
                  options={
                    BonusOptions?.Data
                      // 獎池優惠,紅包優惠,兌換碼優惠,全民代理優惠無法透過優惠活動查詢頁面新增
                      .filter((option: any) => option.Category !== BONUS_CATEGORY.jackpot &&
                        option.Category !== BONUS_CATEGORY.redEnvelopePromotions && option.Category !== BONUS_CATEGORY.couponOffer && option.Category !== BONUS_CATEGORY.allAgent)
                      .map((option: PromotionType) => (
                        { value: `${option.Id}`, label: option.Name }
                      )) || [{ value: '', label: i18n.t('currentlyNoOngoingPromotions') }]
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="memberAccount" label={i18n.t('memberAccount')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('pleaseUseCommaToSeparateTheItems')} ${i18n.t('upTo20Entries')}`} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item className="w-full" name="transactionType" label={i18n.t('transactionType')}
                rules={[{ required: true }]}>
                <Select
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    { value: 1, label: `${i18n.t('dddPromotion')}` },
                    { value: 2, label: `${i18n.t('deductPromotion')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="amount" label={i18n.t('amount')} rules={common.verify({ point: 4, max: 999999999.9999 })}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={common.verify({ point: 0 })}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="backOfficeRemarks" label={i18n.t('remark')}
                rules={[{ type: 'string', max: 100, message: `${i18n.t('remarksUpTo100Characters')}` }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" justify="center" gutter={[12, 12]}>
            <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
            <Col><Button type="primary" htmlType="submit" disabled={loading}>{i18n.t('confirm')}</Button></Col>
          </Row>
        </Form>
      </Spin >
    </Modal>
  )
}

// 批次派發 拒絕
export const PopupBatchDiscount = ({ isOpen, close, send, type, select }: {
  isOpen: boolean;
  close: () => void;
  send: (type: number) => void;
  type: number;
  select: any;
}) => {

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" type="primary"
              onClick={() => send(type)}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmationBatch')}{type === 3 ? i18n.t('distribution') : i18n.t('reject')} {select.length} {i18n.t('counts')} {i18n.t('isData')}</div>
      </Space>
    </Modal>
  )
}

export const PopupAdjustDiscount = ({ isOpen, close, mutate, id }: any) => {
  const [form] = useForm();
  const { data, mutate: refresh } = $get({
    url: 'api/bonusticket/one',
    params: { BonusTicketId: id },
    allow: !!id
  })

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        promotionName: data.Data.Name,
        // payee:data.Data
        memberAccount: data.Data.MemberAccount,
        transactionType: data.Data.TxnType,
        amount: data.Data.Amount,
        rolloverRate: data.Data.ValidBetTimes,
        backOfficeRemarks: data.Data.Remark
      })
    }
  }, [data]);

  const handSend = (type: number) => {
    const remark = form.getFieldValue('backOfficeRemarks');
    $post({
      url: 'api/bonusticket/audit',
      send: {
        BonusTicketId: [data?.Data.Id],
        Status: type,
        Remark: remark
      },
      success: () => {
        message.success(i18n.t(type === 3 ? 'approvaled' : 'reject'));
        refresh();
        mutate();
        onClose();
      },
      resCode: RESPONSE_CODE_BONUSTICKET_CREATE
    })
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={i18n.t('audit')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      footer={false}
      width={500}
      forceRender
    >
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item className="w-full" name="promotionName" label={i18n.t('promotionName')} required>
              <Select disabled options={[]} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="memberAccount" label={i18n.t('memberAccount')} required>
              <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={8}>
            <Form.Item className="w-full" name="transactionType" label={i18n.t('transactionType')} required>
              <Select
                disabled
                options={[
                  { value: '', label: `${i18n.t('ALL')}` },
                  ...common.enumToOptions(BONUS_TICKET_TYPE)
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount" label={i18n.t('amount')} required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('remark')}
              rules={[{ type: 'string', max: 100, message: `${i18n.t('remarksUpTo100Characters')}` }]}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
        </Row >
        <Row className="mt-1" justify="center" gutter={[12, 12]}>
          <Col>
            <Button type="primary" htmlType="submit" danger onClick={() => handSend(2)}>
              {i18n.t('reject')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" onClick={() => handSend(3)}>{i18n.t('approvaled')}</Button>
          </Col>
        </Row>
      </Form >
    </Modal >
  )
}
export const PopupDiscountDetail = ({ isOpen, close, id }: any) => {
  const [form] = useForm();
  const { data } = $get({
    url: 'api/bonusticket/one',
    params: { BonusTicketId: id },
    allow: !!id
  })

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        promotionName:
          data.Data.Category === BONUS_CATEGORY.dailyCheck && data.Data.Type === 2
            ? `${data.Data.Name}（${i18n.t('totalSignIn')}）` : data.Data.Name,
        // payee:data.Data
        memberAccount: data.Data.MemberAccount,
        transactionType: data.Data.TxnType,
        amount: data.Data.Amount,
        rolloverRate: data.Data.ValidBetTimes,
        backOfficeRemarks: data.Data.Remark
      })
    }
  }, [data]);

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      footer={false}
      width={500}
      forceRender
    >
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item className="w-full" name="promotionName" label={i18n.t('promotionName')} required>
              <Select disabled options={[]} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="memberAccount" label={i18n.t('memberAccount')} required>
              <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={8}>
            <Form.Item className="w-full" name="transactionType"
              label={i18n.t('transactionType')} required>
              <Select
                disabled
                options={[
                  { value: '', label: `${i18n.t('ALL')}` },
                  ...common.enumToOptions(BONUS_TICKET_TYPE)
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="amount" label={i18n.t('amount')} required>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pb-1">
          <Col span={24}>
            <Form.Item name="backOfficeRemarks" label={i18n.t('remark')} required
              rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-1" justify="center">
          <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      </Form>
    </Modal>
  )
}

// 等級優惠 新增審核詳細
export const PopupAddPromotionVip = ({ isOpen, close }: any) => {
  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={close}
      centered={true}
      footer={[
        <Row align="middle" justify="center">
          <Col>
            <Button onClick={close}>{i18n.t('cancel')}</Button>
            <Button type="primary">{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      ]}
      width={600}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <Row gutter={[12, 12]} justify="space-between">
            <Col span={24}>
              <span className="require">*</span>{i18n.t('payee')}
              <Select
                className="w-full"
                placeholder={i18n.t('pleaseSelect')}
                options={[
                  { value: '1', label: `${i18n.t('specificMember')}` },
                  { value: '2', label: `${i18n.t('allMembers')}` },
                  { value: '3', label: `${i18n.t('memberLevel')}` },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            placeholder={i18n.t('pleaseSelect')}
            options={[
              { value: '1', label: `${i18n.t('dddPromotion')}` },
              { value: '2', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('inputData')}`} />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('rolloverRate')}
          <Input placeholder={`${i18n.t('inputData')}`} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('inputData')}`} />
        </Col>
      </Row>
    </Modal>
  )
}
export const PopupAuditPromotionVip = ({ isOpen, close }: any) => {
  return (

    <Modal
      title={i18n.t('audit')}
      open={isOpen}
      onCancel={close}
      centered={true}
      footer={[
        <>
          <Button onClick={close}>{i18n.t('cancel')}</Button>
          <Button type="primary">{i18n.t('confirm')}</Button>
        </>
      ]}
      width={600}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('payee')}
          <Select
            className="w-full"
            placeholder="1"
            disabled
            options={[
              { value: '1', label: `${i18n.t('specificMember')}` },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('memberAccount')}
          <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            placeholder="1"
            disabled
            options={[
              { value: '1', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('1,000.0000')}`} disabled />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('requiresContinuousSignIn')}
          <Input placeholder={`${i18n.t('inputData')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('inputData')}`} />
        </Col>
      </Row>
    </Modal>
  )
}
export const PopupDetailPromotionVip = ({ isOpen, close }: any) => {
  return (

    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered={true}
      footer={[
        <>
          <Button type="primary" onClick={close}>{i18n.t('close')}</Button>
        </>
      ]}
      width={600}
    >
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('payee')}
          <Select
            className="w-full"
            options={[
              { value: '1', label: `${i18n.t('specificMember')}` },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          <span className="require">*</span>{i18n.t('memberAccount')}
          <Input placeholder={`${i18n.t('memberAccount')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={8}>
          <span className="require">*</span>{i18n.t('transactionType')}
          <Select
            className="w-full"
            disabled
            options={[
              { value: '1', label: `${i18n.t('deductPromotion')}` },
            ]}
          />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('amount')}
          <Input placeholder={`${i18n.t('1,000.0000')}`} disabled />
        </Col>
        <Col span={8}>
          <span className="require">*</span>{i18n.t('requiresContinuousSignIn')}
          <Input placeholder={`${i18n.t('inputData')}`} disabled />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="pb-1">
        <Col span={24}>
          {i18n.t('backOfficeRemarks')}
          <Input placeholder={`${i18n.t('inputData')}`} disabled />
        </Col>
      </Row>
    </Modal>
  )
}

// 返水優惠 全部派發
export const PopupAllpassPromotionRebate = ({ isOpen, close, mutate, setAllPass, selectedRowKeys, setSelectedRowKeys }: {
  isOpen: boolean;
  close: () => void;
  mutate: () => void;
  setAllPass: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: any;
  setSelectedRowKeys: any;
}) => {
  const [form] = useForm();
  const [date, setDate] = useState<any>(dayjs().format("YYYY-MM-DD"));
  
  const handleAllPass = (formData: any) => {
    $post({
      url: 'api/commissionticket/member/pass',
      send: {
        // Role: 0,
        StartDate: `${date} 00:00:00`,
        EndDate: `${date} 23:59:59`,
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
        setSelectedRowKeys([]);
      },
      resCode: RESPONSE_CODE_REBATE
    }, setAllPass)
  }

  const onClose = () => {
    form.resetFields();
    close();
  };

  return (
    <Modal
      title={i18n.t('distributeAll')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={400}
      forceRender
    >
      <Form form={form} onFinish={handleAllPass} initialValues={{
        date: dayjs()
      }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <div>
              <span className="require">*</span>
              <span className="color-03">
                {`${i18n.t('distributionTimeOnlyAllowsSelecting')}${i18n.t('oneDay')}`}
              </span>
            </div>
            <div>
              <span className="require">*</span>
              <span className="color-03">
                {i18n.t('rebatesWillNotBeDistributed')}
              </span>
            </div>
          </Col>
          <Col span={24}>
            <Form.Item name="date" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}` }]}>
              <DatePicker className="w-full" onChange={(date, dateString) => setDate(dateString)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('distributeAll')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 有的
export const CKEditorComp = ({ data, setData, form, fieldName, image, disabled }: any) => {

  const editorToolbarRef = useRef<any>(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    if (form) form.setFieldValue(fieldName, data);
  }, []);

  useEffect(() => {
    setIsLayoutReady(true);
    return () => setIsLayoutReady(false);
  }, []);

  class MyUploadAdapter {
    public loader: any;
    public token: any;

    constructor(loader: any) {
      this.loader = loader;
      this.token = Cookies.get(COOKIE.TOKEN);
    }

    upload() {
      return new Promise(async (resolve, reject) => {
        this.loader.file.then((file: any) => {
          this._sendRequest(resolve, reject, file);
        });
      });
    }

    _sendRequest(resolve: any, reject: any, file: any) {

      const newFormData = new FormData();
      newFormData.append('Media', file as any);

      const upload = fetch('api/upload/images/public/bonus', {
        headers: {
          "Authorization": this.token
        },
        method: 'POST',
        body: newFormData,
      })
        .then(res => {
          const response: any = res.json();
          if (res.ok) return response;
          else throw response;
        })
        .then(data => {
          // 成功
          if (data.State === 'Success') {
            resolve({
              default: data.Data[0],
            });
          } else {
            const msg = RESPONSE_CODE[data.Message as keyof typeof RESPONSE_CODE];
            message.error(msg || i18n.t('processingFailed'));
            reject();
          };
        })

    }
  }

  function CustomImageUploadAdapter(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => new MyUploadAdapter(loader);
  };

  const editorConfig: any = {
    extraPlugins: [CustomImageUploadAdapter],
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'removeFormat',
        '|',
        'link',
        image ? 'imageUpload' : '',
        'insertTable',
        // '|',
        // 'horizontalLine',
        // 'alignment',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent'
      ],
      shouldNotGroupWhenFull: false
    },
    plugins: [
      Undo,
      Heading,
      FontColor,
      FontBackgroundColor,
      Bold,
      Italic,
      Underline,
      Strikethrough,
      RemoveFormat,
      Link,
      ImageUpload,
      HorizontalLine,
      Alignment,

      List,
      TodoList,
      ImageBlock,
      ImageInsertViaUrl,
      CkTable,
      TableToolbar,
      TableProperties,
      TableCellProperties,

      Indent,
      IndentBlock,

      BalloonToolbar,

      // Paragraph,
      // SimpleUploadAdapter,
      // AccessibilityHelp,
      // AutoImage,
      // AutoLink,
      // Autosave,
      // Code,
      // Essentials,
      // FontFamily,
      // FontSize,
      // ImageInsert,
      // ImageToolbar,
      // SelectAll,
      // SpecialCharacters,
      // TableCaption,
      // TableColumnResize,
    ],
    balloonToolbar: [
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'removeFormat'
    ],
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph'
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1'
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2'
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_heading3'
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_heading4'
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Heading 5',
          class: 'ck-heading_heading5'
        },
        {
          model: 'heading6',
          view: 'h6',
          title: 'Heading 6',
          class: 'ck-heading_heading6'
        }
      ]
    },
    image: {
      toolbar: ['insertImage']
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file'
          }
        }
      }
    },
    menuBar: {
      isVisible: true
    },
    table: {
      contentToolbar: ['mergeTableCells', 'tableProperties', 'tableCellProperties']
    },
    // initialData: '<h2>Congratulations on setting up CKEditor 5!</h2>\n',
    // placeholder: 'Type or paste your content here!'
  };

  const oldConfig: any = {
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'link',
        image ? 'imageUpload' : '',
        'insertTable',
        'blockQuote',
        '|',
        'bulletedList',
        'numberedList',
      ]
    },
    image: {
      toolbar: [
        'insertImage',
        // 'imageStyle:full',
        // 'imageStyle:side',
        // '|',
        // 'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    language: 'en'
  }

  return (
    <div className="editor-container editor-container_document-editor">
      <div className="editor-container__toolbar" ref={editorToolbarRef}></div>
      {isLayoutReady && 
      <CKEditor
        disabled={disabled}
        data={data}
        onReady={editor => { editorToolbarRef.current?.appendChild(editor.ui.view.toolbar.element) }}
        onAfterDestroy={() => { Array.from(editorToolbarRef.current?.children || []).forEach((child: any) => child.remove()) }}
        onChange={(event, editor) => {
          const data = editor.getData();
        }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          setData(data);
          if (form) form.setFieldValue(fieldName, data);
        }}
        config={editorConfig}
        editor={DecoupledEditor}
      />}
    </div>
    // 舊版
    // <CKEditor
    //   disabled={disabled}
    //   data={data}
    //   onChange={(event, editor) => {
    //     const data = editor.getData();
    //   }}
    //   onBlur={(event, editor) => {
    //     const data = editor.getData();
    //     setData(data);
    //     if (form) form.setFieldValue(fieldName, data);
    //   }}
    //   config={{
    //     extraPlugins: [CustomImageUploadAdapter],
    //     toolbar: CKdefaultConfig.toolbar,
    //   }}
    //   editor={ClassicEditor}
    // />
  )
}

// 獎池優惠設定列表
export const JackpotSettingList = ({ data, mutate }: any) => {
  const { permissions: $p } = useAccount();
  const navigate = useNavigate();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [id, setId] = useState(0);

  const handleDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  const submitDelete = () => {
    $post({
      url: 'api/bonus/jackpot/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_BONUS
    })
  }

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data.map((item: any) => ({
          key: item.Id,
          ...item,
          ...(JSON.parse(item.BonusRuleSetting))
        }))}
        columns={[
          {
            dataIndex: 'Position',
            title: i18n.t('frontSort'),
            align: 'center',
            width: 80
          },
          {
            dataIndex: 'Id',
            title: i18n.t('jackpotId'),
            width: 120
          },
          {
            dataIndex: 'Name',
            title: i18n.t('jackpotName'),
            width: 200
          },
          // 設定遊戲名稱
          {
            dataIndex: 'SettingGames',
            title: i18n.t('settingGameName'),
            width: 200,
            render: (val: any) => common.gameNameArray(val)
          },
          // 獎池底金
          {
            dataIndex: 'PrizePoolInitAmount',
            title: i18n.t('jackpotAmount'),
            width: 140,
            render: (val) => $f(val)
          },
          // 投注貢獻比例
          {
            dataIndex: 'BetContributeRatio',
            title: i18n.t('bettingRatio'),
            width: 100,
            render: (val) => `${$g(val)}%`
          },
          {
            dataIndex: 'Rule',
            title: `${i18n.t('stageOne')}${i18n.t('oddsOfPrize')}`,
            width: 100,
            render: (val) => `${$g(val[0].LotteryRatio)}%`
          },
          {
            dataIndex: 'Rule',
            title: `${i18n.t('stageTwo')}${i18n.t('oddsOfPrize')}`,
            width: 100,
            render: (val) => `${$g(val[1].LotteryRatio)}%`
          },
          {
            dataIndex: 'Rule',
            title: `${i18n.t('stageThree')}${i18n.t('oddsOfPrize')}`,
            width: 100,
            render: (val) => `${$g(val[2].LotteryRatio)}%`
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 100,
            render: (val) => <div className={val ? 'color-pass' : 'color-reject'}>{i18n.t(PROMOTION_STATE[val])}</div>
          },
          {
            dataIndex: 'StartDate',
            title: `${i18n.t("promotionStartTime")} - ${i18n.t("endTime")}`,
            width: 150,
            render: (val, { StartDate, EndDate }: any) => `${timeS2L(StartDate)} - ${timeS2L(EndDate)}`
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, record: any) => (
              <>
                <Button className="size-12" type="link" onClick={() => navigate(`/promotion/edit-jackpot/${record.Id}`)}>
                  {/* dayjs().isBefore(dayjs(timeS2L(record.EndDate)) */}
                  {$p('30703') ? i18n.t('edit') : i18n.t('detail')}
                </Button>
                {$p('30704') &&
                  <Button className="size-12" type="link" onClick={() => handleDelete(record.Id)}>
                    {i18n.t('delete')}
                  </Button>}
              </>
            ),
          },
        ]}
        pagination={false}
      />
      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={submitDelete} />
    </>
  )
}
