import { Image } from "antd";
import ipBlockGgIcon from 'assets/image/bg-nb.png';
import ipBlockGgImage from 'assets/image/woman-ip.png';
import i18n from "i18n";
import React from "react";

const PageMain: React.FC = () => {

  return (
    <div
      style={{
        backgroundImage: `url(${ipBlockGgIcon})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <Image style={{ width: '16.375rem', height: '20rem' }} src={ipBlockGgImage} preview={false} />
          <div className="ip-block-content">
            IP{i18n.t('restriction')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageMain;
