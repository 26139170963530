import { Button, Col, Empty, Form, Row, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import 'chart.js/auto';
import { DatePickerCol, Export, LayoutNav } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { DATE_RANGE_LIMIT } from 'enum/date';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { $get } from 'services';
import * as common from 'utils/common';

// 遊戲統計分析

interface SearchParams {
  StartDate: string;
  EndDate: string;
}

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<SearchParams>({
    StartDate: common.timeL2S(date[0]),
    EndDate: common.timeL2S(date[1])
  });
  const { data: Report, isValidating, mutate } = $get({
    url: 'api/report/provider/list',
    params
  });

  // 圖表設定
  const config = {
    staticPlot: false,
    responsive: true,
    displaylogo: false,
    showEditInChartStudio: false,
  };

  // 取得圖表資訊
  const [reportData, setReportData] = useState<{
    dataSource?: {
      label: string;
      betMemberCount: number;
      betCount: number;
      validBetAmount: number;
      betAmount: number;
    }[]
  }>({});
  useEffect(() => {
    if (Report && Report.Data) {
      const data = Report.Data.map((item: ReportGame, i: number) => (
        {
          label: common.specialProviderName(item.ProviderName, site.SiteName, item.ProviderCode),
          betMemberCount: item.TotalBetMemberCount,
          betCount: item.TotalBetCount,
          validBetAmount: item.TotalValidBetAmount,
          betAmount: item.TotalBetAmount,
        }
      ))
      setReportData({
        dataSource: data
      });
    }
  }, [Report])

  // 投注人數圖表
  const [betPieData, setBetPieData] = useState<{ data: any[], layout: {} }>({
    data: [],
    layout: {}
  });
  useEffect(() => {
    const decimalNum = reportData.dataSource?.map(item => new Decimal(item.betMemberCount));
    const totalAmount = decimalNum?.reduce((acc, num) => acc.plus(num), new Decimal(0));
    const totalAmountPercent = reportData.dataSource?.map((item) => {
      if (totalAmount) {
        return {
          label: item.label,
          amount: `${new Decimal(item.betMemberCount).dividedBy(totalAmount).times(100).toDecimalPlaces(2)}`
        }
      }
    });
    setBetPieData({
      data: [
        {
          type: 'pie',
          values: reportData.dataSource?.map(item => item.betMemberCount),
          labels: totalAmountPercent?.map(item => `${item?.label}—${item?.amount}%`),
          text: reportData.dataSource?.map(item => `${i18n.t('bettingMembers')}：${item.betMemberCount}`),
          hoverinfo: "label+text",
          textinfo: "none",
          textposition: "outside",
          automargin: true,
          sort: false,
        }
      ],
      layout: {
        height: 600,
        width: 600,
      }
    });
  }, [reportData]);

  // 投注筆數圖表
  const [totalBetPieData, setTotalBetPieData] = useState<{ data: any[], layout: {} }>({
    data: [],
    layout: {}
  });
  useEffect(() => {
    const decimalNum = reportData.dataSource?.map(item => new Decimal(item.betCount));
    const totalAmount = decimalNum?.reduce((acc, num) => acc.plus(num), new Decimal(0));
    const totalAmountPercent = reportData.dataSource?.map((item) => {
      if (totalAmount) {
        return {
          label: item.label,
          amount: `${new Decimal(item.betCount).dividedBy(totalAmount).times(100).toDecimalPlaces(2)}`
        }
      }
    });
    setTotalBetPieData({
      data: [
        {
          type: 'pie',
          values: reportData.dataSource?.map(item => item.betCount),
          labels: totalAmountPercent?.map(item => `${item?.label}—${item?.amount}%`),
          text: reportData.dataSource?.map(item => `${i18n.t('totalBets')}：${item.betCount}`),
          hoverinfo: "label+text",
          textinfo: "none",
          textposition: "outside",
          automargin: true,
          sort: false
        }
      ],
      layout: {
        height: 600,
        width: 600,
      }
    });
  }, [reportData]);

  // 有效投注圖表
  const [validBetPieData, setValidBetPieData] = useState<{ data: any[], layout: {} }>({
    data: [],
    layout: {}
  });
  useEffect(() => {
    const decimalNum = reportData.dataSource?.map(item => new Decimal(item.validBetAmount));
    const totalAmount = decimalNum?.reduce((acc, num) => acc.plus(num), new Decimal(0));
    const totalAmountPercent = reportData.dataSource?.map((item) => {
      if (totalAmount) {
        return {
          label: item.label,
          amount: `${new Decimal(item.validBetAmount).dividedBy(totalAmount).times(100).toDecimalPlaces(2)}`
        }
      }
    });
    setValidBetPieData({
      data: [
        {
          type: 'pie',
          values: reportData.dataSource?.map(item => item.validBetAmount),
          labels: totalAmountPercent?.map(item => `${item?.label}—${item?.amount}%`),
          text: reportData.dataSource?.map(item => `${i18n.t('betAmountValid')}：${common.toFormatNumber(item.validBetAmount)}`),
          hoverinfo: "label+text",
          textinfo: "none",
          automargin: true,
          sort: false
        }
      ],
      layout: {
        height: 600,
        width: 600,
      }
    });
  }, [reportData]);

  // 總投注圖表
  const [totalPieAmountBarData, setTotalBetAmountPieData] = useState<{ data: any[], layout: {} }>({
    data: [],
    layout: {}
  });
  useEffect(() => {
    const decimalNum = reportData.dataSource?.map(item => new Decimal(item.betAmount));
    const totalAmount = decimalNum?.reduce((acc, num) => acc.plus(num), new Decimal(0));
    const totalAmountPercent = reportData.dataSource?.map((item) => {
      if (totalAmount) {
        return {
          label: item.label,
          amount: `${new Decimal(item.betAmount).dividedBy(totalAmount).times(100).toDecimalPlaces(2)}`
        }
      }
    });
    setTotalBetAmountPieData({
      data: [
        {
          type: 'pie',
          values: reportData.dataSource?.map(item => item.betAmount),
          labels: totalAmountPercent?.map(item => `${item?.label}—${item?.amount}%`),
          text: reportData.dataSource?.map(item => `${i18n.t('totalBetMoney')}：${common.toFormatNumber(item.betAmount)}`),
          hoverinfo: "label+text",
          textinfo: "none",
          textposition: "outside",
          automargin: true,
          sort: false,
        }
      ],
      layout: {
        height: 600,
        width: 600,
      }
    });
  }, [reportData]);

  const onFinish = (formData: any) => {
    if (date) {
      setParams({
        StartDate: common.timeL2S(date[0]),
        EndDate: common.timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            {/* 搜尋列 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <DatePickerCol date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 圖表數據 */}
            <Col span={24} >
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Spin spinning={isValidating}>
                    <div className="size-20">{i18n.t('bettingMembers')}</div>
                    <Row align="middle" justify="center">
                      {Array.isArray(betPieData.data[0]?.text) && betPieData.data[0]?.text.length === 0 ? (
                        <Col span={24}>
                          <Empty description={i18n.t('notFoundData')} />
                        </Col>
                      ) : (
                        <Col span={24}>
                          <Plot layout={betPieData.layout} data={betPieData.data} config={config as any} />
                        </Col>
                      )}
                    </Row>
                  </Spin>
                </Col>
                <Col span={12}>
                  <Spin spinning={isValidating}>
                    <div className="size-20">{i18n.t('totalBets')}</div>
                    <Row align="middle" justify="center">
                      {Array.isArray(totalBetPieData.data[0]?.text) && totalBetPieData.data[0]?.text.length === 0 ? (
                        <Col span={24}>
                          <Empty description={i18n.t('notFoundData')} />
                        </Col>
                      ) : (
                        <Col span={24}>
                          <Plot layout={totalBetPieData.layout} data={totalBetPieData.data} config={config as any} />
                        </Col>
                      )}
                    </Row>
                  </Spin>
                </Col>
                <Col span={12}>
                  <Spin spinning={isValidating}>
                    <div className="size-20">{i18n.t('betAmountValid')}</div>
                    <Row align="middle" justify="center">
                      {Array.isArray(validBetPieData.data[0]?.text) && validBetPieData.data[0]?.text.length === 0 ? (
                        <Col span={24}>
                          <Empty description={i18n.t('notFoundData')} />
                        </Col>
                      ) : (
                        <Col span={24}>
                          <Plot layout={validBetPieData.layout} data={validBetPieData.data} config={config as any} />
                        </Col>
                      )}
                    </Row>
                  </Spin>
                </Col>
                <Col span={12}>
                  <Spin spinning={isValidating}>
                    <div className="size-20">{i18n.t('totalBetMoney')}</div>
                    <Row align="middle" justify="center">
                      {/* <Pie data={} /> */}
                      {Array.isArray(totalPieAmountBarData.data[0]?.text) && totalPieAmountBarData.data[0]?.text.length === 0 ? (
                        <Col span={24}>
                          <Empty description={i18n.t('notFoundData')} />
                        </Col>
                      ) : (
                        <Col span={24}>
                          <Plot layout={totalPieAmountBarData.layout} data={totalPieAmountBarData.data}
                            config={config as any} />
                        </Col>
                      )}
                    </Row>
                  </Spin>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {$p('60402') && <Export url={'/report/provider/list/export'} param={{ ...params }}
                sheetName={i18n.t('gameStatisticalAnalysis')} columns={ExcelColumns.ReportGame} />}
            </Col>
            {/* 列表 */}
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  bordered
                  dataSource={
                    Report?.Data?.map((item: ReportGame, i: number) => ({ key: i, ...item })) || []
                  }
                  columns={[
                    {
                      title: i18n.t('gameProvider'),
                      align: 'center',
                      children: [
                        {
                          title: () => (<>{i18n.t('total')}</>),
                          align: 'center',
                          width: 250,
                          dataIndex: 'ProviderName',
                          render: (val, { ProviderCode }) => common.specialProviderName(val, site.SiteName, ProviderCode)
                        }
                      ],
                    },
                    {
                      title: i18n.t('bettingMembers'),
                      align: 'right',
                      sorter: (a: any, b: any) => a.TotalBetMemberCount - b.TotalBetMemberCount,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => Report ? Report.TotalBetMemberCount : 0,
                          align: 'right',
                          dataIndex: 'TotalBetMemberCount',
                        }
                      ],
                    },
                    {
                      title: i18n.t('totalBets'),
                      align: 'right',
                      sorter: (a: any, b: any) => a.TotalBetCount - b.TotalBetCount,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => Report ? Report.TotalBetCount : 0,
                          align: 'right',
                          dataIndex: 'TotalBetCount',

                        }
                      ],
                    },
                    {
                      title: i18n.t('betAmountValid'),
                      align: 'right',
                      sorter: (a: any, b: any) => a.TotalValidBetAmount - b.TotalValidBetAmount,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => common.toFormatNumber(Report ? Report.TotalValidBetAmount : 0),
                          align: 'right',
                          dataIndex: 'TotalValidBetAmount',
                          render: (val) => (<>{common.toFormatNumber(val)}</>)
                        }
                      ],
                    },
                    {
                      title: i18n.t('totalBetMoney'),
                      align: 'right',
                      sorter: (a: any, b: any) => a.TotalBetAmount - b.TotalBetAmount,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => common.toFormatNumber(Report ? Report.TotalBetAmount : 0),
                          align: 'right',
                          dataIndex: 'TotalBetAmount',
                          render: (val) => (<>{common.toFormatNumber(val)}</>)
                        }
                      ],
                    },
                    {
                      title: i18n.t('payoutTotal'),
                      align: 'right',
                      sorter: (a: any, b: any) => a.TotalSettleAmount - b.TotalSettleAmount,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => common.toFormatNumber(Report ? Report.TotalSettleAmount : 0),
                          align: 'right',
                          dataIndex: 'TotalSettleAmount',
                          render: (val) => (<>{common.toFormatNumber(val)}</>)
                        }
                      ],
                    },
                    {
                      title: i18n.t('companyWinLoss'),
                      align: 'right',
                      sorter: (a: any, b: any) => a.TotalWinLossAmount - b.TotalWinLossAmount,
                      defaultSortOrder: 'ascend',
                      children: [
                        {
                          title: () => (
                            <div className={common.numColor03(Report ? Report.TotalWinLossAmount : 0)}>
                              {common.toFormatNumber(Report ? Report.TotalWinLossAmount : 0)}
                            </div>
                          ),
                          align: 'right',
                          dataIndex: 'TotalWinLossAmount',
                          render: (val) => (
                            <div className={common.numColor03(val)}>
                              {common.toFormatNumber(val)}
                            </div>
                          )
                        }
                      ],
                    },
                  ]}
                  pagination={false}
                />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;