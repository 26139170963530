import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { timeS2L } from 'utils/common';

// 登入記錄

const PageMain: React.FC = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD'),
    dayjs().endOf('day').format('YYYY-MM-DD')
  ]);
  const [reset, setReset] = useState(false);
  const [params, setParams] = useState<any>({
    StartDate: `${date[0]} 00:00:00`,
    EndDate: `${date[1]} 23:59:59`,
  });

  const { data: loginlogs, isValidating, mutate } = $get({
    url: 'api/loginlog/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const [formChanged, setFormChanged] = useState(false);
  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (formChanged) {
      setFormChanged(false);
      setParams({
        StartDate: `${date[0]} 00:00:00`,
        EndDate: `${date[1]} 23:59:59`,
        PermissionId: formData.PermissionId,
        Account: formData.Account,
      });
    } else mutate();
  };

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
  }

  const { data: permission } = $get({
    url: 'api/loginlog/permission/list',
  });

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} onValuesChange={setFormChanged}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="PermissionId" className="w-12">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('backOfficePermissions')}`}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...((permission && permission.Data) || []).map((item: any) => ({
                          value: item.Id, label: item.Name
                        }))
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="Account">
                    <Input placeholder={`${i18n.t('backOfficeAccount')}`} />
                  </Form.Item>
                </Col>
                <DatePickerCol width={150} date={date} setDate={setDate} initDate={reset} format={'YYYY-MM-DD'}
                  setFormChanged={setFormChanged} />
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                dataSource={
                  loginlogs?.Data?.map((item: OperationInfo) => {
                    return {
                      ...item,
                      key: item.Id,
                    }
                  })
                }
                columns={[
                  {
                    dataIndex: 'CreateDate',
                    title: i18n.t('loginTime'),
                    width: 180,
                    render: (val: string) => timeS2L(val)
                  },
                  {
                    dataIndex: 'PermissionName',
                    title: i18n.t('backOfficePermissions'),
                    width: 180,
                  },
                  {
                    dataIndex: 'Account',
                    title: i18n.t('backOfficeAccount'),
                    width: 180,
                  },
                  {
                    dataIndex: 'Status',
                    title: i18n.t('status'),
                    width: 180,
                    // 登入記錄需求目前只有存登入成功的帳號，如之後有登入失敗也需顯示的需求再另行開發
                    // render: (val) => i18n.t(`${LOGIN_RECORD_STATE[val]}`) || val
                    render: (val) => i18n.t('success')
                  },
                  {
                    dataIndex: 'IP',
                    title: i18n.t('IP'),
                    width: 180,
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={loginlogs ? loginlogs.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;