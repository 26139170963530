import { Button, Col, Form, Image, Input, Modal, Row, Table, message } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { useForm } from 'antd/lib/form/Form';
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { AGENT_ADJUST_STATE, MODE } from 'enum/state';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { timeS2L } from "../utils/common";
import { UploadImage } from './layout.component';
import useAccount from 'hooks/account.hook';

// 列表
export const ViewVerificationList = ({ data, mutate }: {
  data: {
    State: string;
    Data: VerificationList[];
    TotalRecord: number;
  };
  mutate: any
}) => {
  const { permissions: $p } = useAccount();
  const [id, setId] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [popMode, setPopMode] = useState(MODE.add);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case AGENT_ADJUST_STATE.approvaled:
        return 'color-pass'
      case AGENT_ADJUST_STATE.reject:
        return 'color-reject'
      case AGENT_ADJUST_STATE.waitApproval:
        return 'color-padding'
      default: return ''
    };
  };

  return (
    <>
      <Row className="mb-1">
        {
          $p('10802') &&
          <Col>
            <Button type="primary" onClick={() => {
              setPopMode(MODE.add);
              setId(0);
              setIsOpenModal(true);
            }}>{i18n.t('add')}</Button>
          </Col>
        }
      </Row>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={
          data ?
            data.Data.map(item => ({
              key: item.Id,
              MemberAccount: item.MemberAccount,
              Name: item.Name,
              CreateDate: item.CreateDate,
              AuditedTime: item.AuditedTime,
              OperatorAccount: item.OperatorAccount,
              Status: item.Status
            })) : []}
        columns={[
          {
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            width: 200,
          },
          {
            dataIndex: 'Name',
            title: i18n.t('realName'),
            width: 200,
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 200,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'AuditedTime',
            title: i18n.t('auditTime'),
            width: 200,
            render: (val) => timeS2L(val)
          },
          {
            title: i18n.t('status'),
            width: 250,
            render: (_, record: any) => (
              <>
                <div className={stateColor(record.Status)}>{i18n.t(AGENT_ADJUST_STATE[record.Status])}</div>
                {
                  (record.Status !== AGENT_ADJUST_STATE.waitApproval) &&
                  <div>{`${i18n.t('from')} ${record.OperatorAccount || 'System'} ${i18n.t(AGENT_ADJUST_STATE[record.Status])}`}</div>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record: any) => <>
              {
                record.Status === AGENT_ADJUST_STATE.waitApproval && $p('10804') &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.review);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('audit')}</Button>
              }
              {
                record.Status === AGENT_ADJUST_STATE.reject && $p('10803') &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.edit);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('edit')}</Button>
              }
              {
                record.Status === AGENT_ADJUST_STATE.approvaled &&
                <Button className="size-12" type="link" onClick={() => {
                  setPopMode(MODE.detail);
                  setId(record.key);
                  setIsOpenModal(true);
                }}>{i18n.t('detail')}</Button>
              }
            </>
          },
        ]}
        pagination={false}
      />
      <PopupVerificationAccount isOpen={isOpenModal} close={() => setIsOpenModal(false)} mutate={mutate} mode={popMode} id={id} />
    </>
  )
}

// 新增編輯實名驗證
export const PopupVerificationAccount = ({ isOpen, close, mutate, id, mode }: any) => {
  const [form] = useForm();
  const [imageData01, setImageData01] = useState<UploadFile<any>[]>([]);
  const [imageData02, setImageData02] = useState<UploadFile<any>[]>([]);
  const [approve, setApprove] = useState(false);

  const { data: memberData, mutate: mutateOne } = $get({
    url: 'api/kycticket/one',
    params: { Id: id },
    allow: !!id
  });
  useEffect(() => {
    if (isOpen) {
      mutateOne();
    }
  }, [isOpen]);
  useEffect(() => {
    if (memberData && isOpen) {
      form.setFieldsValue({
        Account: memberData.Data.MemberAccount,
        IDNumber: memberData.Data.IDNumber,
        Name: memberData.Data.Name,
        Photo_1: memberData.Data.Photo_1 || null,
        Photo_2: memberData.Data.Photo_2 || null
      })
      setImageData01([{
        uid: memberData.Data.Photo_1,
        name: memberData.Data.Photo_1,
        url: memberData.Data.Photo_1,
      }])
      setImageData02([{
        uid: memberData.Data.Photo_2,
        name: memberData.Data.Photo_2,
        url: memberData.Data.Photo_2,
      }])
    }
  }, [memberData, isOpen]);

  const onFinish = (formData: any) => {
    const send: any = {
      IDNumber: formData.IDNumber,
      Name: formData.Name,
      Photo_1: imageData01[0] ? imageData01[0].url : '',
      Photo_2: imageData02[0] ? imageData02[0].url : '',
      Photo_3: ''
    }
    switch (mode) {
      case MODE.add:
        send.Account = formData.Account;
        $post({
          url: 'api/kycticket/upsert',
          send,
          success: () => {
            message.success(i18n.t('addSuccess'));
            onClose();
            mutate();
          },
          resCode: RESPONSE_CODE_MEMBER
        })
        break;
      case MODE.review:
        $post({
          url: 'api/kycticket/verify',
          send: {
            Id: id,
            Status: approve ? AGENT_ADJUST_STATE.approvaled : AGENT_ADJUST_STATE.reject
          },
          success: () => {
            message.success(approve ? i18n.t('approved') : i18n.t('rejected'))
            onClose();
            mutate();
          },
          resCode: RESPONSE_CODE_MEMBER
        })
        break;
      case MODE.edit:
        send.Id = id;
        $post({
          url: 'api/kycticket/update',
          send,
          success: () => {
            message.success(i18n.t('addSuccess'))
            onClose();
            mutate();
          },
          resCode: RESPONSE_CODE_MEMBER
        })
        break;
    }
  };

  const onClose = () => {
    form.resetFields();
    setImageData01([]);
    setImageData02([]);
    close();
  };

  let title = '';
  switch (mode) {
    case MODE.add:
      title = i18n.t('add');
      break;
    case MODE.review:
      title = i18n.t('audit');
      break;
    case MODE.edit:
      title = i18n.t('edit');
      break;
    case MODE.detail:
      title = i18n.t('detail');
      break;
    default:
      break;
  }

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Account" label={i18n.t('account')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('account')}`} disabled={mode === MODE.review || mode === MODE.edit || mode === MODE.detail} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="IDNumber" label={i18n.t('idNum')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('idNum')}`} disabled={mode === MODE.review || mode === MODE.detail} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('name')} rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('name')}`} disabled={mode === MODE.review || mode === MODE.detail} />
            </Form.Item>
          </Col>
          <Col span={24}>
            {(mode === MODE.add || mode === MODE.edit) && <>
              <Form.Item name="Photo_1" valuePropName="fileList" label={i18n.t('uploadPaperwork')}
                rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]}>
                <UploadImage
                  form={form}
                  name="Photo_1"
                  url={'/upload/images/public/kycticket'}
                  preUrl={memberData ? memberData.Data.Photo_1 : ""}
                  imageData={imageData01}
                  setImageData={setImageData01}
                  remove
                  disabled={mode === MODE.review || mode === MODE.detail}
                />
              </Form.Item>
              <div className="color-03 size-12">
                {i18n.t('onlyJpgOrPngFiles')}
                {i18n.t('allowedAndSizeNotExceed10MB')}
              </div>
              <Form.Item name="Photo_2" valuePropName="fileList" label={i18n.t('uploadPaperwork')}
                rules={[{ required: true, message: `${i18n.t('uploadImage')}` }]}>
                <UploadImage
                  form={form}
                  name="Photo_2"
                  url={'/upload/images/public/kycticket'}
                  preUrl={memberData ? memberData.Data.Photo_2 : ""}
                  imageData={imageData02}
                  setImageData={setImageData02}
                  remove
                  disabled={mode === MODE.review || mode === MODE.detail}
                />
              </Form.Item>
              <div className="color-03 size-12">
                {i18n.t('onlyJpgOrPngFiles')}
                {i18n.t('allowedAndSizeNotExceed10MB')}
              </div>
            </>}
            {(mode === MODE.review || mode === MODE.detail) && imageData01[0] && imageData02[0] && <>
              <Row gutter={[12, 0]}>
                <Col span={24}>{i18n.t('uploadPaperwork')}</Col>
                {memberData?.Data.Photo_1 && <Col><Image src={imageData01[0].url} width={180} height={120} /></Col>}
                {memberData?.Data.Photo_2 && <Col><Image src={imageData02[0].url} width={180} height={120} /></Col>}
              </Row>
            </>}
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              {
                (mode === MODE.add || mode === MODE.edit) &&
                <>
                  <Col>
                    <Button onClick={onClose}>{i18n.t(mode === MODE.edit ? 'reject' : 'cancel')}</Button>
                  </Col>
                  <Col>
                    <Button type="primary" htmlType="submit">{i18n.t(mode === MODE.edit ? 'approvaled' : 'confirm')}</Button>
                  </Col>
                </>
              }
              {
                (mode === MODE.detail) &&
                <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
              }
              {
                (mode === MODE.review) && <>
                  <Col><Button type="primary" danger onClick={() => {
                    setApprove(false);
                    form.submit();
                  }}>{i18n.t('reject')}</Button></Col>
                  <Col><Button type="primary" onClick={() => {
                    setApprove(true);
                    form.submit();
                  }}>{i18n.t('approvaled')}</Button></Col>
                </>
              }
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}
