import { Button, Col, Form, Input, Row, Switch, Table, Tooltip, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import { RESPONSE_CODE_CREATE_ANNOUNCEMENT } from 'constants/response';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { timeS2L } from 'utils/common';

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [id, setId] = useState<number | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [params, setParams] = useState({
    Title: '',
  });
  const { data: SystemAnnouncement, isValidating, mutate } = $get({
    url: 'api/contents/system/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const search = (formData: any) => {
    setParams({
      ...params,
      Title: formData.title
    })
    mutate();
  }

  const handleUpdateStatus = (record: any, state: boolean) => {
    $post({
      url: 'api/contents/system/update',
      send: {
        Id: record.key,
        Title: record.title,
        Content: record.internalContent,
        Position: record.sort,
        PublishDate: record.publicationTime,
        EndDate: record.EndTime,
        Status: state ? 1 : 0
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_CREATE_ANNOUNCEMENT
    })
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const handleDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  const handleSubmit = () => {
    $post({
      url: 'api/contents/announcement/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_CREATE_ANNOUNCEMENT
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Form.Item name="title">
                    <Input className="w-12" placeholder={`${i18n.t('title')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" loading={isValidating}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Button type="primary" onClick={() => navigate('/advanced/announcement/system/add')}>
                    {i18n.t('add')}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                dataSource={
                  SystemAnnouncement ?
                    SystemAnnouncement.Data.map((item: Contents) => (
                      {
                        key: item.Id,
                        sort: item.Position,
                        title: item.Title,
                        internalContent: item.Content,
                        publicationTime: item.PublishDate,
                        EndTime: item.EndTime,
                        status: item.Status,
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('sort'),
                    dataIndex: 'sort',
                    align: 'center',
                    width: 50
                  },
                  {
                    title: i18n.t('title'),
                    dataIndex: 'title',
                    width: 300,
                    render: (_, { title }: any) => (
                      <Tooltip title={title}>
                        <div style={{
                          whiteSpace: 'nowrap', maxWidth: 300,
                          overflow: 'hidden', textOverflow: 'ellipsis'
                        }}>{title}</div>
                      </Tooltip>
                    )
                  },
                  {
                    title: i18n.t('internalContent'),
                    dataIndex: 'internalContent',
                    width: 600,
                    ellipsis: true,
                    render: (_, { internalContent }) => (
                      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: internalContent }} />}>
                        <div style={{
                          whiteSpace: 'nowrap', maxWidth: 600,
                          overflow: 'hidden', textOverflow: 'ellipsis'
                        }}>{internalContent.replace(/<\/?[^>]+(>|$)/g, "")}</div>
                      </Tooltip>
                    )
                  },
                  {
                    title: i18n.t('publicationTime'),
                    dataIndex: 'publicationTime',
                    width: 150,
                    render: (val) => timeS2L(val)
                  },
                  {
                    title: i18n.t('endTime'),
                    dataIndex: 'EndTime',
                    width: 150,
                    render: (val) => timeS2L(val)
                  },
                  {
                    title: i18n.t('status'),
                    width: 80,
                    render: (_, record: any) => (
                      <Switch
                        loading={isValidating}
                        checked={record.status}
                        onClick={v => handleUpdateStatus(record, v)}
                      />
                    )
                  },
                  {
                    title: i18n.t('operation'),
                    width: 150,
                    render: (_, record: any) => (
                      <>
                        <Button className="size-12" type="link" onClick={() => navigate(`/advanced/announcement/system/edit/${record.key}`)}>
                          {i18n.t('edit')}
                        </Button>
                        <Button className="size-12" type="link" onClick={() => handleDelete(record.key)}>
                          {i18n.t('delete')}
                        </Button>
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={SystemAnnouncement ? SystemAnnouncement.TotalRecord : 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
      {/* Modal */}
      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={handleSubmit} />
    </div>
  );
};

export default PageMain;