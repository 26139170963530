import { Button, Col, Form, message, Row, Select, Spin, Table } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupAddFrontSub, PopupEditFrontSub } from 'components/front.component';
import { InquiryWindow, LayoutNav, LayoutPagination, LayoutTabFront } from "components/layout.component";
import { FRONT_COPY_TYPE } from 'enum/state';
import useAccount from "hooks/account.hook";
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from "services";
import { enumToOptions, timeS2L } from "utils/common";
import { RESPONSE_CODE_FRONT } from "../../../constants/response";

// 前台文案: 副標題

interface SearchParams {
  ContentsId?: number;
}

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [type, setType] = useState<number | null>(null);
  const [mainTitleId, setMainTitleId] = useState<number | null>(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [key, setKey] = useState<number | null>(null);
  const [frontTitleParams, setFrontTitleParams] = useState<any>({
    Type: null,
  });
  const { data: FrontList, isValidating: loadingOptions } = $get({
    url: 'api/contents/frontpage/list',
    params: {
      ...frontTitleParams,
      PageIndex: 1,
      PageSize: 999
    },
    showMessage: false,
    allow: !!frontTitleParams.Type
  })
  const [params, setParams] = useState<SearchParams>({});
  const { data: FrontSubList, isValidating, mutate } = $get({
    url: 'api/contents/frontpage/sub/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    },
    showMessage: false
  })
  const onSelectType = (type: number) => {
    setFrontTitleParams({ Type: type });
    setIsAddButtonDisabled(true);
    form.setFieldValue('ContentsId', null);
  }

  const onAdd = () => {
    setIsOpenAddModal(true);
    setType(form.getFieldValue('type'));
    setMainTitleId(form.getFieldValue('ContentsId'));
  }

  const onEdit = (id: number) => {
    setIsOpenEditModal(true);
    setKey(id);
    setType(form.getFieldValue('type'));
    setMainTitleId(form.getFieldValue('ContentsId'));
  }

  const onDelete = (id: number) => {
    setIsOpenDeleteModal(true);
    setKey(id);
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      ContentsId: formData.ContentsId
    });
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setFrontTitleParams({ ...frontTitleParams, Type: FRONT_COPY_TYPE.qa });
  }

  const handleDelete = () => {
    $post({
      url: 'api/contents/frontpage/sub/delete',
      send: { Id: key },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <LayoutTabFront activeKey="2" />
        <Form form={form} onFinish={onFinish} initialValues={{
          type: null,
        }}>
          {/* 搜尋欄 */}
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="type" className="w-12">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('selectCategory')}`}
                      onChange={onSelectType}
                      options={enumToOptions(FRONT_COPY_TYPE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="ContentsId" className="w-12">
                    <Select
                      className="w-full"
                      disabled={!form.getFieldValue('type')}
                      loading={loadingOptions}
                      onChange={() => setIsAddButtonDisabled(false)}
                      placeholder={`${i18n.t('inputData')}${i18n.t('mainTitle')}`}
                      options={
                        (FrontList && form.getFieldValue('type')) ?
                          FrontList.Data.map((item: FrontList) => (
                            { value: item.Id, label: item.Title }
                          )) : []
                      }
                    />
                  </Form.Item>
                </Col>
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
            <Col span={24}>
              {$p('50102') && <Button type="primary" disabled={isAddButtonDisabled} onClick={onAdd}>{i18n.t('add')}</Button>}
            </Col>
            <Col span={24}>
              {/* 數據 */}
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  dataSource={
                    (FrontSubList && FrontSubList.State === 'Success') ?
                      FrontSubList.Data.map((item: FrontSubList) => ({ key: item.Id, ...item })) : []
                  }
                  columns={[
                    {
                      title: i18n.t('sort'),
                      dataIndex: 'Position',
                      align: 'center',
                      width: 80,
                    },
                    {
                      title: i18n.t('subTitle'),
                      dataIndex: 'Title',
                      width: 800
                    },
                    {
                      title: i18n.t('updater'),
                      dataIndex: 'OperatorAccount',
                      width: 120,
                      render: (val) => val || 'System'
                    },
                    {
                      title: i18n.t('updateTime'),
                      dataIndex: 'UpdateDate',
                      width: 120,
                      render: (val: string) => timeS2L(val)
                    },
                    {
                      title: i18n.t('createTime'),
                      dataIndex: 'CreateDate',
                      width: 120,
                      render: (val: string) => timeS2L(val)
                    },
                    {
                      title: i18n.t('operation'),
                      width: 100,
                      render: (_, record: any) => (
                        <>
                          {$p('50103') && <Button type="link" onClick={() => onEdit(record.key)}>{i18n.t('edit')}</Button>}
                          {$p('50104') && <Button type="link" onClick={() => onDelete(record.key)}>{i18n.t('delete')}</Button>}
                        </>
                      )
                    }
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={FrontSubList ? FrontSubList.TotalRecord : 0}
                  setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>

      <PopupAddFrontSub isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)}
        type={type} main={mainTitleId} FrontList={FrontList} mutate={() => onFinish({ ContentsId: mainTitleId })} />
      <PopupEditFrontSub isOpen={isOpenEditModal} close={() => setIsOpenEditModal(false)} id={key}
        type={type} main={mainTitleId} FrontList={FrontList} mutate={mutate} />
      <InquiryWindow isOpen={isOpenDeleteModal} close={() => setIsOpenDeleteModal(false)} 
        msg={i18n.t('confirmDeleteData')} action={handleDelete} />
    </div>
  );
};

export default PageMain;