import { COOKIE } from "constants/cookie";
import useSite from "hooks/site.hook";
import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from 'socket.io-client';

export const socketContext = createContext<SocketContext>({});

export const useSocket = () => {
  return useContext(socketContext);
};

export const SocketProvider = ({ children }: {
  children?: any
}) => {
  const { data: $s }: any = useSite();

  const [socketOriginal, setSocketOriginal] = useState<Socket>();
  const [socketOriginalId, setSocketOriginalId] = useState<string | undefined>("");
  const [socketChat, setSocketChat] = useState<Socket>();
  const [socketChatId, setSocketChatId] = useState<string | undefined>("");

  const [serviceMessages, setServiceMessages] = useState<any>({});
  const [privateMessage, setPrivateMessage] = useState<any>({});
  const [notification, setNotification] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [reviewKyc, setReviewKyc] = useState<number>(0);
  const [reviewBankcard, setReviewBankcard] = useState<number>(0);

  const [peopleAgentAction, setPeopleAgentAction] =useState<any>("");
  const [peopleAgentStatus, setPeopleAgentStatus] =useState<any>(0);

  const token = Cookies.get(COOKIE.TOKEN);

  useEffect(() => {
    if ($s?.Domain) {

      // 一般socket
      const socketOriginalIo = io(`wss://socket.${$s.Domain}/bo`, {
        transports: ['websocket'],
        secure: true,
        auth: {
          token
        }
      });
      setSocketOriginal(socketOriginalIo);

      // 聊天室
      const socketChatIo = io(`wss://chat.${$s.Domain}`, {
        transports: ['websocket'],
        secure: true,
        auth: {
          token,
          role: 'Service'
        }
      });
      setSocketChat(socketChatIo);
    }
  }, [$s]);

  useEffect(() => {
    if (!socketOriginal) return;
    socketOriginal.off();

    // 連線
    socketOriginal.on('connect', () => {
      setSocketOriginalId(socketOriginal.id);
    });
   
    // 全民代理
    socketOriginal.on('people-agent', function(msg) {
      const message = JSON.parse(msg);
      switch (message.Action) {
        case null:
          setPeopleAgentAction(`${message.Action}-${Date.now()}`);
          setPeopleAgentStatus(0);
          break;
        case 'allPass':
        case 'changeMember':
        case 'batchReject':
        case 'recalculate':
          setPeopleAgentAction(`${message.Action}-${Date.now()}`);
          setPeopleAgentStatus(message.Status);
          break;
      }
    });

    socketOriginal.on('kyc', function(msg) {
      const message = JSON.parse(msg);
      switch (message.Action) {
        case 'past':
          setReviewKyc(message.Quantity);
          break;
        case 'apply':
          setReviewKyc((reviewKyc) => reviewKyc + 1);
          break;
        case 'pass':
        case 'reject':
          setReviewKyc((reviewKyc) => reviewKyc - 1);
          break;
      }
    
    });

    socketOriginal.on('withdraw-bank', function(msg) {
      const message = JSON.parse(msg);
      switch (message.Action) {
        case 'past':
          setReviewBankcard(message.Quantity);
          break;
        case 'apply':
          setReviewBankcard((reviewBankcard) => reviewBankcard + 1);
          break;
        case 'pass':
        case 'reject':
          setReviewBankcard((reviewBankcard) => reviewBankcard - 1);
          break;
      }
    });

  }, [socketOriginal, socketOriginalId]);

  useEffect(() => {
    if (!socketChat) return;
    socketChat.off();

    // 連線
    socketChat.on('connect', function() {
      setSocketChatId(socketChat.id);
    });
    // 斷線
    socketChat.on('disconnect', function() {
    });
    // 未登入
    socketChat.on('unauthorized', function() {
    });
    // 客服訊息
    socketChat.on('service_message', function(msg) {
      const message = JSON.parse(msg);
      setServiceMessages(message);
    });
    // 私人訊息
    socketChat.on('private_message', function(msg) {
      const message = JSON.parse(msg);
      setPrivateMessage(message);
    });
    // 客服通知
    socketChat.on('service_notification', function(msg) {
      const message = JSON.parse(msg);
      setSessionId(message.SessionId);
      setNotification(`${message.Notification}-${new Date()}`);
    });
    // 連線失敗
    socketChat.on('connect_error', function(msg) {
    });

  }, [socketChat, socketChatId]);

  return (
    <socketContext.Provider value={{
      serviceMessages,
      privateMessage,
      reviewKyc,
      reviewBankcard,
      notification,
      sessionId,
      peopleAgentAction,
      peopleAgentStatus
    }}>
      {children}
    </socketContext.Provider>
  )
}