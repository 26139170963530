import { Button, Col, Descriptions, Form, Input, InputNumber, Row, Select, Tag, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { SelectAgentPermission } from 'components/agent.component';
import { LayoutNav, LayoutTextArea } from 'components/layout.component';
import { REGEX_ACCOUNT } from 'constants/regex';
import { RESPONSE_CODE_AGENT } from 'constants/response';
import { SETTLE_CYCLE } from 'enum/promotion';
import { ENABLE } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { convertedToPercentage02 as $c, convertedToPercentage01 as $g, enumToOptions, verify } from 'utils/common';

// 新增子代

const PageMain: React.FC = () => {

  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const { data: agentSa } = $get({
    url: 'api/agent/one',
    params: { Id: id },
    allow: !!id
  })

  // 代理標籤
  const { data: tagList } = $get({ url: 'api/tag/list' });

  // 取得上代返水(最大值)
  const [parentRebate, setParentRebate] = useState<any>({
    BonusCostRate: 100,
    CashFlowFeeRate: 100,
  });
  const { data: parentRebateInfo } = $get({
    url: 'api/agentoperationparams/one',
    params: { AgentId: id },
    allow: !!id
  })
  useEffect(() => {
    if (parentRebateInfo) {
      setParentRebate({
        BonusCostRate: $g(parentRebateInfo?.Data.BonusCostRate),
        CashFlowFeeRate: $g(parentRebateInfo?.Data.CashFlowFeeRate),
      })
    }
  }, [parentRebateInfo]);

  useEffect(() => {
    resetFormValues();
  }, [agentSa]);

  const resetFormValues = () => {

    if (!agentSa) return;

    form.setFieldsValue({
      agentSaAccount: agentSa.Data.Account,
      tag: undefined,
      commissionCycle: agentSa.Data.CommissionSettleCycle,
      revenueCycle: agentSa.Data.RevenueShareSettleCycle,
    });
  }

  const [permissions, setPermissions] = useState([]);
  const onFinish = (formData: any) => {
    if (!REGEX_ACCOUNT.test(formData.account) || typeof formData.account[0] !== 'string') {
      return message.error(i18n.t('INCORRECT_ACCOUNT_FORMAT'));
    }
    if (formData.password !== formData.confirmPassword) {
      return message.error(i18n.t('INCORRECT_REPEAT_PASSWORD'));
    }
    if (
      !$c(formData.BonusCostRate) ||
      !$c(formData.feeRate)
    ) return message.error(i18n.t('contentOnlyNumber'));
    if (
      parseFloat(formData.BonusCostRate) < 0 ||
      parseFloat(formData.feeRate) < 0 ||
      parseFloat(formData.BonusCostRate) > parentRebate.BonusCostRate ||
      parseFloat(formData.feeRate) > parentRebate.CashFlowFeeRate
    ) return message.error(i18n.t('discountAndPaymentRateCannotExceedParent'))
    $post({
      url: 'api/agent/ag/create',
      send: {
        AgentAccount: agentSa?.Data.Account,
        Account: formData.account,
        Password: formData.password,
        RepeatPassword: formData.confirmPassword,
        TagsId: formData.TagsId || [],
        Remark: formData.remark,
        BonusCostRate: parseFloat(formData.BonusCostRate) / 100,
        CashFlowFeeRate: parseFloat(formData.feeRate) / 100,
        Permission: permissions
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        setConfirm(true);
      },
      resCode: RESPONSE_CODE_AGENT
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish}>
          {/* 基本資料 */}
          <Row align="middle">
            <Col span={24}>
              <Row align="middle" justify="end" gutter={[12, 12]}>
                {confirm ?
                  <Col><Button onClick={() => navigate('/agent')}>{i18n.t('close')}</Button></Col> :
                  <>
                    <Col><Button onClick={() => navigate('/agent')}>{i18n.t('cancel')}</Button></Col>
                    <Col><Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
                  </>
                }
              </Row>
            </Col>
            <Col span={24}>
              <Descriptions size="small" layout="horizontal" title={i18n.t('addAgent')} column={{ xs: 8 }} bordered
                contentStyle={{ maxWidth: 500, width: 500 }}
                labelStyle={{ fontSize: 12, fontWeight: 500, maxWidth: 200, width: 200 }}>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('upperAgentMasterAgent')}</>}>
                  <Form.Item name="agentSaAccount">
                    <Input disabled />
                  </Form.Item>
                </Descriptions.Item>
                {/* 聖經欄位驗證 */}
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('account')}</>}>
                  <Form.Item name="account" rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    { min: 4, message: `4~12${i18n.t('requiresEnglishAndNumbersStartWithEnglish')}` },
                    { max: 12, message: `4~12${i18n.t('requiresEnglishAndNumbersStartWithEnglish')}` },
                  ]}>
                    <Input disabled={confirm} placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('password')}</>}>
                  <Form.Item name="password" rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    { min: 4, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
                    { max: 20, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
                  ]}>
                    <Input.Password disabled={confirm} placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('confirmPassword')}</>}>
                  <Form.Item name="confirmPassword" rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) return Promise.resolve();
                        else return Promise.reject(new Error(`${i18n.t('INCORRECT_REPEAT_PASSWORD')}`));
                      },
                    }),
                  ]}>
                    <Input.Password disabled={confirm} placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                  <Select
                    className="w-full"
                    disabled
                    value={i18n.t('waitApproval')}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('agentTag')}>
                  <Form.Item name="TagsId">
                    <Select
                      mode="tags"
                      disabled={confirm}
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      options={
                        tagList?.Data
                          .filter((item: any)=>item.Status === ENABLE.enabled)
                          .map((option: any ) => ({
                          value: option.Id,
                          label:
                            <Tag key={option.Id} style={{ color: option.TextColor, background: option.Color }}>
                              {option.Name}
                            </ Tag>
                        }))
                      }
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('promotionRevenue')}%</>}>
                  <Form.Item name="BonusCostRate" rules={verify({ max: parentRebate.BonusCostRate || 100 })}>
                    <InputNumber className="w-full" disabled={confirm} placeholder={`${i18n.t('inputData')}`} maxLength={12} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<><span className="require">*</span>{i18n.t('paymentFeeRate')}</>}>
                  <Form.Item name="feeRate" rules={verify({ max: parentRebate.CashFlowFeeRate || 100 })}>
                    <InputNumber className="w-full" disabled={confirm} placeholder={`${i18n.t('inputData')}`} maxLength={12} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('remark')} contentStyle={{ height: 100 }}>
                  <LayoutTextArea formName='remark' disabled={confirm} />
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>

          {/* 後台權限 */}
          <Row align="middle" className="mt-1">
            <Col span={24}>
              <Col className="size-16 font-w-md">{i18n.t('backOfficePermissions')}</Col>
              <Col span={24} className="mt-1">
                <SelectAgentPermission permissions={permissions} setPermissions={setPermissions} confirm={confirm} />
              </Col>
            </Col>
          </Row>

          {/* 返水/占成設定 */}
          <Row align="middle" className="mt-1" gutter={[30, 30]}>
            <Col span={12}>
              <Descriptions size="small" layout="vertical" title={i18n.t('cashbackCommissionSetting')} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('rebateSettlementPeriod')}</>}>
                  <Form.Item name="commissionCycle">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={enumToOptions(SETTLE_CYCLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <Descriptions size="small" layout="vertical" title={i18n.t('cashbackCommissionSetting')} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('revenueSettlementPeriod')}</>}>
                  <Form.Item name="revenueCycle">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={enumToOptions(SETTLE_CYCLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;