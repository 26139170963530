import { Button, Col, Form, Row, Select, Spin, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ViewMessageList, ViewMessageTemplateList } from "components/message.component";
import dayjs from 'dayjs';
import { MESSAGE_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from "react";
import { $get } from 'services';
import { enumToOptions, timeL2S } from "utils/common";
import useAccount from "../../../hooks/account.hook";

// 即時訊息

interface SearchParams {
  IsRead: number | string | null;
  StartDate: string;
  EndDate: string;
  PageSize?: number;
  PageIndex?: number;
}
enum TAB {
  即時訊息 = '1',
  訊息模板 = '2',
}

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();
  const [activeKey, setActiveKey] = useState<string>("");
  const items: any= [
    {
      key: TAB.即時訊息,
      label: <div className="w-8 text-center">{i18n.t('instantMessages')}</div>
    },
    {
      key: TAB.訊息模板,
      label: <div className="w-8 text-center">{i18n.t('messageTemplate')}</div>
    }
  ];

  const filteredItems = items.filter((item: any) => {
      if ($p('11001') && !$p('11501')) {
        return item.key === TAB.即時訊息;
      } else if ($p('11501') && !$p('11001')) {
        return item.key === TAB.訊息模板;
      } else if ($p('11501') && $p('11001')) {
        return item
      }
  });

  useEffect(() => {
    setActiveKey(filteredItems[0]?.key);
  }, [init]);

  const onTabClick = (key: string) => {
    setActiveKey(key);
  }

// 即時訊息
  const [form] = useForm();
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState<SearchParams>({
    IsRead: null,
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1])
  });
  const { data: messageData, isValidating, mutate } = $get({
    url: 'api/mail/list',
    params: {
      ...params,
      PageSize: page[1],
      PageIndex: page[0]
    },
    allow: activeKey === TAB.即時訊息
  });

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        IsRead: formData.statusType,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1]),
        PageSize: page[1],
        PageIndex: page[0]
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  // 訊息模板
  const { data: messageTemplateData, isValidating: messageTemplateValidating, mutate: messageTemplateMutate } = $get({
    url: 'api/mail/setting/list',
    allow: activeKey === TAB.訊息模板
  });


  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Tabs className="mt-1" activeKey={activeKey} items={init && filteredItems} onTabClick={onTabClick} />
        {activeKey === TAB.即時訊息&&
        <Row>
          <Col span={24}>
        {/* 搜尋列 */}
        <Form form={form} onFinish={onFinish} initialValues={{
          statusType: '',
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item className="w-12" name="statusType">
                <Select
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    { value: "", label: `${i18n.t('ALL')}` },
                    ...enumToOptions(MESSAGE_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset} />
            <Col>
              <Button htmlType="submit" type="primary" loading={isValidating}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 搜尋結果 */}
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewMessageList data={messageData} mutate={mutate} />
              <LayoutPagination total={messageData && messageData.TotalRecord} setPage={setPage} page={page} />
            </Spin>
          </Col>
        </Row>
          </Col>
        </Row>
        }
        {activeKey === TAB.訊息模板&&
          <Row>
            <Col span={24}>
              <Row className="mt-1">
                <Col span={24}>
                  <Spin spinning={isValidating}>
                    <ViewMessageTemplateList data={messageTemplateData} mutate={messageTemplateMutate} />
                  </Spin>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      </Content>
    </div>
  );
};

export default PageMain;