import { Button, Col, Form, Image, Input, Modal, Row, Select, Space, Table, message } from 'antd';
import { UploadFile } from 'antd/es/upload';
import hintIcon from 'assets/image/icon-12.png';
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { CONTACT_INFO, ENABLE, SOURCE_ROLE } from 'enum/state';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { $post } from 'services';
import { enumToOptions } from 'utils/common';
import { UploadImage } from './layout.component';
import React, { useContext, useMemo } from 'react';
import { HolderOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { ColumnsType } from 'antd/es/table';

export const PopupContact = ({ isOpen, id, contact, close, mutate }: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (contact && isOpen) {
      form.setFieldsValue({
        SourceRole: contact.SourceRole,
        SourceAccount: contact.SourceAccount,
        ContactInfo: Number(contact.ContactInfo),
        ContactContent: contact.ContactContent,
        Status: contact.Status,
      })
      contact.Photo && setImageData([
        {
          uid: contact.Photo,
          name: contact.Photo,
          url: contact.Photo,
        },
      ])
    }
  }, [contact, isOpen]);

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const handleSubmit = (formData: any) => {
    const send: any = {
      SourceRole: formData.SourceRole,
      SourceAccount: formData.SourceAccount,
      ContactInfo: String(formData.ContactInfo),
      ContactContent: formData.ContactContent,
      Status: formData.Status,
      Photo: imageData[0] ? [imageData[0].url] : [],
    }
    if (!contact) {
      send.MemberId = id;
      $post({
        url: 'api/member/contact/create',
        send,
        success: () => {
          message.success(i18n.t('addSuccess'));
          handleCancel();
          mutate();
        },
        resCode: RESPONSE_CODE_MEMBER
      })
    }
    else {
      send.Id = contact?.Id;
      $post({
        url: 'api/member/contact/update',
        send,
        success: () => {
          message.success(i18n.t('updateSuccess'));
          handleCancel();
          mutate();
        },
        resCode: RESPONSE_CODE_MEMBER
      })
    }
  }

  const handleCancel = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={!contact ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={{
        ContactInfo: CONTACT_INFO.Line,
        Status: ENABLE.enabled,
      }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="SourceRole" label={i18n.t('source')} rules={[{ required: true }]}>
              <Select className="w-full" placeholder={`${i18n.t('pleaseSelect')}`} options={enumToOptions(SOURCE_ROLE)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="SourceAccount" label={i18n.t('account')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('account')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="ContactInfo" label={i18n.t('contactMethod')} rules={[{ required: true }]}>
              <Select className="w-full" options={enumToOptions(CONTACT_INFO)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="ContactContent" label={i18n.t('content')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}${i18n.t('content')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select className="w-full" options={enumToOptions(ENABLE)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Photo" valuePropName="fileList" label={i18n.t('image')}>
              {contact !== null ?
                <UploadImage
                  name="Photo"
                  url={'/upload/images/public/contact'}
                  preUrl={contact ? contact.Photo : ''}
                  imageData={imageData}
                  setImageData={setImageData}
                /> :
                <UploadImage
                  name="Photo"
                  url={'/upload/images/public/contact'}
                  imageData={imageData}
                  setImageData={setImageData}
                />}
            </Form.Item>
            <div className="color-03 size-12">
              {`${i18n.t('onlyJpgOrPngFiles')}${i18n.t('allowedAndSizeNotExceed500kb')}`}
            </div>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={handleCancel}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 棄用
export const PopupDeleteContact = ({ isOpen, id, close, mutate }: any) => {
  const handleSubmit = () => {
    $post({
      url: 'api/member/contact/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
        close();
      },
      resCode: RESPONSE_CODE_MEMBER
    })
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onOk={handleSubmit}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" key="confirm" type="primary" onClick={handleSubmit}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}?</div>
      </Space>
    </Modal>
  )
}

// 拖曳列表
export const TableDrag = ({ 
  size, bordered, pagination, 
  columns, dataSource, setDataSource, 
  callback, 
  disabledDrag, disabledDragIndex
}: any) => {
  const RowContext = React.createContext<any>({});

  const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{ cursor: 'move' }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const _columns: any = [
    { 
      title: i18n.t('drag'), 
      align: 'center', 
      width: 50, 
      render: (val: any, record: any, i: number) => {
        if (dataSource.length<4 || i === dataSource.length - 1) {
          return null;
        }
        return (!disabledDrag && Number(disabledDragIndex) !== i ) ? <DragHandle /> : null;
      }
    },
    ...columns
  ];

  const TableRow: React.FC<any> = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: props['data-row-key'] });
  
    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };
  
    const contextValue = useMemo<any>(
      () => ({ setActivatorNodeRef, listeners }),
      [setActivatorNodeRef, listeners],
    );
  
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    const activeIndex = dataSource.findIndex((record: any) => record.key === active?.id);
    const overIndex = dataSource.findIndex((record: any) => record.key === over?.id);
    if (active.id !== over?.id && (activeIndex !== disabledDragIndex && overIndex !== disabledDragIndex)) {
      setDataSource((prevState: any) => {
        // const activeIndex = prevState.findIndex((record: any) => record.key === active?.id);
        // const overIndex = prevState.findIndex((record: any) => record.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      if (callback) callback(active, over);
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={dataSource.map((i: any) => i.key)} strategy={verticalListSortingStrategy}>
        <Table
          size={size}
          bordered={bordered}
          pagination={pagination}

          rowKey="key"
          components={{ body: { row: TableRow } }}
          columns={_columns}
          dataSource={dataSource}
        />
      </SortableContext>
    </DndContext>
  )
}
