export enum CHAT_TYPE {
  等待中 = '1',
  我的對話 = '2',
  同事對話 = '3',
}
export enum SERVICE_STATUS {
  在線 = 1,
  離線 = 0
}
export enum SERVICE_NOTIFY {
  開啟通知 = 1,
  關閉通知 = 0
}
export enum CHAT_ROOM {
  自己 = 1,
  同事 = 2
}
export enum AVATAR_TYPE {
  自訂頭像 = 1,
  預設頭像 = 0
}