import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { PopupAddPromotionVip, PopupAuditPromotionVip, PopupDetailPromotionVip } from 'components/promotion.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { PROMOTION_VIP_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { timeL2S, timeS2L } from "utils/common";

// 會員等級優惠查詢

const MEMBER_LEVEL_TYPE = [
  `${i18n.t('memberLevelPromotion')}-${i18n.t('upgradeBonus')}`,
  `${i18n.t('memberLevelPromotion')}-${i18n.t('weeklyBonus')}`,
  `${i18n.t('memberLevelPromotion')}-${i18n.t('amountMonthly')}`
]

interface SearchParams {
  Code?: string;
  MemberAccount?: string;
  Status?: number;
  TimeType: number;
  StartDate: string;
  EndDate: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [hideColumns, setHideColumns] = useState({
    order: true,
    memberAccount: true,
    recommendedMembers: true,
    transactionType: true,
    amount: true,
    rolloverRate: true,
    settlementDate: true,
    createTime: true,
    takeTime: true,
    status: true
  })

  const defaultDate = 0;
  const [params, setParams] = useState<SearchParams>({
    TimeType: 0,
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1])
  });
  const { data: MemberlevelRebateList, isValidating, mutate } = $get({
    url: 'api/memberlevel/reward/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        Code: formData.order,
        MemberAccount: formData.account,
        Status: formData.status,
        TimeType: formData.TimeMode,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
  }

  const colorChange = (type: number) => {
    switch (type) {
      case PROMOTION_VIP_STATE.received:
        return 'color-pass'
      case PROMOTION_VIP_STATE.dispatched:
        return 'color-reviewing'
      case PROMOTION_VIP_STATE.notDispatch:
        return 'color-reject'
    }
  }

  const hideColumnsKeys = Object.keys(hideColumns);
  const hideColumnsValues = Object.values(hideColumns);
  const onChantColumns = (e: any) => {
    setHideColumns({
      ...hideColumns,
      [e.target.name]: e.target.checked
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: defaultDate,
        }}>
          <Row align="middle" gutter={10}>
            <Col className="w-12">
              <Form.Item name="order">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="account">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  options={[
                    { value: 0, label: `${i18n.t('createTime')}` },
                    { value: 1, label: `${i18n.t('takeTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    { value: 0, label: i18n.t('dispatched') },
                    { value: 1, label: i18n.t('received') },
                  ]}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
            <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="small"
                  dataSource={
                    MemberlevelRebateList ? [
                      {
                        key: 1,
                        totalCount: MemberlevelRebateList.TotalRecord,
                        totalAmount: MemberlevelRebateList.TotalAmount
                      }
                    ] : []
                  }
                  columns={[
                    {
                      title: i18n.t('totalCount'),
                      dataIndex: 'totalCount',
                      align: "right",
                      width: '50%'
                    },
                    {
                      title: i18n.t('totalAmount'),
                      dataIndex: 'totalAmount',
                      align: "right",
                      width: '50%',
                      render: (_, { totalAmount }) => common.toFormatNumber(totalAmount)
                    },
                  ]}
                  pagination={false}
                />
              </Spin>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1" justify="end">
            <Col>
              <Popover content={
                hideColumnsKeys &&
                hideColumnsKeys.map((item, i) => (
                  <div key={i}>
                    <Checkbox name={item} defaultChecked={hideColumnsValues[i]}
                      onChange={onChantColumns}>{i18n.t(item)}</Checkbox>
                  </div>
                ))

              } placement="bottomRight" trigger="click">
                <Button><DatabaseFilled /></Button>
              </Popover>
            </Col>
          </Row>
          <Spin spinning={isValidating}>
            <Table
              size="middle"
              className="mt-1"
              scroll={{ x: 1500 }}
              dataSource={
                MemberlevelRebateList?.Data.map((item: MemberlevelRebateInfo) => (
                  { key: item.Id, ...item, OperatorAccount: 'system' }
                )) || []
              }
              columns={[
                {
                  title: i18n.t('order'),
                  dataIndex: 'Code',
                  className: hideColumns.order ? '' : 'hide',
                  width: 250,
                  fixed: 'left',
                },
                {
                  title: i18n.t('memberAccount'),
                  dataIndex: 'Account',
                  className: hideColumns.memberAccount ? '' : 'hide',
                  width: 200,
                },
                {
                  title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
                  width: 250,
                  className: hideColumns.recommendedMembers ? '' : 'hide',
                  render: (_, record: any) => (
                    <>
                      <span>{record.UpAccount || '-'}</span>
                      <span>／</span>
                      <span>{record.AgAccount || '-'}</span>
                      <span>／</span>
                      <span>{record.SaAccount || '-'}</span>
                    </>
                  )
                },
                {
                  title: i18n.t('transactionType'),
                  dataIndex: 'Type',
                  className: hideColumns.transactionType ? '' : 'hide',
                  width: 150,
                  render: (_, { Type }) => MEMBER_LEVEL_TYPE[Type]
                },
                {
                  title: i18n.t('amount'),
                  dataIndex: 'bonus_amount',
                  className: hideColumns.amount ? '' : 'hide',
                  align: 'right',
                  width: 200,
                  render: (_, { bonus_amount }) => common.toFormatNumber(bonus_amount)
                },
                {
                  title: i18n.t('rolloverRate'),
                  dataIndex: 'RewardValidBetTimes',
                  className: hideColumns.rolloverRate ? '' : 'hide',
                  align: 'right',
                  width: 100
                },
                {
                  title: i18n.t('settlementDate'),
                  dataIndex: 'RewardDate',
                  className: hideColumns.settlementDate ? '' : 'hide',
                  width: 180,
                  render: (val) => timeS2L(val)
                },
                {
                  title: i18n.t('createTime'),
                  dataIndex: 'CreateDate',
                  className: hideColumns.createTime ? '' : 'hide',
                  width: 180,
                  render: (val) => timeS2L(val)
                },
                {
                  title: i18n.t('takeTime'),
                  dataIndex: 'ReceiveDate',
                  className: hideColumns.takeTime ? '' : 'hide',
                  width: 180,
                  render: (val) => timeS2L(val)
                },
                {
                  title: i18n.t('status'),
                  dataIndex: 'Status',
                  className: hideColumns.status ? '' : 'hide',
                  fixed: 'right',
                  width: 150,
                  render: (_, { Status, OperatorId, OperatorAccount }) => (
                    <>
                      <div className={colorChange(Status)}>
                        {i18n.t(PROMOTION_VIP_STATE[Status])}
                      </div>
                      <div>
                        {i18n.t('from')}
                        {OperatorId === 0 ? 'System' : OperatorAccount}
                        {Status === PROMOTION_VIP_STATE.dispatched && i18n.t('distribution')}
                        {Status === PROMOTION_VIP_STATE.received && i18n.t('distribution')}
                      </div>
                    </>
                  )
                },
              ]}
              pagination={false}
            />
            <LayoutPagination total={MemberlevelRebateList ? MemberlevelRebateList.TotalRecord : 0} setPage={setPage} page={page} />
          </Spin>
        </Form>
      </Content>

      <PopupAddPromotionVip isOpen={isModalOpenAdd} close={() => setIsModalOpenAdd(false)} />
      <PopupAuditPromotionVip isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} />
      <PopupDetailPromotionVip isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} />
    </div>
  );
};

export default PageMain;