import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { PopupAddBackOfficeAccount, ViewPermissionBackOffice } from 'components/advanced.component';
import { LayoutNav, LayoutTabPermission } from 'components/layout.component';
import { PERMISSION_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import { enumToOptions } from 'utils/common';

// 後台帳號

interface SearchParams {
  Account?: string;
  Status?: number;
  PermissionId?: number;
  PageSize: number,
  PageIndex: number;
}

const PageMain: React.FC = () => {
  const { id }: any = useParams();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [isOpenAdd, setIsOpenAdd] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      PermissionId: parseInt(id) || ''
    })
  }, [])

  const [searchParams, setSearchParams] = useState<SearchParams>({
    PermissionId: id || '',
    PageSize: 10,
    PageIndex: 1,
  });
  const { data: accountList, mutate, isValidating } = $get({
    url: 'api/account/list',
    params: searchParams
  })

  const search = async (formData: any) => {
    setSearchParams({
      ...searchParams,
      PageIndex: 1,
      Account: formData.Account,
      Status: formData.Status,
      PermissionId: formData.PermissionId
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setSearchParams({
      ...searchParams,
      PageSize: 10,
      PageIndex: 1,
    })
  }

  // 權限列表
  const { data: permissionList } = $get({
    url: 'api/permission/list',
    params: { PageIndex: 1, PageSize: 999 }
  });

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPermission activeKey="2" />
        <Form form={form} onFinish={search}>
          <Row className="mt-1" gutter={[0, 12]}>
            {/* 搜尋 */}
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Form.Item name="PermissionId" className="w-12">
                    <Select
                      defaultValue={''}
                      options={[
                        { value: '', label: i18n.t('allPermission') },
                        ...(permissionList?.Data || []).map((item: any) => (
                          { value: item.Id, label: item.Name }
                        ))
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="Account" className="w-12" >
                    <Input placeholder={`${i18n.t('inputData')}${i18n.t('account')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="Status" className="w-12">
                    <Select
                      defaultValue={''}
                      options={[
                        { value: '', label: i18n.t('allStatus') },
                        ...enumToOptions(PERMISSION_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button htmlType="submit" type="primary" loading={isValidating}>{i18n.t('search')}</Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button onClick={onClear}>{i18n.t('clear')}</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 新增按鈕 */}
            <Col span={24}>
              {$p('00802') && <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>}
            </Col>
            {/* 列表 */}
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewPermissionBackOffice accountList={accountList} mutate={mutate}
                  setParams={setSearchParams} params={searchParams} />
              </Spin>
            </Col>
          </Row>
        </Form>
        <PopupAddBackOfficeAccount isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} mutate={mutate} permissionList={permissionList} />
      </Content>
    </div>
  );
};

export default PageMain;