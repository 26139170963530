import { Button, Col, Form, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { JackpotSettingList } from 'components/promotion.component';
import dayjs from 'dayjs';
import { PROMOTION_STATE } from 'enum/promotion';
import { PROMOTION_TIME } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get } from 'services';
import { enumToOptions, gameNameArray, timeL2S } from "utils/common";

// 獎池優惠設定

interface SearchParams {
  Name?: string;
  Category?: number;
  Type?: number;
  Status?: number;
  TimeCondition?: number;
  StartDate?: string;
  EndDate?: string;
  DateType?: number | undefined;
  PageSize?: number;
  PageIndex?: number;
}

interface PromotionType {
  Id: number,
  Name: string
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<any>({
    TimeCondition: PROMOTION_TIME.eventTime,
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
  })

  const { data: promotion, mutate, isValidating } = $get({
    url: 'api/bonus/jackpot/list',
    params: {
      ...params,
      PageSize: page[1],
      PageIndex: page[0],
    },
  })

  const { data: jackpotName } = $get({
    url: 'api/bonus/jackpot/name/list'
  })
  const { data: gameName } = $get({
    url: 'api/bonus/jackpot/game/list'
  })

  // 搜尋
  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        Id: formData.Id,
        Name: formData.Name,
        GameId: formData.GameId,
        Status: formData.Status,
        TimeCondition: formData.TimeCondition,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          type: '1',
          TimeCondition: PROMOTION_TIME.eventTime
        }}>
          {/* 第一排 */}
          <Row gutter={[12, 12]} className="pb-1">
            <Col>
              <Form.Item className="w-12" name="type">
                <Select disabled options={[{ value: '1', label: i18n.t('jackpot') }]}/>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="Id">
              <Select
                  className="w-full"
                  placeholder={i18n.t('jackpotId')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...(jackpotName?.Data || []).map((item: any) => ({
                      value: item.Id, label: item.Id
                    }))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="Name">
                <Select
                  placeholder={i18n.t('jackpotName')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...(jackpotName?.Data || []).map((item: any) => ({
                      value: item.Name, label: item.Name
                    }))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="GameId">
                <Select
                  placeholder={i18n.t('gameName')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...(gameName?.Data || []).map((item: any) => ({
                      value: item.Id, label: gameNameArray([item])
                    }))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="Status">
                <Select
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    ...enumToOptions(PROMOTION_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* 第二排 */}
          <Row gutter={[12, 12]} className="pb-1">
            <Col>
              <Form.Item className="w-12" name="TimeCondition">
                <Select options={enumToOptions(PROMOTION_TIME)} />
              </Form.Item>
            </Col>
            <DatePickerCol width={191} date={date} setDate={setDate} initDate={reset} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 新增 */}
        {$p('30702') && <Row gutter={[16, 16]} className="pb-1">
          {<Col><Button type="primary" onClick={() => navigate('/promotion/add-jackpot')}>{i18n.t('add')}</Button></Col>}
        </Row>}
        {/* 列表 */}
        <Spin spinning={isValidating}>
          <JackpotSettingList data={promotion} mutate={mutate} />
          <LayoutPagination total={promotion ? promotion.TotalRecord : 0} page={page} setPage={setPage} />
        </Spin>
      </Content>
    </div>
  );
};

export default PageMain; 