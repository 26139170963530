import { Image, Tag } from 'antd';
import offline from 'assets/image/offline.svg';
import online from 'assets/image/online.svg';
import { AGENT_STATE, AGENT_WITHDRAW_STATE, MEMBER_FREEZE, MEMBER_STATE } from 'enum/state';
import i18n from 'i18n';
// NOTE: 圖片直接import就好 不用放這邊

const useIcon = () => {

  const memberStateIcon = (state: number, width = 'w-full') => {
    switch (state) {
      default:
      case MEMBER_STATE.waitApproval:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#DCDEE2', color: '#535A6C' }}>
            {i18n.t('waitApproval')}
          </Tag>
        )
      case MEMBER_STATE.approvaled:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#4AA4EA', color: '#fff' }}>
            {i18n.t('approvaled')}
          </Tag>
        )
      case MEMBER_FREEZE.normal:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#4AA4EA', color: '#fff' }}>
            {i18n.t('normal')}
          </Tag>
        )
      case MEMBER_FREEZE.frozen:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#E7716D', color: '#fff' }}>
            {i18n.t('frozen')}
          </Tag>
        )
    }
  }

  const agentStateIcon = (state: number, width = 'w-full') => {
    switch (state) {
      default:
      case AGENT_STATE.waitApproval:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#DCDEE2', color: '#535A6C' }}>
            {i18n.t('waitApproval')}
          </Tag>
        )
      case AGENT_STATE.normal:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#4AA4EA', color: '#fff' }}>
            {i18n.t('normal')}
          </Tag>
        )
      case AGENT_STATE.frozen:
        return (
          <Tag
            className={`${width} border-rd-02 border-none center`}
            style={{ background: '#E7716D', color: '#fff' }}>
            {i18n.t('frozen')}
          </Tag>
        )
    }
  }

  const AgentWithdrawState = (type: number, date?: string, admin?: string) => {
    switch (type) {
      // color-withdraw-padding-review
      case AGENT_WITHDRAW_STATE.pendingDistribution: default:
        return <Tag className='color-withdraw-padding' style={{ cursor: date ? 'pointer' : '' }}>
          {`${date} ${i18n.t(AGENT_WITHDRAW_STATE[type])}`}
        </Tag>
      case AGENT_WITHDRAW_STATE.distributing:
        return <Tag className='color-withdraw-reviewing'>
          {`${date} ${i18n.t('from')} ${admin || 'System'} ${i18n.t(AGENT_WITHDRAW_STATE[type])}`}
        </Tag>
      case AGENT_WITHDRAW_STATE.dispatched:
        return <Tag className='color-withdraw-pass' style={{ cursor: 'pointer' }}>
          {i18n.t(AGENT_WITHDRAW_STATE[type])}
        </Tag>
    }
  }

  return {
    MemberStateTag: (state: number, width?: string) => memberStateIcon(state, width),
    AgentStateTag: (state: number, width?: string) => agentStateIcon(state, width),
    WithdrawStateTag: (type: number, date?: string, admin?: string) => AgentWithdrawState(type, date, admin),
    StatusTag: (isOnline: boolean) => <Image src={isOnline ? online : offline} preview={false} />,
  };
};

export default useIcon;
