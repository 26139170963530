import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Space, Spin, Tag, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { TagTableDisplay } from 'components/account.component';
import { PopupAccounting, PopupExchange, ViewAgentSaList } from 'components/agent.component';
import { DatePickerCol, Export, LayoutNav } from 'components/layout.component';
import { PopupIPHistory } from 'components/member.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { SETTLE_CYCLE } from 'enum/promotion';
import { AGENT_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import useIcon from 'hooks/icon.hook';
import i18n from 'i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { $get } from 'services';
import { setSearchHistory } from 'slice/searchSlice';
import { RootType } from 'store/configureStore';
import * as common from 'utils/common';
import {
  toFormatNumber as $f,
  convertedToPercentage01 as $g,
  agentLevelWord,
  enumToOptions,
  timeL2S, timeS2L,
} from "utils/common";

// 總代

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewAgentSaList = useRef<any>();

  // 存搜尋聖經
  const searchAgent = useSelector((state: RootType) => state.Search.agent);

  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [reset, setReset] = useState<boolean>(false);
  const [form] = Form.useForm();

  // 標籤列表
  const { data: TagList } = $get({ url: 'api/tag/list' });

  const defaultDate = '1';
  const [timeMode, setTimeMode] = useState<any>(defaultDate);
  const [agentSaParam, setAgentSaParam] = useState<any>({
    PageIndex: 1,
    PageSize: 10,
    CreateDateStartTime: timeL2S(date[0]),
    CreateDateEndTime: timeL2S(date[1]),
  })
  const [findAll, setFindAll] = useState(false);
  const { data: agentSaData, mutate: mutateSa, isValidating } = $get({
    url: 'api/agent/sa/list',
    params: searchAgent || agentSaParam
  })
  // 子代小標籤
  const { data: agentList, mutate: mutateAg, isValidating: allValidating } = $get({
    url: 'api/agent/list',
    params: {
      ...(searchAgent || agentSaParam),
      PageSize: 999
    },
    allow: !!findAll
  })
  const search = (formData: any) => {
    // const timeMode = form.getFieldValue('TimeMode') || defaultDate;
    const type = timeMode === defaultDate ? 'Create' : 'Login';
    const send: any = {
      Account: formData.Account,
      RefCode: formData.RefCode,
      Status: formData.Status,
      PageIndex: 1,
      PageSize: agentSaParam.PageSize,
    }
    if (timeMode !== '3') {
      send[`${type}DateStartTime`] = timeL2S(date[0])
      send[`${type}DateEndTime`] = timeL2S(date[1])
    }
    setAgentSaParam(send);
    dispatch(setSearchHistory({ agent: send }));
    mutateAll();
    viewAgentSaList.current?.refresh();
    const condition1 = [AGENT_STATE.frozen, AGENT_STATE.waitApproval].includes(formData.Status)
    const condition2 = formData.Account && formData.Account !== ''
    const condition3 = formData.RefCode && formData.RefCode !== ''
    setFindAll(condition1 || condition2 || condition3)
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setAgentSaParam({
      ...agentSaParam,
      PageSize: 10,
      PageIndex: 1,
    })
    setTimeMode(defaultDate);
  }

  const handlePageChange = (pagination: any) => {
    const param = searchAgent || agentSaParam
    setAgentSaParam({ ...param, PageIndex: pagination.current, PageSize: pagination.pageSize })
    dispatch(setSearchHistory({ agent: { ...param, PageIndex: pagination.current, PageSize: pagination.pageSize } }));
  }

  const openAgent = (item: any) => {
    viewAgentSaList.current?.openAgent(item);
  }

  const mutateAll = () => {
    mutateSa();
    mutateAg();
  }

  // 子視窗
  const [agentId, setAgentId] = useState<number | null>(null);
  const [agentAccount, setAgentAccount] = useState<string | null>(null);
  const [agentLevel, setAgentLevel] = useState<number | null>(null);

  const [isOpenIPHistory, setOpenIPHistory] = useState(false);
  const [isOpenAccounting, setOpenAccounting] = useState(false);
  const [isOpenExchange, setOpenExchange] = useState(false);

  const icon = useIcon();

  // 代理列表返水
  const [agid, setAgid] = useState(0);
  const { data: rebate, isValidating: rebateValidating } = $get({
    url: 'api/agentoperationparams/one',
    params: { AgentId: agid },
    allow: !!agid
  })
  const updateAgid = (open: boolean, AGId: number) => {
    if (open) setAgid(AGId)
  }

  const [columns, setColumns] = useState<any>([]);
  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked
        return true
      }
    })
    setColumns([...columns]);
  };

  useEffect(() => {
    if (init) {
      setColumns([
        {
          dataIndex: 'Account',
          title: i18n.t('account'),
          width: 120,
          render: (_: any, record: Agent) => {
            const di = record.AGsId.split('|').length - 1;
            const word = common.agentLevelWord(di);
            return `${word}／${record.Account}`
          }
        },
        {
          dataIndex: 'TagsId',
          title: i18n.t('agentTag'),
          width: 120,
          render: (val: any, record: Agent) => <TagTableDisplay ids={val || []} list={TagList} />
        },
        {
          dataIndex: 'RefCode',
          title: i18n.t('promotionCode'),
          width: 80,
        },
        {
          dataIndex: 'DownlineAgentCount',
          title: i18n.t('teamAgentCount'),
          className: 'text-right',
          width: 80,
          render: (_: any, record: Agent) => (
            <Tooltip
              placement="bottom"
              title={`${i18n.t('directly')}: ${record?.DownlineAgentCount || 0} + ${i18n.t('subordinate')}: ${record?.InDownlineAgentCount || 0}`}
            >
              <div className='h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                {$f((record?.DownlineAgentCount || 0) + (record?.InDownlineAgentCount || 0), 0)}
              </div>
            </Tooltip>
          )
        },
        {
          dataIndex: 'DownlineMemberCount',
          title: i18n.t('teamMemberCount'),
          className: 'text-right',
          width: 80,
          render: (_: any, record: Agent) => (
            <Tooltip
              placement="bottom"
              title={`${i18n.t('directly')}: ${record?.DownlineMemberCount || 0} + ${i18n.t('subordinate')}: ${record?.InDownlineMemberCount || 0}`}
            >
              <div className='h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                {$f((record?.DownlineMemberCount || 0) + (record?.InDownlineMemberCount || 0), 0)}
              </div>
            </Tooltip>
          )
        },
        {
          dataIndex: 'Balance',
          title: i18n.t('walletBalance'),
          className: 'text-right',
          width: 120,
          render: (_: any, record: Agent) => $f(record.Balance)
        },
        {
          dataIndex: 'Status',
          title: i18n.t('status'),
          width: 100,
          render: (val: any) => (
            <Row justify="center" align="middle">
              <Col>{icon.AgentStateTag(val)}</Col>
            </Row>
          )
        },
        {
          dataIndex: 'LastLoginDate',
          title: i18n.t('lastLoginTime'),
          width: 120,
          render: (val: any, { Account }: any) => val && Account !== 'company' ? timeS2L(val) : '-'
        },
        {
          dataIndex: 'CreateDate',
          title: i18n.t('createTime'),
          width: 120,
          render: (val: any) => timeS2L(val) || '-'
        },
        {
          dataIndex: 'IP',
          title: i18n.t('IP'),
          width: 120,
          render: (_: any, record: Agent) => (
            record.IP ? <Button type="link" onClick={() => {
              setAgentId(record.Id);
              setOpenIPHistory(true);
            }}>{record.IP}</Button> : '-'
          )
        },
        {
          dataIndex: 'CommissionSettleCycle',
          title: i18n.t('rebate'),
          width: 40,
          render: (_: any, record: Agent) => {
            const title = <Spin spinning={rebateValidating}>
              <div>{`${i18n.t('EGAMES')}: ${$g(rebate?.Data.EGamesCommissionRate || 0)}%`}</div>
              <div>{`${i18n.t('LIVE')}: ${$g(rebate?.Data.LiveCommissionRate || 0)}%`}</div>
              <div>{`${i18n.t('SPORTS')}: ${$g(rebate?.Data.SportsCommissionRate || 0)}%`}</div>
              <div>{`${i18n.t('ESPORTS')}: ${$g(rebate?.Data.ESportsCommissionRate || 0)}%`}</div>
              <div>{`${i18n.t('POKER')}: ${$g(rebate?.Data.PokerCommissionRate || 0)}%`}</div>
              <div>{`${i18n.t('FISHING')}: ${$g(rebate?.Data.FishingCommissionRate || 0)}%`}</div>
              <div>{`${i18n.t('COCKFIGHT')}: ${$g(rebate?.Data.CockfightCommissionRate || 0)}%`}</div>
              <div>{`${i18n.t('LOTTERY')}: ${$g(rebate?.Data.LotteryCommissionRate || 0)}%`}</div>
              <div>{`${i18n.t('SLOT')}: ${$g(rebate?.Data.SlotCommissionRate || 0)}%`}</div>
            </Spin>
            return (
              <Tooltip placement="bottom" title={title} onOpenChange={(o) => updateAgid(o, record.Id)}>
                <div className='h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {i18n.t(SETTLE_CYCLE[record.CommissionSettleCycle])}
                </div>
              </Tooltip>
            )
          }
        },
        {
          dataIndex: 'RevenueShareSettleCycle',
          title: i18n.t('revenue'),
          width: 40,
          render: (_: any, record: Agent) => {
            const title = <Spin spinning={rebateValidating}>
              <div>{`${i18n.t('EGAMES')}: ${$g(rebate?.Data.EGamesRevenueShareRate || 0)}%`}</div>
              <div>{`${i18n.t('LIVE')}: ${$g(rebate?.Data.LiveRevenueShareRate || 0)}%`}</div>
              <div>{`${i18n.t('SPORTS')}: ${$g(rebate?.Data.SportsRevenueShareRate || 0)}%`}</div>
              <div>{`${i18n.t('ESPORTS')}: ${$g(rebate?.Data.ESportsRevenueShareRate || 0)}%`}</div>
              <div>{`${i18n.t('POKER')}: ${$g(rebate?.Data.PokerRevenueShareRate || 0)}%`}</div>
              <div>{`${i18n.t('FISHING')}: ${$g(rebate?.Data.FishingRevenueShareRate || 0)}%`}</div>
              <div>{`${i18n.t('COCKFIGHT')}: ${$g(rebate?.Data.CockfightRevenueShareRate || 0)}%`}</div>
              <div>{`${i18n.t('LOTTERY')}: ${$g(rebate?.Data.LotteryRevenueShareRate || 0)}%`}</div>
              <div>{`${i18n.t('SLOT')}: ${$g(rebate?.Data.SlotRevenueShareRate || 0)}%`}</div>
            </Spin>
            return (
              <Tooltip placement="bottom" title={title} onOpenChange={(o) => updateAgid(o, record.Id)}>
                <div className='h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {i18n.t(SETTLE_CYCLE[record.RevenueShareSettleCycle])}
                </div>
              </Tooltip>
            )
          }
        },
        {
          dataIndex: 'Operation',
          title: i18n.t('operation'),
          width: 240,
          render: (_: any, record: Agent) => {
            return (
              <>
                {
                  $p('40201') &&
                  <Button type="link" onClick={() => navigate(`/agent/edit/${record.Id}`)}>{i18n.t('edit')}</Button>
                }
                {
                 ($p('40301') && $p('40901')) &&
                  <Button type="link" onClick={() => navigate(`/agent/settle/${record.Id}`)}>{i18n.t('settlementReports')}</Button>
                }
                {
                  $p('40401') &&
                  <Button type="link" onClick={() => navigate(`/agent/daily/${record.Id}`)}>{i18n.t('dailyReport')}</Button>
                }
                {
                  $p('40103') &&
                  <Button type="link" onClick={() => {
                    setAgentAccount(record.Account);
                    setOpenAccounting(true);
                  }}>{i18n.t('accounting')}</Button>
                }
                {
                  $p('40205') &&
                  <Button type="link" onClick={() => navigate(`/agent/add-agent/${record.Id}`)}>{i18n.t('addAgent')}</Button>
                }
                {
                 ($p('40204') && record.Level !== 0) &&
                  <Button type="link" onClick={() => {
                    setAgentId(record.Id);
                    setAgentAccount(record.Account);
                    setAgentLevel(record.Level);
                    setOpenExchange(true);
                  }}>
                    {i18n.t('currencyExchange')}
                  </Button>
                }
              </>
            )
          },
        },
      ].map(item => ({ ...item, key: item.dataIndex, show: true })))
    }
  }, [init, rebateValidating, TagList]);

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={search} initialValues={{
          TimeMode: defaultDate
        }}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="Account">
                    <Input placeholder={`${i18n.t('account')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="RefCode">
                    <Input placeholder={`${i18n.t('promotionCode')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="Status">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('status')}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...enumToOptions(AGENT_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      className="w-full"
                      options={[
                        { value: '1', label: `${i18n.t('createTime')}` },
                        { value: '2', label: `${i18n.t('lastLoginTime')}` },
                        { value: '3', label: `${i18n.t('noTimeLimit')}` },
                      ]}
                      onChange={setTimeMode}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset} disabled={timeMode === '3'} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {/* 找子代小按鈕 */}
              {findAll &&
                <Spin spinning={allValidating}>
                  <Space size={[0, 8]} wrap>
                    {agentList?.Data.map((item: any) => {
                      const di = item.AGsId.split('|').length - 1;
                      const word = agentLevelWord(di);
                      return <Tag key={item.Id} color="#87d068" onClick={() => openAgent(item)} style={{ cursor: 'pointer' }}>
                        {`${word}／${item.Account}`}
                      </Tag>
                    })}
                  </Space>
                </Spin>
              }
            </Col>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    {
                      $p('40202') &&
                      <Col>
                        <Button type="primary" onClick={() => navigate('/agent/add')}>{i18n.t('add')}</Button>
                      </Col>
                    }
                    {
                      $p('40102') &&
                      <Col>
                        <Export url={'/agent/sa/list/export'} param={{
                          ...agentSaParam, PageSize: agentSaData && agentSaData.TotalRecord, PageIndex: 1
                        }} sheetName={i18n.t('generalAgentList')} columns={ExcelColumns.AgentList} otherData={TagList} />
                      </Col>
                    }
                  </Row>
                </Col>
                <Col>
                  {/* 欄位顯示 */}
                  <Popover
                    placement="bottomRight"
                    trigger="click"
                    content={
                      <div className="w-full">
                        {columns.filter((item: any) => item.dataIndex !== 'Account' && item.dataIndex !== 'Operation')
                          .map((item: any) =>
                          <div key={item.key}>
                            <Checkbox
                              checked={item.show}
                              onChange={e => updateColumnShow(item.dataIndex, e.target.checked)}
                            >
                              {item.title}
                            </Checkbox>
                          </div>
                        )}
                      </div>
                    }
                  >
                    <Button><DatabaseFilled /></Button>
                  </Popover>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <ViewAgentSaList
                refs={viewAgentSaList} columns={columns}
                saList={agentSaData} isValidating={isValidating} setPage={handlePageChange}
                pageSize={searchAgent?.PageSize || agentSaParam?.PageSize}
                pageIndex={searchAgent?.PageIndex || agentSaParam?.PageIndex}
              />
            </Col>
          </Row>

          <PopupIPHistory isOpen={isOpenIPHistory} close={() => setOpenIPHistory(false)} AgentId={agentId} />
          <PopupAccounting isOpen={isOpenAccounting} close={() => setOpenAccounting(false)} AgentAccount={agentAccount} />
          <PopupExchange isOpen={isOpenExchange} close={() => setOpenExchange(false)} AgentId={agentId}
            Account={agentAccount} Level={agentLevel} refresh={viewAgentSaList.current?.refresh} />
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;