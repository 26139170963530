import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE_SEO } from 'constants/response';
import { SEO_PAGE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $post } from 'services';
import { enumToOptions } from 'utils/common';

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [isDone, setIsDone] = useState(false);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/seo/create',
      send: {
        Type: formData.type,
        Title: formData.title,
        KeyWord: formData.keyword,
        Description: formData.description,
        HeadMeta: formData.meta,
        HeadLink: formData.link,
        HtmlContent: formData.htmlContent
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        navigate('/seo')
      },
      resCode: RESPONSE_CODE_SEO
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Form.Item name="type" className="w-26" label={i18n.t('page')} required
                    rules={[{ required: true }]}>
                    <Select
                      disabled={isDone}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(SEO_PAGE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Row align="middle" gutter={[12, 12]}>
                    {
                      isDone
                        ?
                        <Col>
                          <Button onClick={() => navigate('/seo')}>{i18n.t('close')}</Button>
                        </Col>
                        :
                        <>
                          <Col>
                            <Button onClick={() => navigate('/seo')}>{i18n.t('cancel')}</Button>
                          </Col>
                          <Col>
                            <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                          </Col>
                        </>
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="title" className="w-26" label={i18n.t('title')}
                rules={[{ type: 'string', max: 20 }]}>
                <Input disabled={isDone} placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="keyword" className="w-26" label={i18n.t('keyword')}
                rules={[{ type: 'string', max: 20 }]}>
                <Input disabled={isDone} placeholder={`${i18n.t('pleaseUseCommaToSeparateTheItems')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="description" className="w-26" label={i18n.t('description')}
                rules={[{ type: 'string', max: 100 }]}>
                <Input.TextArea
                  disabled={isDone}
                  size="middle"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  placeholder={`${i18n.t('inputData')}`}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="meta" className="w-26" label={i18n.t('head meta')}>
                <Input disabled={isDone} placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="link" className="w-26" label={i18n.t('head link')}>
                <Input disabled={isDone} placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="htmlContent" className="w-full" label={i18n.t('htmlContent')}
                rules={[{ type: 'string', max: 100 }]}>
                <TextArea autoSize={{ minRows: 3, maxRows: 3 }} placeholder={`${i18n.t('inputData')}${i18n.t('description')}`} 
                  maxLength={100} showCount />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;