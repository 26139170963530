import { Button, Col, Form, Row, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import { RootType } from 'store/configureStore';
import * as common from 'utils/common';

// 三方入款記錄

interface SearchParams {
  MerchantId: number;
  StartDate: string;
  EndDate: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const { text } = useSelector((state: RootType) => state.Search);
  const { id } = useParams<{ id: string | undefined }>();
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState<SearchParams>({
    MerchantId: Number(id),
    StartDate: common.timeL2S(date[0]),
    EndDate: common.timeL2S(date[1]),
  });
  const { data: DepositThird, isValidating, mutate } = $get({
    url: 'api/depositmerchant/third/list',
    params: {
      ...params,
      MerchantId: id,
      PageIndex: page[0],
      PageSize: page[1],
    }
  })
  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        StartDate: common.timeL2S(date[0]),
        EndDate: common.timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
  }

  return (
    <div id="container">
      <LayoutNav account={text.bankName} />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <div className="size-18">{text.bankDisplayName}</div>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  dataSource={
                    DepositThird ?
                      [{
                        key: 1,
                        totalCount: DepositThird.TotalCount,
                        totalDeposit: DepositThird.TotalAmount
                      }] : []
                  }
                  columns={[
                    {
                      title: i18n.t('totalCount'),
                      dataIndex: 'totalCount',
                      align: 'right',
                      width: '50%',
                    },
                    {
                      title: i18n.t('totalDepositSum'),
                      dataIndex: 'totalDepositSum',
                      align: 'right',
                      width: '50%',
                      render: (_, { totalDeposit }) => common.toFormatNumber(totalDeposit)
                    },
                  ]}
                  pagination={false}
                />
              </Spin>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  dataSource={
                    DepositThird ?
                      DepositThird.Data.map((item: DepositThird) => (
                        {
                          key: item.DepositMerchantId,
                          date: item.CreateDate,
                          count: item.TotalCount,
                          amount: item.TotalAmount
                        }
                      )) : []
                  }
                  columns={[
                    {
                      title: i18n.t('date'),
                      dataIndex: 'date',
                    },
                    {
                      title: i18n.t('count'),
                      dataIndex: 'count',
                      align: 'right',
                    },
                    {
                      title: i18n.t('amount'),
                      dataIndex: 'amount',
                      align: 'right',
                      render: (_, { amount }: any) => common.toFormatNumber(amount)
                    },
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={0} setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;