import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Spin, Table, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { DateSelector, LayoutNav, UploadImage } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { CKEditorComp } from 'components/promotion.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { PROMOTION_STATE, PROMOTION_TYPE } from 'enum/promotion';
import { PROMOTION_CYCLE, WHETHER } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { convertedToPercentage01 as $g, convertedToPercentage02 as $p2n, enumToOptions, timeL2S, timeS2L, toFormatNumber, verify } from "utils/common";

// 編輯存款優惠

enum BONUS_TYPE {
  fixedBonus = 1,
  proportionallyBonus = 2
}

const BONUS_TYPE_WORD: any = {
  1: 'Fixed',
  2: 'Part'
}

const BONUS_TYPE_WORD_KEY: any = {
  Fixed: 1,
  Part: 2
}

enum CALCULATION {
  separateCalculation = 1,
  cumulativeCalculation = 2
}

const CALCULATION_WORD: any = {
  1: 'Single',
  2: 'Total'
}

const CALCULATION_WORD_KEY: any = {
  Single: 1,
  Total: 2
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState<string>();

  const [isPopUp, setIsPopUp] = useState<WHETHER>(WHETHER.none);
  const [period, setPeriod] = useState();
  const [isOneTime, setIsOneTime] = useState();
  const [isFirstDeposit, setIsFirstDeposit] = useState();
  const [type, setType] = useState(BONUS_TYPE.fixedBonus);

  const [depositList, setDepositList] = useState<any>(['item', 'item']);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    null
  ]);

  const { data, isValidating } = $get({
    url: 'api/bonus/info',
    params: { Id: id },
  })

  useEffect(() => {
    if (data?.Data) {
      const LevelsId = JSON.parse(data.Data.LevelsId);
      const BonusRuleSetting = JSON.parse(data.Data.BonusRuleSetting);

      const Settings: any = {}
      BonusRuleSetting.Rule.Settings.forEach((item: any, i: number) => {
        Settings[`Settings-${i}-DepositAmount`] = Number(item.DepositAmount);
        Settings[`Settings-${i}-AwardRatio`] = Number($g(item.AwardRatio));
        Settings[`Settings-${i}-ValidBetMulitple`] = Number(item.ValidBetMulitple);
      })
      setDepositList(Object.keys(BonusRuleSetting.Rule.Settings).map(item => 'item'));

      form.setFieldsValue({
        Page: PROMOTION_TYPE.storePromotions,
        Name: data.Data.Name,
        IsPopUp: data.Data.IsPopUp,
        IsRedirect: data.Data.IsRedirect,
        Period: data.Data.Period,
        IsOneTime: data.Data.IsOneTime,
        IsFirstDeposit: BonusRuleSetting.IsFirstDeposit,
        LevelsId,
        Type: BONUS_TYPE_WORD_KEY[BonusRuleSetting.Rule.Type],
        // 1.7版本先不觸發
        // CumulativeType: CALCULATION_WORD_KEY[BonusRuleSetting.CumulativeType],
        MaximumBonusAmount: BonusRuleSetting.MaximumBonusAmount,
        Apply: 1,
        Distribute: 1,
        Status: data.Data.Status,
        Position: data.Data.Position,
        Content: data.Data.Content,
        ...Settings
      })
      setImageData([
        {
          uid: data.Data.Photo,
          name: data.Data.Photo,
          url: data.Data.Photo,
        },
      ])
      setEditorContent(data.Data.Content);
      setDate([timeS2L(data.Data.StartDate), timeS2L(data.Data.EndDate), null]);

      setIsPopUp(data.Data.IsPopUp);
      setPeriod(data.Data.Period);
      setIsOneTime(data.Data.IsOneTime);
      setIsFirstDeposit( BonusRuleSetting.IsFirstDeposit);
      setType(BONUS_TYPE_WORD_KEY[BonusRuleSetting.Rule.Type]);
    }
  }, [data]);

  // 1.7版本先不觸發
  // const CumulativeType = (type === BONUS_TYPE.proportionallyBonus && isOneTime === WHETHER.none && isFirstDeposit === WHETHER.none && (period === PROMOTION_CYCLE.week || period === PROMOTION_CYCLE.day));

  const onFinish = (formData: any) => {
    const Settings: any = []

    if (formData.Type === BONUS_TYPE.fixedBonus) {
      Settings.push({
        DepositAmount: Number(formData[`Settings-${0}-DepositAmount`]),
        AwardRatio: Number(parseFloat($p2n(formData[`Settings-${0}-AwardRatio`]))),
        ValidBetMulitple: Number(formData[`Settings-${0}-ValidBetMulitple`])
      })
    } else {
      depositList.forEach((item: any, i: number) => {
        Settings.push({
          DepositAmount: Number(formData[`Settings-${i}-DepositAmount`]),
          AwardRatio: Number(parseFloat($p2n(formData[`Settings-${i}-AwardRatio`]))),
          ValidBetMulitple: Number(formData[`Settings-${i}-ValidBetMulitple`])
        })
      })
    }

    $post({
      url: 'api/bonus/deposit/update',
      send: {
        Id: id,
        Name: formData.Name,
        IsPopUp: formData.IsPopUp,
        IsRedirect: isPopUp ? formData.IsRedirect : 0,
        Period: formData.Period,
        IsOneTime: formData.IsOneTime,
        IsFirstDeposit: formData.IsFirstDeposit,
        LevelsId: formData.LevelsId,
        Type: BONUS_TYPE_WORD[formData.Type],
        // 1.7版本先不觸發
        // CumulativeType: CumulativeType ? CALCULATION_WORD[formData.CumulativeType] : null,
        CumulativeType:null,
        Settings,
        MaximumBonusAmount: Number(formData.MaximumBonusAmount),
        Status: formData.Status,
        Position: Number(formData.Position),
        StartDate: timeL2S(date[0] as any),
        EndDate: timeL2S(date[1] as any),
        Photo: imageData[0] ? [imageData[0].url] : [],
        Content: editorContent
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        navigate('/promotion');
      },
      resCode: RESPONSE_CODE_BONUS
    }, setLoading)
  }

  const pageChanged = (val: number) => {
    switch (val) {
      case PROMOTION_TYPE.otherPromotions:
        navigate('/promotion/add-other')
        break;
      case PROMOTION_TYPE.storePromotions:
        navigate('/promotion/add-store')
        break;
    }
  }

  const addDepositList = () => {
    if (depositList.length < 10) {
      setDepositList([...depositList, 'item']);
    }
  }

  const removeDepositList = (key: number) => {
    const updatedList = depositList.filter((item:any, index:any) => index !== key - 1);

    setDepositList(updatedList);

    const formData=  form.getFieldsValue()

    depositList.forEach((item: any, i: number) => {
      const actualIndex = i < key-1 ? i: i+1;

      form.setFieldValue(`Settings-${i}-DepositAmount`, isNaN(Number(formData[`Settings-${actualIndex}-DepositAmount`])) ? undefined : Number(formData[`Settings-${actualIndex}-DepositAmount`]));
      form.setFieldValue(`Settings-${i}-AwardRatio`, isNaN(Number(formData[`Settings-${actualIndex}-AwardRatio`])) ? undefined : Number(formData[`Settings-${actualIndex}-AwardRatio`]));
      form.setFieldValue(`Settings-${i}-ValidBetMulitple`, isNaN(Number(formData[`Settings-${actualIndex}-ValidBetMulitple`])) ? undefined : Number(formData[`Settings-${actualIndex}-ValidBetMulitple`]));
    })
  };

  // 不得小於上層 大於下層
  const validator: any = async (d: any, val: number, i: number, name: string, point: number) => {
    const _val: number = Number(val);
    const upperInputValue: number = Number(form.getFieldValue(`Settings-${i - 1}-${name}`));
    const downerInputValue: number = Number(form.getFieldValue(`Settings-${i + 1}-${name}`));
    const min: number = 0;

    // 需大於min
    if (_val <= min) return Promise.reject(() => 
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThan')}${toFormatNumber(min, point)}`}</p>);
    // 需大於上層
    else if (_val <= upperInputValue && (upperInputValue && upperInputValue !== 0)) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThan')}${toFormatNumber(upperInputValue, point)}`}</p>);
    // 需小於下層
    else if (_val >= downerInputValue && (downerInputValue && downerInputValue !== 0) && downerInputValue >= min) return Promise.reject(() => 
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThan')}${toFormatNumber(downerInputValue, point)}`}</p>);

    else return Promise.resolve();
  }

  const validateOther: any = async (i: number, name: string,) => {
    form.validateFields([`Settings-${i - 1}-${name}`, `Settings-${i + 1}-${name}`]);
  }

  useEffect(() => {
    if(type === BONUS_TYPE.fixedBonus){
      form.setFields([
        {
          name: `Settings-${0}-DepositAmount`,
          errors: [],
        },
        {
          name: `Settings-${0}-AwardRatio`,
          errors: [],
        }
      ]);
    }
  }, [type]);

  const isEditPage = true;

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Spin spinning={isValidating}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={[12, 12]}>

              {/* 類別與確認 */}
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <Form.Item name="Page" className="w-12" label={i18n.t('promotionCategory')} rules={[{ required: true }]}>
                      <Select options={enumToOptions(PROMOTION_TYPE)} onChange={pageChanged} disabled />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Space size="middle">
                      <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                      <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
                    </Space>
                  </Col>
                </Row>
              </Col>

              {/* 名稱 */}
              <Col span={24}>
                <Form.Item name="Name" label={i18n.t('promotionName')} rules={[
                  { required: true },
                  { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }]}>
                  <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="IsPopUp" className="w-12" label={i18n.t('popupDisplay')} rules={[{ required: true }]}>
                      <Select
                        onChange={(e) => {
                          setIsPopUp(e);
                          form.setFieldValue('IsRedirect', WHETHER.none);
                        }}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(WHETHER)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="IsRedirect" className="w-12" label={i18n.t('redirectToPromotionPage')} rules={[{ required: !!isPopUp }]}>
                      <Select
                        disabled={!isPopUp}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(WHETHER)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* 週期 */}
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="Period" className="w-12" label={i18n.t('cycle')} rules={[{ required: true }]}>
                      <Select
                        disabled={isEditPage}
                        onChange={setPeriod}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(PROMOTION_CYCLE)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="IsOneTime" className="w-12" label={i18n.t('oneTimePromotion')} rules={[{ required: true }]}>
                      <Select
                        disabled={isEditPage}
                        onChange={setIsOneTime}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(WHETHER)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="IsFirstDeposit" className="w-12" label={i18n.t('isFirstDeposit')} rules={[{ required: true }]}>
                      <Select
                        disabled={isEditPage}
                        onChange={setIsFirstDeposit}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(WHETHER)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* 適用會員等级 */}
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="LevelsId" className="w-12" label={
                      <div>
                        <div>{i18n.t('applicableMemberLevel')}</div>
                        <div className="color-03">{`(${i18n.t('selectableMultiple')})`}</div>
                      </div>
                    }>
                      <MemberLevelSelect mode="multiple" name="LevelsId" form={form} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* 優惠條件 */}
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="Type" className="w-12" label={i18n.t('preferentialTerms')} rules={[{ required: true }]}>
                      <Select
                        onChange={setType}
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(BONUS_TYPE)}
                      />
                    </Form.Item>
                  </Col>
                  {/*1.7版本先不觸發*/}
                  {/* 存款金額計算方式 */}
                  {/*{CumulativeType &&*/}
                  {/*<Col>*/}
                  {/*  <Form.Item name="CumulativeType" className="w-12" label={i18n.t('depositCalculationMethod')} rules={[{ required: true }]}>*/}
                  {/*    <Select*/}
                  {/*      placeholder={i18n.t('pleaseSelect')}*/}
                  {/*      options={enumToOptions(CALCULATION)}*/}
                  {/*    />*/}
                  {/*  </Form.Item>*/}
                  {/*</Col>}*/}
                </Row>
              </Col>
              {/* 列表 */}
              <Col span={14}>
                <Form.Item name="Settings">
                  <Table
                    size="small"
                    dataSource={
                      depositList
                        .concat(type === BONUS_TYPE.proportionallyBonus && depositList.length <10 ? 'add' : [])
                        .map((item: any, i: number) => ({ key: i + 1, item }))
                        .filter((item: any, i: number) => type === BONUS_TYPE.proportionallyBonus ? true : i === 0)
                    }
                    columns={[
                      {
                        align: 'center',
                        dataIndex: 'key'
                      },
                      {
                        title: i18n.t('depositAmounts'),
                        render: (_, { item }: any, i) => (
                          item === 'item' 
                          ? <Form.Item name={`Settings-${i}-DepositAmount`} rules={ type === BONUS_TYPE.fixedBonus
                            ? [...verify({ point: 4, zero: false })]
                            : [...verify({ point: 4, zero: false }), { validator: (d, val) => validator(d, val, i, 'DepositAmount', 4) }]
                          }>
                            <Input type="number" placeholder={`${i18n.t('inputData')}`} addonBefore="≧" onChange={() => validateOther(i, 'DepositAmount')} />
                          </Form.Item> : ''
                        ),
                      },
                      {
                        title: `${i18n.t('giftRatio')} %`,
                        render: (_, { item }, i) => (
                          item === 'item' 
                          ? <Form.Item name={`Settings-${i}-AwardRatio`} rules={ type === BONUS_TYPE.fixedBonus
                            ? [...verify({ point: 2, zero: false })]
                            : [...verify({ point: 2, zero: false }), { validator: (d, val) => validator(d, val, i, 'AwardRatio', 2) }]
                          }>
                            <Input type="number" placeholder={`${i18n.t('inputData')}`} onChange={() => validateOther(i, 'AwardRatio')} />
                          </Form.Item> : ''
                        ),
                      },
                      {
                        title: i18n.t('rolloverRate'),
                        render: (_, { item }: any, i) => (
                          item === 'item' ? 
                          <Form.Item name={`Settings-${i}-ValidBetMulitple`} rules={
                            verify({ point: 0, zero: false }
                          )}>
                            <Input type="number" placeholder={`${i18n.t('inputData')}`} />
                          </Form.Item> : ''
                        ),
                      },
                      {
                        title: i18n.t('operation'),
                        hidden: type === BONUS_TYPE.fixedBonus,
                        render: (_, { item, key }: any, i) => (
                          item === 'add' ? <Button className="size-12" type="link" onClick={addDepositList}>
                          {i18n.t('add')}
                        </Button> : (
                            i >= 2 && i <= 9 ? <Button className="size-12" type="link" onClick={()=>removeDepositList(key)}>
                              {i18n.t('delete')}
                            </Button> : null
                          )
                        ),
                      },
                    ]}
                    pagination={false}
                    bordered />
                </Form.Item>
              </Col>
              {/* 最高優惠金額 */}
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="MaximumBonusAmount" label={
                      <Row gutter={5}>
                        <Col>{i18n.t('maximumDiscountAmount')}</Col>
                        <Col>
                          <Tooltip placement="top" title={i18n.t('0MeansNoLimitation')}>
                            <InfoCircleFilled />
                          </Tooltip>
                        </Col>
                      </Row>
                    } rules={verify({ point: 4, max: 999999999.9999 })}>
                      <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* 申請方式 */}
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="Apply" className="w-12" label={i18n.t('applicationMethod')} rules={[{ required: true }]}>
                      <Select
                        disabled
                        placeholder={i18n.t('pleaseSelect')}
                        options={[
                          { value: 1, label: `${i18n.t('memberManualApplication')}` },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="Distribute" className="w-12" label={i18n.t('distributionMethod')} rules={[{ required: true }]}>
                      <Select
                        disabled
                        placeholder={i18n.t('pleaseSelect')}
                        options={[
                          { value: 1, label: `${i18n.t('manualDispatch')}` },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Form.Item name="Status" className="w-12" label={i18n.t('status')} rules={[{ required: true }]}>
                      <Select
                        placeholder={i18n.t('pleaseSelect')}
                        options={enumToOptions(PROMOTION_STATE)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="Position" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                      <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="Date" label={`${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`} rules={[{ required: true }]}>
                      <DateSelector form={form} name="Date" date={date} setDate={setDate} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* 上傳封面圖片 */}
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Form.Item name="Photo" label={i18n.t('image')} 
                      rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                      <UploadImage
                        name="Photo"
                        url={'/upload/images/public/bonus'}
                        imageData={imageData}
                        setImageData={setImageData}
                        form={form}
                        w={1920}
                        h={560}
                      />
                    </Form.Item>
                    <div className="color-03 size-12">
                      {i18n.t('onlyJpgOrPngFiles')}
                      <span className="require">{i18n.t('imageMobileVersionWidth1920pxHeight560px')}</span>
                      {i18n.t('allowedAndSizeNotExceed500kb')}
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* 內文編輯器 */}
              <Col span={24}>
                <Form.Item name="Content" label={i18n.t('internalContent')} rules={[{ required: true }]}>
                  <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'Content'} image />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Content >
    </div >
  );
};

export default PageMain;