// 2024/04/25 核對過
export enum OPERATION_TYPE {
  loginRecords = 1001,
  createAccount = 1002,
  updateAccount = 1003,
  createActpermission = 1004,
  updateActpermission = 1005,
  deleteActpermission = 1006,
  createMember = 1007,
  updateMemberOrUpdateMemberBirth = 1008,
  updateMemberStatus = 1009,
  manualVerifyMember = 1010,
  resetMemberVerification = 1011,
  memberSwitchedToOtherAgentOffline = 1012,
  agentSwitchedToOtherAgentOffline = 1013,
  modifyMemberContactInfo = 1014,
  updateAccountPassword = 1015,
  resetAccountPassword = 1016,
  createRealNameVerificationOrder = 1020,
  createMemberLevel = 1023,
  updateMemberLevel = 1024,
  deleteMemberLevel = 1025,
  createAccountLabel = 1026,
  updateAccountLabel = 1027,
  backendOperationChangeWithdrawalMethodData = 1030,
  backendOperationDeleteWithdrawalMethod = 1031,
  memberCreateWithdrawalMethod = 1032,
  agentCreateWithdrawalMethod = 1033,
  transferBucketUpdateAgentOperatingParameters = 1034,
  updateAgentOperatingParameters = 1035,
  transferBucketUpdateMemberOperatingParameters = 1036,
  updateMemberOperatingParameters = 1037,
  updateMemberCashbackStatus = 1038,
  updateAgentMembershipRebate = 1039,
  agentSelfVerification = 1040,
  resetMemberPassword = 1041,
  changePassword = 1042,
  executiveMemberLevelUp = 1050,
  membersReceiveLevelBonuses = 1051,
  transferBucketUpdateAgentOperatingParameter = 1055,
  createPromoCodeForAgentsOnly = 1060,
  updatePromoCodeForAgentsOnly = 1061,
  createMemberEmail = 1070,
  updateMasterAgentAgentApproveOrRejectRealNameVerification = 2001,
  updateMasterAgentmanualVerifyAgentReviewWithdrawalMethod = 2002,
  resetAgentVerification = 2003,
  createMasterAgent = 2004,
  addAgent = 2005,
  agentTransfer = 2006,
  createAdjustmentOrder = 3001,
  passAdjustmentOrder = 3002,
  rejectAdjustmentOrder = 3003,
  updateTransactionChannel = 4001,
  updateDepositChannel = 4002,
  updateWithdrawalChannel = 4003,
  updateSystemLanguageSetting = 4005,
  updateSystemSettingParameters = 4006,
  createDepositOrder = 6001,
  depositOrderSuccessfulRequestPaymentProvider = 6002,
  paymentProviderResponseSuccess = 6004,
  paymentProviderResponseFailure = 6005,
  paymentProviderAsyncNotificationSuccessResolveDepositOrder = 6006,
  paymentProviderAsyncResponseFailure = 6007,
  financialStaffManualRejectDepositOrder = 6008,
  financialStaffManualApproveDepositOrder = 6009,
  createWithdrawalOrderForMemberOrAgent = 6011,
  customerServiceApproveWithdrawalOrder = 6012,
  customerServiceRejectWithdrawalOrder = 6013,
  customerServiceSupervisorApproveWithdrawalOrder = 6014,
  customerServiceSupervisorRejectWithdrawalOrder = 6015,
  financialAuditApproveWithdrawalOrder = 6016,
  financialAuditRejectWithdrawalOrder = 6017,
  withdrawalOrderRequestPaymentProviderResponseFailure = 6018,
  withdrawalOrderRequestPaymentProviderResponseSuccess = 6019,
  withdrawalOrderPaymentProviderAsyncResponseSuccess = 6020,
  withdrawalOrderPaymentProviderAsyncResponseFailure = 6021,
  createOfflineDeposit = 6024,
  updateOfflineDeposit = 6025,
  deleteOfflineDeposit = 6026,
  backendFinancialStaffExecuteTaskForThisWithdrawalOrder = 6027,
  backendFinancialStaffAbortTaskForThisWithdrawalOrder = 6028,
  createOfflineWithdrawal = 6029,
  updateOfflineWithdrawal = 6030,
  deleteOfflineWithdrawal = 6031,
  createPromotion = 7001,
  updatePromotion = 7002,
  deletePromotion = 7003,
  createFrontendMaterial = 7004,
  updateFrontendMaterial = 7005,
  deleteFrontendMaterial = 7006,
  createPromotionOrder = 7007,
  reviewPromotionOrder = 7008,
  claimPromotionOrder = 7009,
  updateGameCategorySetting = 7101,
  updateGameProviderSetting = 7102,
  updateGameSetting = 7103,
  memberToggleGameProvider = 7104,
  memberToggleAllGameProviders = 7105,
  adminUpdateGameProviderStatus = 7107,
  adminUpdateGameProviderOpenStatus = 7108,
  createGameProviderMaintenanceSetting = 7109,
  updateGameProviderMaintenanceSetting = 7110,
  deleteGameProviderMaintenanceSetting = 7111,
  createMemberLevelPromotionDistributionOrder = 7112,
  reservedFieldGameProviderRebateSetting = 7113,
  updateGameProviderRebateSetting = 7114,
  createSEOSetting = 7201,
  updateSEOSetting = 7202,
  deleteSEOSetting = 7203,
  updateFrontendPagePopupSetting = 7204,
  createSiteData = 7301,
  updateSiteData = 7302,
  frontendPageCreateMainTitle = 7305,
  frontendPageEditMainTitle = 7306,
  frontendPageDeleteMainTitle = 7307,
  frontendPageCreateSubTitle = 7308,
  frontendPageEditSubTitle = 7309,
  frontendPageDeleteSubTitle = 7310,
  adminCreateUploadLicense = 7401,
  adminUpdateLicense = 7402,
  adminClearLicense = 7403,
  createCustomerServiceChatroom = 7501,
  updateCustomerServiceChatroom = 7502,
  createCustomerServiceChatroomMessage = 7511,
  updateCustomerServiceChatroomMessage = 7512,
  createPrivateChatroom = 7521,
  updatePrivateChatroom = 7522,
  createPrivateChatroomMessage = 7531,
  updatePrivateChatroomMessage = 7532,
  createChatroomAccount = 7541,
  updateChatroomAccount = 7542,
  createQuickReplyType = 7551,
  updateQuickReplyType = 7552,
  createQuickReply = 7561,
  updateQuickReply = 7562,
  clearQuickReply = 7563,
  updateChatroomSettings = 7572,
  reviewSettlementOrder = 8001,
  addCashbackOrder = 9001,
  lockOrUnlockCashbackOrder = 9002,
  approveOrRejectCashbackOrder = 9003,
  batchApproveCashbackOrders = 9004,
  addCommissionOrder = 9006,
  approveOrRejectCommissionOrder = 9007,
  batchApproveCommissionOrders = 9008,
  addSystemBlacklistIP = 10281,
  deleteSystemBlacklistIP = 10282,
  updateSystemBlacklistIP = 10283
}
