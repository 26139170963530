import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Space, Spin, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { DatePickerCol, LayoutNav, LayoutPagination, LayoutUpdateControl } from 'components/layout.component';
import { PopupAddDiscount, PopupBatchDiscount, ViewDiscount, ViewDiscountOverview } from 'components/promotion.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { BONUS_CATEGORY } from 'enum/promotion';
import { BONUS_TICKET_TYPE, DISCOUNT_TYPE, SEARCH_DATE_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from 'services';
import { enumToOptions, selectFuzzyFilter, timeL2S } from "utils/common";

// 優惠活動查詢

interface OverviewParams {
  AgentAccount?: string | null;
  BonusCode?: string;
  MemberAccount?: string;
  TxnType?: number | null;
  Status?: number | null;
  BonusName?: string | null;
  BonusId?: string | null;
  TimeCondition?: number;
  Category?: number;
  RangeStartTime: string;
  RangeEndTime: string;
}

interface DiscountParams extends OverviewParams {
  PageSize?: number;
  PageIndex?: number;
}

interface PromotionType {
  Id: number,
  Name: string
}

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [isOpenBatchModal, setIsOpenBatchModal] = useState(false);
  const [modalType, setModalType] = useState<number>(0);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);

  const [hideColumns, setHideColumns] = useState({
    order: true,
    memberAccount: true,
    memberLevel: true,
    recommendedMembers: true,
    promotionType: true,
    transactionType: true,
    promotionName: true,
    amount: true,
    rolloverRate: true,
    cycle: true,
    oneTimePromotion: true,
    applicableGameProvider: true,
    applicableMemberLevel: true,
    createTime: true,
    auditTime: true,
    remark: true,
    status: true,
    operation: true,
  });

  const [params, setParams] = useState<DiscountParams>({
    TimeCondition: SEARCH_DATE_TYPE.createTime,
    RangeStartTime: timeL2S(date[0]),
    RangeEndTime: timeL2S(date[1])
  });
  const { data: bonusticketList, mutate, isValidating } = $get({
    url: 'api/bonusticket/list',
    params: {
      ...params,
      PageSize: page[1],
      PageIndex: page[0],
    }
  })
  const { data: BonusOptions, isValidating: BonusLoading } = $get({ url: 'api/bonusticket/bonus/name/list' })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    const selectedOption = BonusOptions?.Data.find((option: any) => option.Id === formData.promotionName);
    const promotionName = selectedOption ? selectedOption.Name : '';
    const promotionId = selectedOption ? selectedOption.Id : '';
    setParams({
      ...params,
      AgentAccount: formData.agentAccount,
      BonusCode: formData.order,
      Category: formData.promotionType,
      MemberAccount: formData.memberAccount,
      TxnType: formData.transactionType && formData.transactionType,
      BonusId: promotionId,
      Status: formData.status && formData.status,
      TimeCondition: formData.TimeCondition,
      RangeStartTime: timeL2S(date[0]),
      RangeEndTime: timeL2S(date[1])
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  const onClickBatchButton = (type: number) => {
    setModalType(type);
    setIsOpenBatchModal(true);
  }
  const handleSend = (type: number) => {
    if (selectedRowKeys.length === 0) return;
    $post({
      url: 'api/bonusticket/audit',
      send: {
        BonusTicketId: [...selectedRowKeys],
        Status: type,
        Remark: ""
      },
      success: () => {
        message.success(i18n.t(type === 3 ? 'approvaled' : 'reject'));
        mutate();
        setSelectedRowKeys([]);
      },
      fail: (response: any) => {
        const errorMessage = response.Data;
        message.error(
          <>
            <div className="center">{i18n.t('ordersOperationFailed')}</div>
            {
              errorMessage.map((error: string, i: number) => (
                <div key={i}>{error}</div>
              ))
            }
          </>
        );
      }
    }, setIsLoading)
    setIsOpenBatchModal(false);
  }
  const ColumnsTitle = Object.keys(hideColumns);
  const ColumnsValue = Object.values(hideColumns);
  const onChangeColumns = (e: any) => {
    setHideColumns({
      ...hideColumns,
      [e.target.name]: e.target.checked
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeCondition: SEARCH_DATE_TYPE.createTime,
        }}>
          <Row align="middle" gutter={10}>
            <Col className="w-12">
              <Form.Item name="agentAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="order">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="memberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item className="w-full" name="promotionType">
                <Select
                  placeholder={i18n.t('promotionType')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...enumToOptions(BONUS_CATEGORY)?.filter(item => !(item?.value === 9))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item className="w-full" name="promotionName">
                <Select
                  showSearch
                  loading={BonusLoading}
                  className="w-full"
                  filterOption={selectFuzzyFilter}
                  placeholder={i18n.t('promotionName')}
                  options={
                    BonusOptions?.Data.map((option: PromotionType) => (
                      { value: option.Id, label: option.Name }
                    )) || [{ value: '', label: i18n.t('currentlyNoOngoingPromotions') }]
                  }
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item className="w-full" name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...enumToOptions(DISCOUNT_TYPE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item className="w-full" name="transactionType">
                <Select
                  placeholder={i18n.t('transactionType')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...enumToOptions(BONUS_TICKET_TYPE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-1" justify="space-between">
            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item className="w-full" name="TimeCondition">
                    <Select
                      className="w-full"
                      options={enumToOptions(SEARCH_DATE_TYPE)}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <LayoutUpdateControl callback={mutate} />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewDiscountOverview data={bonusticketList} />
              </Spin>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1" justify="space-between">
            <Col>
              <Space>
                {
                  $p('30302') &&
                  <Button type="primary" onClick={() => setIsModalOpenAdd(true)}>{i18n.t('add')}</Button>
                }
                {
                  $p('30303') &&
                  <>
                    <Button type="primary" ghost onClick={() => onClickBatchButton(3)} loading={isLoading} disabled={!selectedRowKeys.length}>{i18n.t('batchDistribution')}</Button>
                    <Button type="primary" ghost danger onClick={() => onClickBatchButton(2)} loading={isLoading} disabled={!selectedRowKeys.length}>{i18n.t('batchReject')}</Button>
                  </>
                }
              </Space>
            </Col>
            <Col>
              <Popover content={
                ColumnsTitle.map((item, i) => (
                  <div key={i}>
                    <Checkbox name={item} defaultChecked={ColumnsValue[i]} onChange={onChangeColumns}>
                      {i18n.t(item)}
                    </Checkbox>
                  </div>
                ))
              } placement="bottomRight" trigger="click">
                <Button><DatabaseFilled /></Button>
              </Popover>
            </Col>
          </Row>
          <Spin spinning={isValidating}>
            <ViewDiscount data={bonusticketList} mutate={mutate} selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys} hideColumns={hideColumns} />
            <LayoutPagination total={bonusticketList?.TotalRecord || 0} setPage={setPage} page={page} />
          </Spin>
        </Form>
      </Content >

      <PopupAddDiscount isOpen={isModalOpenAdd} close={() => setIsModalOpenAdd(false)} mutate={mutate} />
      <PopupBatchDiscount isOpen={isOpenBatchModal} close={() => setIsOpenBatchModal(false)}
        send={handleSend} type={modalType} select={selectedRowKeys} />
    </div >
  );
};

export default PageMain;