export const MESSAGE_TEMPLATE_TYPE: any= {
  KYC: "nameVerification",
  ADJUST_PLUS: "adjustmentAddBalance",
  ADJUST_MINUS: "adjustmentDeductBalance",
  DEPOSIT: "deposit",
  WITHDRAW: "withdrawing"
}

export const MESSAGE_TEMPLATE_TYPE_STATUS: any= {
  SUCCESS: "success",
  FAIL: "fail"
}