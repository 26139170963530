import audio01 from 'assets/audio/01.mp3';
import audio02 from 'assets/audio/02.mp3';

const useAudio = () => {
  return {
    audio01,
    audio02
  };
};

export default useAudio;