import { Button, Col, Row, Spin, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { InquiryWindow, LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE_SEO } from 'constants/response';
import { SEO_PAGE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';

const PageMain: React.FC = () => {
  const navigate = useNavigate();

  const { permissions: $p } = useAccount();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [type, setType] = useState<number | null>(null);
  const { data: SEOList, isValidating, mutate } = $get({ url: 'api/seo/list' })

  const submitDelete = () => {
    $post({
      url: 'api/seo/delete',
      send: { Type: type },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_SEO
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {$p('50402') && <Button type="primary" disabled={SEOList?.Data?.length >= 2}
              onClick={() => navigate('/seo/add')}>
              {i18n.t('add')}
            </Button>}
          </Col>
          <Col span={24}>
            <Spin spinning={isValidating}>
              <Table
                size="small"
                scroll={{ x: 1500 }}
                dataSource={
                  SEOList ?
                    SEOList.Data.map((item: SEOList, i: number) => (
                      {
                        key: i,
                        page: item.Type,
                        title: item.Title,
                        keywords: item.KeyWord,
                        description: item.Description
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('page'),
                    dataIndex: 'page',
                    width: 150,
                    render: (_, { page }: any) => (
                      <div style={{
                        whiteSpace: 'nowrap', maxWidth: 150,
                        overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {i18n.t(SEO_PAGE[page])}
                      </div>
                    )
                  },
                  {
                    title: i18n.t('title'),
                    dataIndex: 'title',
                    width: 300,
                    render: (_, { title }) => (
                      <div style={{
                        whiteSpace: 'nowrap', maxWidth: 300,
                        overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {title}
                      </div>
                    )
                  },
                  {
                    title: i18n.t('keyword'),
                    dataIndex: 'keywords',
                    width: 300,
                    render: (_, { keywords }) => (
                      <div style={{
                        whiteSpace: 'nowrap', maxWidth: 300,
                        overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {keywords}
                      </div>
                    )
                  },
                  {
                    title: i18n.t('description'),
                    dataIndex: 'description',
                    width: 300,
                    render: (_, { description }) => (
                      <div style={{
                        whiteSpace: 'nowrap', maxWidth: 300,
                        overflow: 'hidden', textOverflow: 'ellipsis',
                      }}>
                        {description}
                      </div>
                    )
                  },
                  {
                    title: i18n.t('operation'),
                    width: 100,
                    fixed: 'right',
                    render: (_, record: any) => (
                      <>
                        {$p('50403') && <Button type="link" onClick={() => navigate(`/seo/edit/${record.page}`)}>
                          {i18n.t('edit')}
                        </Button>}
                        {$p('50405') && <Button type="link" onClick={() => {
                          setType(record.page);
                          setIsOpenDelete(true);
                        }}>
                          {i18n.t('delete')}
                        </Button>}
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
            </Spin>
          </Col>
        </Row>
      </Content>

      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={submitDelete} />
    </div>
  );
};

export default PageMain;