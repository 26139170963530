import { Button, Col, Form, Input, Row, Switch, Table, Tooltip, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import { RESPONSE_CODE_CREATE_ANNOUNCEMENT, RESPONSE_CODE_LICENSE } from "constants/response";
import i18n from 'i18n';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { timeS2L } from "../../../utils/common";
import useAccount from 'hooks/account.hook';
import { COOKIE } from 'constants/cookie';

// 公告設定

enum ANNOUNCEMENT_CATEGORY {
  最新消息 = 0,
  WEB輪播圖 = 1,
  MOBILE輪播圖 = 2,
  APP輪播圖 = 3,
  Logo = 4,
  QA = 5,
  系統公告 = 6
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [id, setId] = useState<number | null>(null);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [params, setParams] = useState({
    Title: '',
    Category: ANNOUNCEMENT_CATEGORY.最新消息
  });
  const { data: AnnouncementList, isValidating, mutate } = $get({
    url: 'api/contents/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  
  const handleStateColor = (num: any) => {
    switch (num) {
      case 1:
        return 'color-pass'
      case 0:
        return 'color-reject'
    }
  }

  const onFinish = (formData: any) => {
    setParams({
      ...params,
      Title: formData.title,
    })
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    form.resetFields();
  }

  const handleUpdateStatus = (record: any, state: boolean) => {
    const Lang = Cookies.get(COOKIE.LANG)
    $post({
      url: 'api/contents/announcement/update',
      send: {
        Id: record.key,
        Lang,
        Title: record.title,
        Content: record.internalContent,
        Position: record.sort,
        PublishDate: record.publicationTime,
        EndDate: record.EndTime,
        LinkTarget: record.popup,
        IsMarquee: record.marquee,
        Status: state ? 1 : 0
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_CREATE_ANNOUNCEMENT
    })
  }

  const handleDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  const handleSubmit = () => {
    $post({
      url: 'api/contents/license/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_LICENSE
    })
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={10}>
                <Col>
                  <Form.Item name="title">
                    <Input className="w-12" placeholder={`${i18n.t('title')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" loading={isValidating}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={10}>
                {$p('00902') && <Col>
                  <Button type="primary" onClick={() => navigate('/advanced/announcement/add')}>{i18n.t('add')}</Button>
                </Col>}
                {$p('99999') && <Col>
                  <Button type="primary" onClick={() => navigate('/advanced/announcement_system')}>
                    {i18n.t('systemAnnouncement')}
                  </Button>
                </Col>}
              </Row>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                dataSource={
                  AnnouncementList ?
                    AnnouncementList.Data.map((item: Contents) => (
                      {
                        key: item.Id,
                        sort: item.Position,
                        title: item.Title,
                        internalContent: item.Content,
                        publicationTime: item.PublishDate,
                        EndTime: item.EndTime,
                        status: item.Status,
                        popup: item.LinkTarget,
                        marquee: item.IsMarquee,
                      }
                    )) : []
                }
                columns={[
                  {
                    title: i18n.t('sort'),
                    dataIndex: 'sort',
                    align: 'center',
                    width: 80
                  },
                  {
                    title: i18n.t('title'),
                    dataIndex: 'title',
                    width: 300,
                    render: (_, { title }: any) => (
                      <Tooltip title={title}>
                        <div style={{
                          whiteSpace: 'nowrap', maxWidth: 300,
                          overflow: 'hidden', textOverflow: 'ellipsis'
                        }}>{title}</div>
                      </Tooltip>
                    )
                  },
                  {
                    title: i18n.t('internalContent'),
                    dataIndex: 'internalContent',
                    width: 600,
                    ellipsis: true,
                    render: (_, { internalContent }) => (
                      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: internalContent }} />}>
                        <div style={{
                          whiteSpace: 'nowrap', maxWidth: 600,
                          overflow: 'hidden', textOverflow: 'ellipsis'
                        }}>{internalContent.replace(/<\/?[^>]+(>|$)/g, "")}</div>
                      </Tooltip>
                    )
                  },
                  {
                    title: i18n.t('publicationTime'),
                    dataIndex: 'publicationTime',
                    width: 150,
                    render: (val: string) => timeS2L(val)
                  },
                  {
                    title: i18n.t('endTime'),
                    dataIndex: 'EndTime',
                    width: 150,
                    render: (val: string) => timeS2L(val)
                  },
                  {
                    title: i18n.t('status'),
                    width: 80,
                    render: (_, record: any) => (
                      <Switch
                        loading={isValidating}
                        checked={record.status}
                        onClick={v => handleUpdateStatus(record, v)}
                        disabled={!$p('00903')}
                      />
                    )
                  },
                  {
                    title: i18n.t('popup'),
                    dataIndex: 'popup',
                    width: 80,
                    render: (_, { popup }) =>
                      <div className={handleStateColor(popup)}>
                        {popup ? i18n.t('yes') : i18n.t('none')}
                      </div>
                  },
                  {
                    title: i18n.t('marquee'),
                    dataIndex: 'marquee',
                    width: 80,
                    render: (_, { marquee }) =>
                      <div className={handleStateColor(marquee)}>
                        {marquee ? i18n.t('yes') : i18n.t('none')}
                      </div>
                  },
                  {
                    title: i18n.t('operation'),
                    width: 150,
                    render: (_, record: any) => (
                      <>
                        {$p('00903') && <Button className="size-12" type="link" onClick={() => navigate(`/advanced/announcement/edit/${record.key}`)}>
                          {i18n.t('edit')}
                        </Button>}
                        {$p('00904') && <Button className="size-12" type="link" onClick={() => handleDelete(record.key)}>
                          {i18n.t('delete')}
                        </Button>}
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={AnnouncementList ? AnnouncementList.TotalRecord : 0}
                setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
        <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={handleSubmit} />
      </Content>
    </div>
  );
};

export default PageMain;