import { Button, Checkbox, Col, Form, Image, Input, Row, Select, Spin, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import accountIcon from 'assets/image/account.svg';
import langIcon from 'assets/image/language.svg';
import passwordIcon from 'assets/image/password.svg';
import { ModalLayout } from "components/layout.component";
import { COOKIE } from "constants/cookie";
import useAccount from "hooks/account.hook";
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { $post } from "services";
import useLanguage, { LANG } from "hooks/language.hook";

// 登入畫面

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAccount();
  const { t, i18n } = useTranslation();
  const { list: languageList, getLang: lang, setLang } = useLanguage();
  const { errorCode } = useParams();

  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(true);

  // 聖經once
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      if (!errorCode) {

        const remember = Cookies.get(COOKIE.REMEBER_ME) === 'true';
        if (remember) {
          form.setFieldsValue({
            account: Cookies.get(COOKIE.ACCOUNT),
            remember
          })
        }
        form.setFieldsValue({
          Language: lang
        })

        setIsLoading(false);

      } else {
        // message.error(RESPONSE_CODE[errorCode as keyof typeof RESPONSE_CODE]);
        window.location.href = '/#/login';
        window.location.reload();
      }
    }
  }, []);

  const onFinish = (formData: any) => {
    setIsLoading(true);
    $post({
      url: 'common/auth/login',
      send: {
        Account: formData.account,
        Password: formData.password
      },
      success: (response: any) => {
        login(response.Data.Token);

        Cookies.set(COOKIE.ACCOUNT, formData.account);
        Cookies.set(COOKIE.REMEBER_ME, formData.remember);

        message.success(i18n.t('loginSuccess'));
        form.resetFields();

        navigate('/');
      },
      fail: () => {
        setIsLoading(false);
      }
    })
  };

  return (
    <ModalLayout>
      <Spin spinning={isLoading}>
      <Form size="middle" onFinish={onFinish} form={form}>
        <Form.Item style={{ padding: '10px 0', textAlign: 'center', color: '#666' }}>
          <div>{i18n.t('webmasterDashboard')}</div>
        </Form.Item>
        <Form.Item name="Language">
          <Select
            onChange={val => setLang(val)}
            options={
              languageList?.map((item: any) => ({
                value: item,
                label: (
                  <Row align="middle" gutter={[5, 0]}>
                    <Col><Image src={langIcon} width={20} height={20} preview={false} /></Col>
                    <Col>{LANG[item as keyof typeof LANG]}</Col>
                  </Row>
                )
              }))
            }
          />
        </Form.Item>
        <Form.Item
          name="account"
          className="mt-1"
          rules={[{ required: true, message: `${i18n.t("enterAccount")}` }]}
        >
          <Input
            prefix={
              <Image rootClassName="center" src={accountIcon} width={20} height={20} preview={false} />
            }
            placeholder={`${i18n.t('inputData')}${i18n.t('account')}`}
          />
        </Form.Item>
        <Form.Item
          name="password"
          className="mt-1"
          rules={[{ required: true, message: `${i18n.t("enterPassword")}` }]}
        >
          <Input.Password
            prefix={
              <Image rootClassName="center" src={passwordIcon} width={20} height={20} preview={false} />
            }
            placeholder={`${i18n.t("inputData")}${i18n.t("password")}`}
          />
        </Form.Item>
        <Form.Item className="center mt-1" name="remember" valuePropName="checked">
          <Checkbox>{i18n.t("rememberAccount")}</Checkbox>
        </Form.Item>
        <Form.Item className="center mt-1" name="login">
          <Button
            htmlType="submit"
            style={{ background: '#DABF8D' }}
            className="w-10 login-btn"
            size="large"
          >
            {i18n.t("login")}
          </Button>
        </Form.Item>
      </Form>
      </Spin>
    </ModalLayout>
  );
};

export default PageMain;
