import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ViewVerificationList } from 'components/verification.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { AGENT_ADJUST_STATE, SEARCH_DATE_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { enumToOptions, timeL2S } from "utils/common";

// 實名驗證

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [starDate, setStarDate] = useState('CreateDateStartDate');
  const [endDate, setEndDate] = useState('CreateDateEndDate');
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [reset, setReset] = useState(false);

  const [params, setParams] = useState<any>({
    Status: 0,
    CreateDateStartDate: timeL2S(date[0]),
    CreateDateEndDate: timeL2S(date[1]),
  });
  const { data: verificationList, mutate, isValidating } = $get({
    url: 'api/kycticket/list',
    params: {
      ...params,
      PageSize: page[1],
      PageIndex: page[0]
    }
  });

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Account: formData.memberAccount || '',
        Status: formData.status,
        [starDate]: timeL2S(date[0]),
        [endDate]: timeL2S(date[1]),
        PageSize: page[1],
        PageIndex: page[0]
      })
    }
    mutate();
  }

  const defaultDate = SEARCH_DATE_TYPE.createTime;
  const handleSearchDateType = (type: number) => {
    if (type === defaultDate) {
      setStarDate('CreateDateStartDate');
      setEndDate('CreateDateEndDate');
    } else {
      setStarDate('AuditedTimeStartDate');
      setEndDate('AuditedTimeEndDate');
    }
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
    setStarDate('CreateDateStartDate');
    setEndDate('CreateDateEndDate');
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="pr-2 pl-2 pb-2">
        {/* 搜尋列 */}
        <Form onFinish={onFinish} form={form} initialValues={{
          TimeMode: defaultDate,
          status: 0
        }}>
          <Row align="middle" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="memberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12" >
              <Form.Item name="status">
                <Select
                  placeholder={i18n.t('status')}
                  options={[
                    { value: "", label: `${i18n.t('ALL')}` },
                    ...enumToOptions(AGENT_ADJUST_STATE).filter(item => !(item.value === 3))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="TimeMode">
                <Select
                  placeholder={i18n.t('pleaseSelect')}
                  onChange={v => handleSearchDateType(v)}
                  options={enumToOptions(SEARCH_DATE_TYPE)}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.all} />
            <Col>
              <Button htmlType="submit" type="primary" loading={!verificationList}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>
        {/* 搜尋結果 */}
        <Row className="mt-1">
          <Col span={24}>
            <Spin spinning={isValidating}>
              <ViewVerificationList data={verificationList} mutate={mutate} />
              <LayoutPagination total={verificationList && verificationList.TotalRecord} setPage={setPage} />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;