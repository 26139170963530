import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import { PRIZE_TYPE } from 'enum/promotion';
import { PROMOTION_JACKPOT_STATE, PROMOTION_REBATE_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useState, useEffect} from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { convertedToPercentage01 as $g, convertedToPercentage02 as $p2n, timeL2S, timeS2L } from "utils/common";

// 獎池優惠查詢

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [bonusId, setBonusId] = useState<any>(0);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [params, setParams] = useState<any>({
    AwardStatus: 0,
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
  });
  const { data: list, isValidating, mutate } = $get({
    url: 'api/jackpotticket/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const { data: jackpotName } = $get({
    url: 'api/jackpotticket/bonus/name/list',
  })

  const { data: gameSetting } = $get({
    url: 'api/jackpotticket/game/list',
    params: {
      AwardStatus: 0
    }
  })
  const { data: gameAward } = $get({
    url: 'api/jackpotticket/game/list',
    params: {
      AwardStatus: 1
    }
  })
  const { data: bonusInfo } = $get({
    url: 'api/bonus/info',
    params: { Id: bonusId },
    allow: !!bonusId
  })
  const jackPotTicket = bonusInfo?.Data.JackPotTicket || [];

  // 搜尋
  const onFinish = (formData: any) => {
    if (date) {
      setPage([1, page[1]]);
      const send: any = {
        Id: formData.Id,
        SettingGameId: formData.SettingGameId,
        AwardGameId: formData.AwardGameId,
        PrizeType: formData.PrizeType,
        PrizeRate: formData.PrizeRate ? parseFloat($p2n(formData.PrizeRate)) : null,
        PrizeName: formData.PrizeName,
        MemberAccount: formData.MemberAccount,
        AwardStatus: formData.AwardStatus,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      }
      setParams(send);
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    setBonusId('')
    form.resetFields();
  }

  const colorChange = (type: number) => {
    switch (type) {
      case PROMOTION_REBATE_STATE.received:
      case PROMOTION_REBATE_STATE.dispatched:
        return 'color-pass'
      case PROMOTION_REBATE_STATE.pendingDistribution:
        return 'color-padding'
      case PROMOTION_REBATE_STATE.reject:
        return 'color-reject'
    }
  }

  const jackpotName2Id = (name: string) => {
    const jp = (jackpotName?.Data || []).find((item: any) => item.Id === name);
    setBonusId(jp ? jp.Id : '')
  }

  // 特規模糊搜尋
  const handleFilterOption = (inputValue: any, option: any) => {
    const label = (option?.label?.props?.children?.props?.children) || ''
    return label.toLowerCase().includes(inputValue.toLowerCase())
  }

  useEffect(() => {
    if (bonusId || jackpotName) {
      const jp = (jackpotName?.Data || []).find((item: any) => item.Id === bonusId);
      form.setFieldsValue({ Name: jp ? jp.Name : null });
      form.setFieldsValue({ Id: jp ? jp.Id : null });
    }
  }, [bonusId, jackpotName]);

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          AwardStatus: 0,
        }}>
          {/* 搜尋第一排 */}
          <Row gutter={10} align="middle">
            <Col className="w-12">
              <Form.Item name="Id">
                <Select
                  className="w-full"
                  placeholder={i18n.t('jackpotId')}
                  onChange={setBonusId}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...(jackpotName?.Data || []).map((item: any) => ({
                      value: item.Id, label: item.Id
                    }))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="Name">
                <Select
                  className="w-full"
                  placeholder={i18n.t('jackpotName')}
                  onChange={jackpotName2Id}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...(jackpotName?.Data || []).map((item: any) => ({
                      value: item.Id, label: item.Name
                    }))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="SettingGameId">
                <Select
                  showSearch
                  filterOption={handleFilterOption}
                  className="w-full"
                  placeholder={i18n.t('settingGameName')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...(gameSetting?.Data || []).map((item: any) => ({
                      value: item.Id, label: common.gameNameArray([item])
                    }))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="AwardGameId">
                <Select
                  showSearch
                  filterOption={handleFilterOption}
                  className="w-full"
                  placeholder={i18n.t('prizeGameName')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...(gameAward?.Data || []).map((item: any) => ({
                      value: item.Id, label: common.gameNameArray([item])
                    }))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="PrizeType">
                <Select
                  className="w-full"
                  placeholder={i18n.t('prizeType')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...common.enumToOptions(PRIZE_TYPE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="PrizeRate">
                <Input placeholder={`${i18n.t('prizeRatio')}`} />
              </Form.Item>
            </Col>
            {/* 獎項名稱 */}
            <Col className="w-12">
              <Form.Item name="PrizeName">
                <Select
                  className="w-full"
                  placeholder={i18n.t('prizeName')}
                  disabled={!(jackPotTicket && jackPotTicket.length > 0)}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...jackPotTicket.map((item: any) => ({
                      value: item.PrizeName, label: item.PrizeName
                    }))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="MemberAccount">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
          </Row>
          {/* 搜尋第二排 */}
          <Row gutter={10} className="mt-1">
            <Col className="w-12">
              <Form.Item name="AwardStatus">
                <Select
                  className="w-full"
                  options={[
                    { value: 0, label: `${i18n.t('ALL')}` },
                    { value: 1, label: `${i18n.t('timeOfPrize')}` }
                  ]}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={182} date={date} setDate={setDate} initDate={reset} />
            <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
            <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
          </Row>
          <Table
            loading={isValidating}
            size="middle"
            className="mt-1"
            dataSource={list?.Data?.map((item: any) => ({ key: item.Id, ...item })) || []}
            columns={[
              {
                dataIndex: 'BonusId',
                title: i18n.t('jackpotId'),
                width: 50,
              },
              {
                dataIndex: 'BonusName',
                title: i18n.t('jackpotName'),
                width: 150,
              },
              {
                dataIndex: 'SettingGames',
                title: i18n.t('settingGameName'),
                width: 300,
                render: (val: any) => common.gameNameArray(val)
              },
              {
                dataIndex: 'AwardGame',
                title: i18n.t('prizeGameName'),
                width: 150,
                render: (val: any) => common.gameNameArray([val])
              },
              {
                dataIndex: 'PrizeOrder',
                title: i18n.t('prizeSort'),
                width: 70,
              },
              // 獎項類型
              {
                dataIndex: 'PrizeType',
                title: i18n.t('prizeType'),
                // align: 'right',
                width: 70,
                render: (val: any) => i18n.t(PRIZE_TYPE[val])

              },
              // 獎金比例
              {
                dataIndex: 'PrizeRate',
                title: i18n.t('prizeRatio'),
                width: 120,
                render: (val) => `${$g(val)}%`
              },
              {
                dataIndex: 'PrizeName',
                title: i18n.t('prizeName'),
                width: 120,
              },
              {
                dataIndex: 'MemberAccount',
                title: i18n.t('memberAccount'),
                width: 120,
                render: (val: any) => val || '-'
              },
              {
                dataIndex: 'AwardTime',
                title: i18n.t('timeOfPrize'),
                width: 150,
                render: (val: any) => timeS2L(val)
              },
              {
                dataIndex: 'Status',
                title: i18n.t('status'),
                width: 150,
                render: (val: any, { OperatorAccount }: any) => (
                  val === PROMOTION_JACKPOT_STATE.dispatched ? <>
                    <div className={colorChange(val)}>
                      {i18n.t(PROMOTION_JACKPOT_STATE[val])}
                    </div>
                    <div>
                      {i18n.t('from')} {'System'} {i18n.t('distribution')}
                    </div>
                  </> : '-')
              },
            ]}
            pagination={false}
          />
          <LayoutPagination total={list && list.TotalRecord} setPage={setPage} page={page} />

        </Form>
      </Content>
    </div>
  );
};

export default PageMain;