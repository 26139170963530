import { Button, Col, Form, Input, Row, Spin, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupIpInfoModal } from 'components/ip.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get } from 'services';
import * as common from 'utils/common';

// 會員IP檢核

interface SearchParams {
  IP?: string;
  PageIndex?: number;
  PageSize?: number;
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();

  const { permissions: $p } = useAccount();
  
  const [form] = useForm();
  const [ipInfo, setIpInfo] = useState<{
    IP: string;
    Id: number;
    MemberCount: number;
  } | null>(null);
  const [isOpenIpInfo, setIsOpenIpInfo] = useState(false);
  const [page, setPage] = useState<number[]>([1, 50]);
  const [params, setParams] = useState<SearchParams>({});
  const { data: BlockIpList, isValidating, mutate } = $get({
    url: 'api/blockip/member/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  });

  const handleIpInfo = (ipInfo: {
    IP: string;
    Id: number;
    MemberCount: number;
  }) => {
    setIsOpenIpInfo(true);
    setIpInfo(ipInfo);
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      IP: formData.ip
    })
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setPage([1, 50]);
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="p-2">
        {/* 搜尋列 */}
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="ip">
                    <Input className="w-12" placeholder='IP' />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {
                $p('11201') &&
                <Button type="primary" onClick={() => navigate('/member/block-ip-member')}>{i18n.t('IPBlockManagement')}</Button>
              }
            </Col>
            <Col span={24} className="mt-1">
              <Spin spinning={isValidating}>
                <Row gutter={[12, 12]} >
                  {
                    BlockIpList &&
                    BlockIpList.Data.map((item: MemberBlockIp, i: number) => (
                      <Col key={item.IP} span={Math.floor(24 / 5)}>
                        <Tag className={`w-3 ${common.numColor02(item.MemberCount)}`}
                          style={{ textAlign: 'center' }} bordered={false}>
                          {item.MemberCount}
                        </Tag>
                        <Button size="small" type="link"
                          onClick={() => handleIpInfo(item)}>{item.IP}</Button>
                      </Col>
                    ))
                  }
                </Row>
                <LayoutPagination total={BlockIpList ? BlockIpList.TotalRecord : 0} setPage={setPage}
                  page={page} defaultPageSize={50} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
      <PopupIpInfoModal isOpen={isOpenIpInfo} close={() => setIsOpenIpInfo(false)} ipInfo={ipInfo} mutate={mutate} />
    </div>
  );
};

export default PageMain;