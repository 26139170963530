import { Button, Col, Image, Row, Spin, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PopupContact } from 'components/contact.component';
import { InquiryWindow, LayoutNav, LayoutTabMember } from "components/layout.component";
import { RESPONSE_CODE_MEMBER } from 'constants/response';
import { CONTACT_INFO, SOURCE_ROLE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get, $post } from 'services';

// 會員列表 聯絡資訊

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const { id, account, agId } = useParams();
  const [activeId, setActiveId] = useState<any>('');
  const [activeContact, setActiveContact] = useState<any>(null);
  const [isOpenAddContactModal, setIsOpenAddContactModal] = useState(false);
  const [isOpenDeleteContactModal, setIsOpenDeleteContactModal] = useState(false);

  const { data, mutate, isValidating } = $get({
    url: 'api/member/contact/list',
    params: { MemberId: id },
    allow: !!id
  });

  const handleClickAdd = () => {
    setActiveId(id);
    setActiveContact(null);
    setIsOpenAddContactModal(true);
  }

  const handleClickEdit = (contact: any) => {
    setActiveContact(contact);
    setIsOpenAddContactModal(true);
  }

  const closePopup = () => {
    setIsOpenAddContactModal(false);
  }

  const handleClickDelete = (id: number) => {
    setActiveId(id);
    setIsOpenDeleteContactModal(true);
  }

  const handleChangeStatus = (contact: Contact, checked: boolean) => {
    $post({
      url: 'api/member/contact/update',
      send: {
        Id: contact.Id,
        SourceRole: contact.SourceRole,
        SourceAccount: contact.SourceAccount,
        ContactInfo: contact.ContactInfo,
        ContactContent: contact.ContactContent,
        Status: checked ? 1 : 0,
        Photo: contact.Photo ? [contact.Photo] : [],
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MEMBER
    })
  }

  const handleSubmit = () => {
    $post({
      url: 'api/member/contact/delete',
      send: { Id: activeId },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MEMBER
    })
  }

  return (
    <div id="container">
      <LayoutNav id={id || 0} account={account || ''} agId={agId}/>
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="3" id={id || 0} account={account} agId={agId} />
        <Row>
          {
            $p('10402') &&
            <Col span={24}>
              <Button type="primary" onClick={handleClickAdd}>{i18n.t('add')}</Button>
            </Col>
          }
          <Col span={24}>
            <Spin spinning={isValidating}>
              <Table
                className="mt-1"
                size="small"
                dataSource={data?.Data.map((item: Contact) => ({ ...item, key: item.Id }))}
                columns={[
                  {
                    dataIndex: 'SourceRole',
                    title: i18n.t('source'),
                    width: 150,
                    render: (val) => i18n.t(SOURCE_ROLE[val])
                  },
                  {
                    dataIndex: 'SourceAccount',
                    title: i18n.t('account'),
                    width: 200,
                  },
                  {
                    dataIndex: 'Status',
                    title: i18n.t('status'),
                    width: 150,
                    render: (_: any, record: Contact) => (
                      <Switch
                        checked={!!record.Status}
                        onChange={(checked: boolean) => handleChangeStatus(record, checked)}
                        disabled={!$p('10403')}
                      />
                    )
                  },
                  {
                    dataIndex: 'ContactInfo',
                    title: i18n.t('contactType'),
                    width: 150,
                    render: (val) => i18n.t(CONTACT_INFO[val])
                  },
                  {
                    dataIndex: 'ContactContent',
                    title: i18n.t('content'),
                  },
                  {
                    dataIndex: 'Photo',
                    title: i18n.t('image'),
                    width: 320,
                    render: (val) => val && <Image src={val} height={40} />
                  },
                  {
                    title: i18n.t('operation'),
                    width: 150,
                    render: (_: any, record: Contact) => (
                      <>
                        {
                          $p('10403') &&
                          <Button className="size-12" type="link"
                            onClick={() => handleClickEdit(record)}>
                            {i18n.t('edit')}
                          </Button>
                        }
                        {
                          $p('10404') &&
                          <Button className="size-12" type="link"
                            onClick={() => handleClickDelete(record.key)}>
                            {i18n.t('delete')}
                          </Button>
                        }
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
            </Spin>
          </Col>
          <PopupContact isOpen={isOpenAddContactModal} close={closePopup} id={activeId} contact={activeContact} mutate={mutate} />
          <InquiryWindow isOpen={isOpenDeleteContactModal} close={() => setIsOpenDeleteContactModal(false)} action={handleSubmit} />
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;