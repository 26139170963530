import { Button, Col, Image, Modal, Row, Space, message } from 'antd';
import hintIcon from 'assets/image/icon-12.png';
import { RESPONSE_CODE_MEMBER_PROVIDER_STATUS } from 'constants/response';
import i18n from 'i18n';
import { useState } from 'react';
import { $post } from 'services';

// 有的
export const PopupStatusModal = ({ isOpen, close, id, type, mutate }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const onConfirm = () => {
    $post({
      url: 'api/memberprovidersetting/update/all',
      send: {
        MemberId: id,
        Status: type
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        close();
      },
      resCode: RESPONSE_CODE_MEMBER_PROVIDER_STATUS
    }, setIsLoading)
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" key="confirm" type="primary" loading={isLoading} onClick={onConfirm}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmUpdateBatch')}?</div>
        <div className="color-03">*{i18n.t('thisActionMayTakeSomeTime')}</div>
      </Space>
    </Modal>
  )
}