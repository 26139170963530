import { Col, Form, Row, message, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/lib';
import { RcFile } from 'antd/lib/upload';
import userDefaultAvatarIcon from 'assets/image/avatar-default.png';
import { PopupCloseChat, PopupOfflineChat, PopupTransferChat, ViewChatAdminStatus, ViewChatInput, ViewChatList, ViewQuickReply, ViewUserInfo } from 'components/chat.component';
import { COOKIE } from 'constants/cookie';
import { RESPONSE_CODE_CHAT } from 'constants/response';
import { useSocket } from 'contexts/socket.context';
import dayjs from 'dayjs';
import { CHAT_ROOM, CHAT_TYPE, SERVICE_NOTIFY, SERVICE_STATUS } from 'enum/chat';
import { ROLE } from 'enum/socket';
import useAudio from 'hooks/audio.hook';
import useSite from 'hooks/site.hook';
import { Howl } from 'howler';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { $get, $post } from 'services';
import { timeL2S, timeL2SDay, timeS2LUnix } from "utils/common";

// 線上客服

const PageMain: React.FC = () => {
  const { data: $s } = useSite();
  const audio = useAudio();
  const socket = useSocket();
  const [form] = useForm();
  const [tabActiveKey, setTabActiveKey] = useState('1');
  const [processTime, setProcessTime] = useState('');
  const [page, setPage] = useState<number[]>([1, 20]);
  const [inputValue, setInputValue] = useState('');
  const [transferInfo, setTransferInfo] = useState<any[]>([]);
  const [account, setAccount] = useState<string | null>('');
  const [serviceNotify, setServiceNotify] = useState(true);
  const [isOpenCloseChat, setIsOpenCloseChat] = useState(false);
  const [isOpenOfflineChat, setIsOpenOfflineChat] = useState(false);
  const [isOpenTransferChat, setIsOpenTransferChat] = useState(false);
  const [adminState, setAdminState] = useState<number | null>(null);
  const [firstLoading, setFirstLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatType, setChatType] = useState(CHAT_TYPE.等待中);
  const { t, i18n } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [sendType, setSendType] = useState(0);
  const messageListReferance = useRef();
  const [sessionId, setSessionId] = useState<string>('');
  const [serviceMessage, setServiceMessage] = useState<any>([]);
  const [isContact, setIsContact] = useState(true);
  const [sessionStatus, setSessionStatus] = useState(0);
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [showBackToBottom, setShowBackToBottom] = useState(false);
  const chatWindowRef = useRef<HTMLDivElement | null>(null);

  // 音效
  const contactAudio = new Howl({
    src: [audio.audio02],
    volume: serviceNotify ? 1 : 0
  })
  const messageAudio = new Howl({
    src: [audio.audio01],
    volume: serviceNotify ? 1 : 0
  })
  // 客服聊天歷史紀錄
  const { data: ChatServiceHistory } = $get({
    url: 'api/chathistory/service/session/message/list',
    params: {
      SessionId: sessionId,
      StartDate: timeL2S(processTime as any),
      EndDate: timeL2SDay(dayjs()),
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!sessionId
  })
  useEffect(() => {
    if (ChatServiceHistory) {
      setServiceMessage([
        ...ChatServiceHistory.Data
          .sort((a: ChatMessageInfo, b: ChatMessageInfo) => (
            dayjs(a.SendTime).valueOf() - dayjs(b.SendTime).valueOf()
          ))
          .map((item: ChatMessageInfo, i: number) => (
            {
              key: i,
              position: item.SenderRole === ROLE.customerService ? 'right' : 'left',
              type: item.Type === 0 ? 'text' : 'photo',
              avatar: item.SenderRole !== ROLE.customerService ? userDefaultAvatarIcon : '',
              title: item.SenderRole === ROLE.visitor ? `${i18n.t('visitor')} ${item.SenderAccount}` : item.SenderAccount,
              titleColor: item.SenderRole === ROLE.customerService ? '#df9620' : '#5858d4',
              text: item.Type === 0 ? item.Content : '',
              date: timeS2LUnix(item.SendTime),
              dateString: timeS2LUnix(item.SendTime, 'YYYY-MM-DD HH:mm'),
              data: {
                uri: item.Content,
                width: 200,
                height: 200
              },
            }
          )),
      ])
    }
  }, [ChatServiceHistory])
  // socket收到客服或私人訊息
  useEffect(() => {
    if (socket.serviceMessages) {
      const service = socket.serviceMessages.SessionId === sessionId;
      if (service) {
        messageAudio.play();
        setShowBackToBottom(true);
        setServiceMessage([
          ...serviceMessage,
          ...[socket.serviceMessages].map((item: ChatMessageInfo) => ({
            position: item.SenderRole === ROLE.customerService ? 'right' : 'left',
            type: item.Type === 0 ? 'text' : 'photo',
            title: item.SenderAccount ? item.SenderAccount : `${i18n.t('visitor')} ${item.SessionId}`,
            titleColor: item.SenderRole === ROLE.customerService ? '#df9620' : '#5858d4',
            text: item.Type === 0 ? item.Content : '',
            date: timeS2LUnix(item.SendTime),
            dateString: timeS2LUnix(item.SendTime, 'YYYY-MM-DD HH:mm'),
            data: {
              uri: item.Content,
              width: 200,
              height: 200
            },
          }))
        ])
      }
      refreshOpenMyList();
      refreshOpenColleagueList();
    }
  }, [socket]);
  // socket 收到各類通知
  useEffect(() => {
    const n = socket?.notification?.split('-')[0];
    switch (n) {
      case 'SESSION_CREATED':
        refreshPendingList();
        break;
      case 'SESSION_STATUS_START':
      case 'SESSION_STATUS_TRANSFER':
        refreshPendingList();
        refreshOpenMyList();
        refreshOpenColleagueList();
        break;
      case 'SESSION_STATUS_END':
        const session = socket?.sessionId
        refreshPendingList();
        refreshOpenMyList();
        refreshOpenColleagueList();
        refreshCloseMyList();
        refreshCloseColleagueList();
        if (isContact && session === sessionId) {
          setIsContact(false);
        }
        break;
    }
  }, [socket.notification]);
  
  // 取得聊天室使用者資料
  const { data: UserInfo, mutate: refreshUserInfo } = $get({ url: 'api/chatsetting/account/info' })
  useEffect(() => {
    if (UserInfo) {
      form.setFieldsValue({
        serviceState: UserInfo.Data.IsOnline
      })
    }
  }, [UserInfo])
  // 等待中列表
  const {
    data: PendingList,
    isValidating: pendingListLoading,
    mutate: refreshPendingList } = $get({ url: 'api/chatroom/service/pending/list' });
  // 對話中列表-我的對話
  const {
    data: OpenMyList,
    isValidating: openMyListLoading,
    mutate: refreshOpenMyList
  } = $get({ url: 'api/chatroom/service/open/list', params: { Type: CHAT_ROOM.自己 } })
  // 對話中列表-同事對話
  const {
    data: OpenColleagueList,
    isValidating: openColleagueListLoading,
    mutate: refreshOpenColleagueList
  } = $get({ url: 'api/chatroom/service/open/list', params: { Type: CHAT_ROOM.同事 } })
  // 已結束對話-我的對話
  const {
    data: CloseMyList,
    isValidating: closeMyListLoading,
    mutate: refreshCloseMyList
  } = $get({ url: 'api/chatroom/service/close/list', params: { Type: CHAT_ROOM.自己 } })
  // 已結束對話-同事對話
  const {
    data: CloseColleagueList,
    isValidating: closeColleagueListLoading,
    mutate: refreshCloseColleagueList
  } = $get({ url: 'api/chatroom/service/close/list', params: { Type: CHAT_ROOM.同事 } })
  // 目前上線可轉接的帳號
  const {
    data: ChatOnlineAccount,
    isValidating: onlineAccountLoading,
    mutate: refreshOnlineAccount
  } = $get({
    url: 'api/chatsetting/account/list',
    params: {
      IsOnline: SERVICE_STATUS.在線,
      PageIndex: 1,
      PageSize: 999
    }
  })
  // 更新所有的列表
  const refreshAll = () => {
    refreshPendingList();
    refreshOpenMyList();
    refreshOpenColleagueList();
    refreshCloseMyList();
    refreshCloseColleagueList();
    refreshOnlineAccount();
  }
  // 更新客服上下線狀態
  useEffect(() => {
    if (UserInfo) {
      setAdminState(UserInfo.Data.IsOnline);
    }
  }, [UserInfo]);
  const handleServiceState = (status: number) => {
    if (OpenMyList?.Data.length !== 0) {
      form.setFieldValue('serviceState', SERVICE_STATUS.在線);
      return setIsOpenOfflineChat(true);
    }
    $post({
      url: 'api/chatsetting/account/online/update',
      send: { IsOnline: status },
      success: () => {
        if (status === SERVICE_STATUS.在線) {
          message.success(i18n.t('updateSuccess'));
        } else if (status === SERVICE_STATUS.離線) {
          message.success(i18n.t('operationSuccess'));
        }
        setAdminState(status);
        refreshUserInfo();
        refreshOnlineAccount();
      },
      resCode: RESPONSE_CODE_CHAT
    })
  }
  // handle 開啟關閉通知
  const handleServiceNotify = async (notify: number) => {
    switch (notify) {
      case SERVICE_NOTIFY.開啟通知:
        setServiceNotify(true);
        break;
      case SERVICE_NOTIFY.關閉通知:
        setServiceNotify(false);
        break;
    }
  }
  // handle Tab
  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        setChatType(CHAT_TYPE.等待中);
        setTabActiveKey('1');
        break;
      case '2':
        setChatType(CHAT_TYPE.我的對話);
        setTabActiveKey('2');
        break;
      case '3':
        setChatType(CHAT_TYPE.同事對話);
        setTabActiveKey('3');
        break;
    }
    setSessionId('');
  }
  // 點擊聊天列表
  const handleOpenSession =
    async (type: string, id: string, role: number, account: string, Status: number, ProcessTime?: string, NoCode?: string) => {
      setIsContact(true);
      switch (type) {
        case CHAT_TYPE.等待中:
          setProcessTime(ProcessTime || dayjs().format('YYYY-MM-DD HH:mm:ss'));
          setSessionId(id);
          setAccount(role === ROLE.visitor ? `${i18n.t('visitor')}${id}` : account);
          setSessionStatus(Status);
          break;
        case CHAT_TYPE.我的對話:
          $post({
            url: 'api/chatroom/service/message/count/update',
            send: { SessionId: id },
            success: () => {
              refreshOpenMyList();
              refreshOpenColleagueList();
              setPage([1, 20]);
              setSessionId(id);
              setAccount(role === ROLE.visitor ? `${i18n.t('visitor')}${id}` : account);
              setSessionStatus(Status);
              if (ProcessTime) {
                setProcessTime(ProcessTime);
              }
            }
          })
          break;
        case CHAT_TYPE.同事對話:
          try {
            setPage([1, 20]);
            setSessionId(id);
            setAccount(role === ROLE.visitor ? `${i18n.t('visitor')}${id}` : account);
            if (ProcessTime) {
              setProcessTime(ProcessTime);
            }
            setSessionStatus(Status);
          } catch (error) {
            return;
          }
          break;
      }
    }
  // 轉接給其他客服
  const handleTransfer = (e: string) => {
    const info = e.split(',');
    setTransferInfo(info)
    form.setFieldValue('transfer', null);
    setIsOpenTransferChat(true);
  }
  // 連線聊天室
  const handleConnect = () => {
    if (sessionId) {
      $post({
        url: 'api/chatroom/service/session/answer',
        send: { SessionId: sessionId },
        success: () => {
          setChatType(CHAT_TYPE.我的對話);
          setTabActiveKey('2');
          setServiceMessage([]);
          contactAudio.play();
          message.success(i18n.t('connectionSuccess'));
          refreshAll();
        },
        resCode: RESPONSE_CODE_CHAT
      })
    }
  }
  // 傳送訊息
  const handleScroll = () => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      const isAtBottom =
        chatWindow.scrollTop + chatWindow.clientHeight === chatWindow.scrollHeight;
      const isAtTop = chatWindow.scrollTop === 0;
      if (isAtBottom) {
        setShowBackToBottom(false);
      }
      if (isAtTop) {
        setPage([1, page[1] + 20]);
      }
    }
  }
  const scrollToBottom = (delay = 300) => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, delay);
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (isEnterPressed) {
        onSend();
        setInputValue('');
        setIsEnterPressed(false);
      } else {
        setIsEnterPressed(true);
        setTimeout(() => {
          setIsEnterPressed(false);
        }, 300);
      }
    }
  };
  const onSend = () => {
    if (inputValue) {
      $post({
        url: 'api/chatroom/service/message/send',
        send: {
          SessionId: sessionId,
          Type: sendType,
          Content: inputValue
        },
        success: () => {
          setPage([1, page[1] + 1]);
          scrollToBottom();
          setSendType(0);
          setFileList([]);
          setInputValue('');
          form.setFieldsValue({
            fileList: []
          })
          refreshOpenMyList();
          refreshOpenColleagueList();
        }
      }, setIsLoading)
    }
  }

  // 上傳圖片
  const handleFileListChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    setIsLoading(true);
    setInputValue('');
    const { fileList: newFileList } = info;
    const newFormData = new FormData();

    newFileList.forEach((file) => {
      newFormData.append('Media', file.originFileObj as RcFile);
    });

    form.setFieldsValue({
      fileList: newFileList
    })

    setFileList(newFileList);

    const upload = await fetch(`api/upload/images/public/message`, {
      headers: {
        "Authorization": Cookies.get(COOKIE.TOKEN) as any
      },
      method: 'POST',
      body: newFormData,
    }).then((response) => {
      if (response.ok) {
        return response.json()
      }
    }).then(data => data)
    setInputValue(upload.Data[0]);
    setSendType(1);
    setIsLoading(false);
  };

  // 關閉瀏覽器客服離線
  useEffect(() => {
    window.onbeforeunload = () => {
      $post({
        url: 'api/chatsetting/account/online/update',
        send: { IsOnline: SERVICE_STATUS.離線 }
      })
    }
  }, []);

  return (
    <div className="m-1" style={{ height: '1vh' }}>
      {/* 客服 */}
      <Helmet>
        <link rel="icon" href={$s && $s.Logo1} type="image/x-icon" />
      </Helmet >
      <Form form={form} colon={false} initialValues={{
        notify: 1,
        lang: Cookies.get('lang-online-service') || document.documentElement.lang
      }}>
        <Row gutter={[12, 12]}>
          {/* 左邊 */}
          <Col span={6} style={{ minHeight: 900 }}>
            <Spin spinning={openMyListLoading}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                {/* 上線狀態 */}
                <ViewChatAdminStatus form={form} handleConnect={handleConnect} tabActiveKey={tabActiveKey}
                  adminState={adminState} handleServiceState={handleServiceState} handleServiceNotify={handleServiceNotify} serviceNotify={serviceNotify} />
              </Col>
              {/* 分頁與名單 */}
              <ViewChatList PendingList={PendingList?.Data} tabActiveKey={tabActiveKey} onTabClick={onTabClick}
                chatType={chatType} handleOpenSession={handleOpenSession} sessionId={sessionId} OpenMyList={OpenMyList?.Data} CloseMyList={CloseMyList?.Data} OpenColleagueList={OpenColleagueList?.Data} CloseColleagueList={CloseColleagueList?.Data} pendingListLoading={pendingListLoading} openMyListLoading={openMyListLoading} closeMyListLoading={closeMyListLoading} openColleagueListLoading={openColleagueListLoading} closeColleagueListLoading={closeColleagueListLoading} firstLoading={firstLoading} />
            </Row>
            </Spin>
          </Col>
          {/* 中間 */}
          <Col span={12} style={{ minHeight: 900, maxHeight: 900 }}>
            {/* Chat Input */}
            <ViewChatInput sessionId={sessionId} chatType={chatType} account={account} onlineAccountLoading={onlineAccountLoading} refreshOnlineAccount={refreshOnlineAccount} adminState={adminState} isContact={isContact} sessionStatus={sessionStatus} ChatOnlineAccount={ChatOnlineAccount?.Data} UserInfo={UserInfo?.Data} handleTransfer={handleTransfer} handleScroll={handleScroll} messageListReferance={messageListReferance} serviceMessage={serviceMessage} showBackToBottom={showBackToBottom} scrollToBottom={scrollToBottom} setInputValue={setInputValue} handleKeyDown={handleKeyDown} fileList={fileList} inputValue={inputValue} handleFileListChange={handleFileListChange} setFileList={setFileList} setSendType={setSendType} isLoading={isLoading} setIsOpenCloseChat={setIsOpenCloseChat} onSend={onSend} firstLoading={firstLoading} />
          </Col>
          {/* 右邊 */}
          <Col span={6} style={{ minHeight: 900 }}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                {/* User Info */}
                <ViewUserInfo sessionId={sessionId} chatType={chatType} />
              </Col>
              <Col span={24}>
                {/* QuickReply */}
                <ViewQuickReply chatType={chatType} isContact={isContact} sessionStatus={sessionStatus} setInputValue={setInputValue} />
              </Col>
            </Row>
          </Col>
        </Row >
      </Form >
      {/* Modal */}
      <PopupOfflineChat isOpen={isOpenOfflineChat} close={() => setIsOpenOfflineChat(false)} setChatType={setChatType} setTabActiveKey={setTabActiveKey} />
      <PopupCloseChat isOpen={isOpenCloseChat} close={() => setIsOpenCloseChat(false)}
        sessionId={sessionId} setSessionId={setSessionId} refresh={refreshAll} setIsContact={setIsContact} />
      <PopupTransferChat isOpen={isOpenTransferChat} close={() => setIsOpenTransferChat(false)}
        sessionId={sessionId} setSessionId={setSessionId} transferInfo={transferInfo} refresh={refreshAll} />
    </div >
  );
};

export default PageMain;